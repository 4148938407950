import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Control, Controller, SubmitHandler, useForm, UseFormRegister } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Box, Typography, Button, TextField, Grid } from '@mui/material';

import { useAppDispatch } from '../components/typescript/hooks';
import Modal from '../components/UI/Popup/BackdropComponent';
import { getFullName } from '../helpers/helperFunctions';
import { getAccessToken } from '../store/authSlice';
import { getMyProviderDetails, postProviderInvite } from '../store/providerSlice';
import AltSuccessComponent from '../components/UI/Popup/AltSuccessComponent';
import useTranslate from '../hooks/useTranslate';
import {
  InviteInfoText,
  ReqErrorMsg,
} from '../components/UI/StyledComponents/FormsStyledComponents';
import { InlineContainer } from '../components/UI/StyledComponents/GlobalStyledComponents';
import useDetectResize from '../hooks/useDetectResize';
import { formInputStyles } from '../helpers/styling';
import PageTitleComponent from '../components/PageTitleComponent';
import { CenteredOverlay } from '../components/UI/StyledComponents/NotificationModalStyledComponents';

import inviteSuccess from '../asssets/invite-animation.gif';

interface InviteFormData {
  fName: string;
  lName: string;
  email: string;
  providerName: string;
}

const InvitesComponent = () => {
  const dispatch = useAppDispatch();
  const accessToken = useSelector(getAccessToken);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<InviteFormData>();
  const myProviderDetails = useSelector(getMyProviderDetails);
  const [showInviteSuccess, setShowInviteSuccess] = useState<boolean>(false);
  const t = useTranslate();
  const { isMobile, isTablet, windowDimensions, isDesktop } = useDetectResize();

  const onSubmit: SubmitHandler<InviteFormData> = (data) => {
    const providerName = getFullName(myProviderDetails);
    console.info('MyProviderDetails: ', myProviderDetails);
    console.info('Details', data);
    // wait for response from backend, then give success modal

    dispatch(
      postProviderInvite({
        data: {
          email: data.email,
          fName: data.fName,
          lName: data.lName,
          orgId: myProviderDetails.orgId,
          providerName: providerName,
        },
        token: accessToken!,
      })
    )
      .then(unwrapResult)
      .then(() => {
        setShowInviteSuccess(true);
        reset();
      });
  };

  console.log('errors', errors);

  return (
    <>
      <Box>
        <InviteInfoText>
          {!isMobile && !isTablet && (
            <Typography variant="h1" color="black">
              {t('inviteTitle')}
            </Typography>
          )}
          <Typography sx={{ color: 'text.primary' }}>{t('inviteText')}</Typography>
        </InviteInfoText>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ width: isDesktop ? '80%' : '100%' }}>
            <Grid container>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={12} xl="auto" md="auto">
                  <ControllerTextField
                    control={control}
                    register={register}
                    name="fName"
                    placeholder={t('fName')}
                    rules={{
                      required: 'This field is required',
                      maxLength: 80,
                    }}
                  />
                  {errors?.fName && <ReqErrorMsg>{errors?.fName.message}</ReqErrorMsg>}
                </Grid>
                <Grid item xs={12} xl="auto" md="auto">
                  <ControllerTextField
                    control={control}
                    register={register}
                    name="lName"
                    placeholder={t('lName')}
                    rules={{
                      required: 'This field is required',
                      maxLength: 80,
                    }}
                  />
                  {errors?.lName && <ReqErrorMsg>{errors?.lName.message}</ReqErrorMsg>}
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
                <Grid item xs={12} xl="auto" md="auto">
                  <ControllerTextField
                    control={control}
                    register={register}
                    name="email"
                    placeholder={'Email'}
                    helperText={t('inviteEmailMsg')}
                    rules={{
                      required: 'This field is required',
                    }}
                  />
                  {errors?.email && <ReqErrorMsg>{errors?.email.message}</ReqErrorMsg>}
                  {errors?.email?.type === 'pattern' && (
                    <Typography variant="body1">{t('emailErrorMsg')}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <InlineContainer
              justifyContent={'flex-end'}
              display={isMobile || isTablet ? 'block!important' : 'inline-flex'}
              margin={isMobile || isTablet ? 'auto' : 'unset'}
              width={isMobile || isTablet ? 'unset!important' : '100%'}
              marginTop={isMobile || isTablet ? '50px' : '20px'}
              marginLeft={isMobile || isTablet ? '25vw' : 'initial'}
              marginRight={isMobile || isTablet ? '25vw' : 'initial'}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: isMobile || isTablet ? '100%' : 'initial',
                }}
              >
                {t('inviteBtn')}
              </Button>
            </InlineContainer>
          </Box>
        </form>
      </Box>
      {showInviteSuccess && (
        <Modal className="modal invite-modal" onHide={() => [setShowInviteSuccess(false)]}>
          <CenteredOverlay
            onClick={(e) => {
              e.stopPropagation();
            }}
            top={windowDimensions.h}
            right={windowDimensions.w}
          >
            <AltSuccessComponent message="Invitation sent!" animation={inviteSuccess} />
          </CenteredOverlay>
        </Modal>
      )}
    </>
  );
};

interface PropsControllerTextField {
  register: UseFormRegister<InviteFormData>;
  customFunction?: (e: string) => void;
  control: Control<InviteFormData, any>;
  name: any;
  rules?: {
    required?: boolean | string;
    pattern?: { value: RegExp; message: string };
    maxLength?: number;
  };
  placeholder: string;
  className?: string;
  id?: string;
  label?: string;
  focused?: boolean;
  type?: string;
  helperText?: string;
  disabled?: boolean;
}

const ControllerTextField = (props: PropsControllerTextField) => {
  const {
    control,
    name,
    placeholder,
    rules,
    register,
    className,
    customFunction,
    label,
    focused,
    type,
    id,
    helperText,
    disabled,
  } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <TextField
          {...register(name)}
          id={id ? id : 'outlined-required'}
          helperText={helperText}
          className={className}
          sx={formInputStyles}
          type={type ? type : 'text'}
          placeholder={placeholder}
          label={label ? label : placeholder}
          value={value || ''}
          autoFocus={focused}
          disabled={disabled || false}
          fullWidth
          onChange={(e) => {
            if (customFunction) {
              onChange(customFunction(e.target.value));
            } else {
              onChange(e.target.value);
            }
          }}
        />
      )}
    />
  );
};

export default InvitesComponent;

export const MobileInviteHeader = () => {
  const t = useTranslate();
  return <PageTitleComponent greeting={t('inviteTitle')} />;
};
