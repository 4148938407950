import { Box, Button, Typography } from '@mui/material';
import Card from '../Card/CardComponent';
import { AltSuccessModal } from '../StyledComponents/NotificationModalStyledComponents';
import { useNavigate } from 'react-router-dom';

const AltSuccessComponent = (props: { message: string; animation: string }) => {
  const navigate = useNavigate();
  return (
    <>
      <AltSuccessModal>
        <img src={props.animation} alt="success-gif" />
        <Typography variant="body1">{props.message}</Typography>
      </AltSuccessModal>
    </>
  );
};

export default AltSuccessComponent;
