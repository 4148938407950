import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { stringAvatar } from '../../helpers/helperFunctions';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useDetectResize from '../../hooks/useDetectResize';
import {
  DistBadge,
  HealthBarInner,
  HealthBarOuter,
  HoursBadge,
  InterestBadge,
  RecoCardItem,
  SkillsBadge,
} from '../UI/StyledComponents/RecommendationStyledComponent';
import NearMeIcon from '@mui/icons-material/NearMe';
import { width } from '@mui/system';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';

interface PropsJorECard {
  //jobs or employer card (JorE)
  details: any;
  jobType?: number;
  searchBy: string;
  highlightClass: string;
  setActive: (id: string, distance: number) => void;
  primaryCoordinates: { long: number; lat: number };
  secondaryCoordinates: { long: number; lat: number };
  updateDetected: boolean;
  availability?: string;
  sCount?: number;
  iCount?: number;
}

//When user is going to recommendations via Individual
const RecommendationsCard = (props: PropsJorECard) => {
  const {
    details,
    jobType,
    searchBy,
    highlightClass,
    setActive,
    primaryCoordinates,
    secondaryCoordinates,
    updateDetected,
    availability,
    sCount,
    iCount,
  } = props;

  const [score, setScore] = useState<number | null>(null);
  const { isTablet, isMobile, isDesktop, isLaptop } = useDetectResize();
  //console.log(details)

  useEffect(() => {
    if (searchBy === 'cumulative') {
      setScore(details.total);
    } else if (searchBy === 'aligned') {
      setScore(details.score);
    }
  }, [searchBy, details.score, details.total, updateDetected]);

  // useEffect(()=>{
  //   setActive(details.id, details.distance);
  // },[]);

  const buildMapLink = (
    primary: { long: number; lat: number },
    secondary: { long: number; lat: number }
  ) => {
    const link = `https://www.google.com/maps/dir/${primary.lat},${primary.long}/${secondary.lat},${secondary.long}/`;
    return link;
  };

  const MainInfo = () => {
    return (
      <>
        {(isDesktop || isLaptop) && (
          <>
            {details.profImg === '' ? (
              <Avatar
                {...stringAvatar(details?.id?.startsWith('job') ? details?.desc : details?.title)}
              />
            ) : (
              <img src={details.profImg} alt="prof-img" />
            )}
          </>
        )}

        <Box className="recomm-desc-cont">
          <Typography variant="h3" color={'#050505'}>
            {details.title}
          </Typography>
          <Typography variant="body2" fontWeight={'bold'} color={'#4f5f71'}>
            {details.desc}
          </Typography>
          {details?.id?.startsWith('job') && !(isMobile || isTablet) && (
            <Typography variant="caption" className="job-type-badge">
              {jobType! === 0
                ? 'In-person'
                : jobType! === 1
                ? 'Remote'
                : jobType! === 2
                ? 'Hybrid'
                : 'DeleteOutlineOutlined'}
            </Typography>
          )}
          {searchBy === 'aligned' && (isMobile || isTablet) && <DisplaySkillInterstBadges />}
          {searchBy === 'cumulative' && (isMobile || isTablet) && <DisplayBarUI score={score!} />}
        </Box>
      </>
    );
  };

  const DisplayNearestDesktop = () => {
    return (
      <>
        <Button>
          <a
            href={buildMapLink(primaryCoordinates, secondaryCoordinates)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LocationOnIcon />
          </a>
        </Button>
        <Box className="distance-badge recomm-metric-cont">
          <Typography variant="body1" color="white">
            {details.distance.toFixed(1)} km away
          </Typography>
        </Box>
      </>
    );
  };

  const DisplayNearestMobile = () => {
    return (
      <>
        <Box>
          <DistBadge className="recomm-metric-cont">
            <Typography variant="caption" lineHeight={1}>
              {details.distance.toFixed(1)} km
            </Typography>
          </DistBadge>
          {details.availability ? (
            <HoursBadge className="recomm-metric-cont">
              <Typography variant="caption" lineHeight={1}>
                {details.availability} hrs
              </Typography>
            </HoursBadge>
          ) : (
            <></>
          )}
        </Box>

        <Button
          sx={{
            backgroundColor: 'var(--background)',
            display: 'flex',
            minWidth: '30px',
            marginLeft: '20px',
            boxShadow: '2px 2px 3px 1px #00000047',
          }}
        >
          <a
            href={buildMapLink(primaryCoordinates, secondaryCoordinates)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LocationOnIcon sx={{ color: 'var(--secondary-color)', display: 'flex' }} />
          </a>
        </Button>
      </>
    );
  };

  const DisplaySkillInterstBadges = () => {
    return (
      <>
        <InlineContainer justifyContent="flex-start" alignItems="center" marginTop={'2.25px'}>
          <SkillsBadge>
            <Typography variant="caption" fontSize={9} fontWeight={600} lineHeight={1}>
              {details.sCount > 0 ? `${details.sCount} skills` : `0 skills`}
            </Typography>
          </SkillsBadge>
          <InterestBadge>
            <Typography variant="caption" fontSize={9} fontWeight={600} lineHeight={1}>
              {details.iCount > 0 ? `${details.iCount} interests` : `0 interests`}
            </Typography>
          </InterestBadge>
        </InlineContainer>
      </>
    );
  };

  return isMobile || isTablet ? (
    <RecoCardItem
      className={highlightClass === details.id ? 'recomm-highlight' : ''}
      onClick={() => {
        setActive(details.id, details.distance);
      }}
    >
      <MainInfo />
      <DisplayNearestMobile />
    </RecoCardItem>
  ) : (
    <Box
      key={details.id}
      className={`full-width inline-around recomm-card ${
        highlightClass === details.id ? 'recomm-highlight' : ''
      }`}
      onClick={() => {
        setActive(details.id, details.distance);
      }}
    >
      {details === null ? (
        <Typography variant="body1">Show nothing Container</Typography>
      ) : (
        <>
          <MainInfo />
          {searchBy === 'location' && <DisplayNearestDesktop />}
          {(searchBy === 'aligned' || searchBy === 'cumulative') && <DisplayBarUI score={score!} />}
        </>
      )}
    </Box>
  );
};

export default RecommendationsCard;

export const DisplayBarUI = (props: { score: number }) => {
  const barOuterStyle = {
    background: 'rgba(128, 128, 128, 0.457)',
    boxShadow: 'inset 0px 0px 3px 2px #12121240',
  };

  const metricBarInner = (score: number) => {
    return {
      width: `${score < 100 ? score : 100}%`,
      height: '100%',
      background:
        'linear-gradient(0deg, rgba(5,113,183,1) 28%, rgba(0,154,255,1) 50%, rgba(5,113,183,1) 81%)',
      boxShadow: `${score >= 100 ? '#bdbdbd 1px 1px 3px 1px' : 'none'}`,
      borderRadius: '10px',
    };
  };

  return (
    <div className="recomm-metric-cont text-overflow">
      <HealthBarOuter className="metric-bar-outer" style={barOuterStyle}>
        <HealthBarInner
          className={`${props.score! >= 100 ? ' pulse-animate' : ''}`}
          sx={metricBarInner(props.score!)}
        ></HealthBarInner>
      </HealthBarOuter>
    </div>
  );
};
