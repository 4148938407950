import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';

import useTranslate from '../../hooks/useTranslate';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import Icon from '../../asssets/icons';

function EngagementLinks({
  individualId,
  leadProviderOrgId,
}: {
  individualId: string;
  leadProviderOrgId: string;
}) {
  const t = useTranslate();
  return (
    <section>
      <InlineContainer
        justifyContent="flex-start"
        className="iet-header inline full-width"
        marginTop="1.5em"
      >
        <img src={Icon.AttachmentIcon} alt="doc-icon" />
        <Typography variant="h3" color="black">
          {t('activity-feed').toUpperCase()}
        </Typography>
      </InlineContainer>
      <Box className="individual-notes">
        <Stack direction="row" spacing={2}>
          <div>
            <div className="listing">
              <Button
                component={RouterLink}
                to={`/activity/${leadProviderOrgId}/${individualId}`}
                state={{ activity: 1 }}
                sx={{
                  fontWeight: '600',
                }}
              >
                View Liked Job
              </Button>
            </div>
          </div>
          <div>
            <div className="listing">
              <Button
                component={RouterLink}
                to={`/activity/${leadProviderOrgId}/${individualId}`}
                state={{ activity: 2 }}
                sx={{
                  fontWeight: '600',
                }}
              >
                View Applied Jobs
              </Button>
            </div>
          </div>
        </Stack>
      </Box>
    </section>
  );
}

export default EngagementLinks;
