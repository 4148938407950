import React, { Fragment, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ModalOverlayPositioning } from '../StyledComponents/NotificationModalStyledComponents';
import { BackdropOverlay } from '../StyledComponents/GlobalStyledComponents';

const BackDropComponent = (props: { onHide: () => void; className: string }) => {
  //Teleport this to root-backdrop
  return <BackdropOverlay className={`backdrop`} onClick={props.onHide} />;
};

const ModalOverlay = (props: {
  className: string;
  children: React.ReactNode;
  extraStyling?: string;
  onHide: () => void;
}) => {
  return (
    <div className={props.className}>
      <div className="modal__content " onClick={props.onHide}>
        {props.children}
      </div>
    </div>
  );
};

const Modal = (props: {
  onHide: () => void;
  className?: string;
  children: React.ReactNode;
  extraStyling?: string;
  top?: number;
  right?: number;
  hideBackdrop?: boolean;
}) => {
  useEffect(() => {
    document!.getElementById('body')?.scrollIntoView({ behavior: 'instant' as ScrollBehavior })!;
    document!.getElementById('body')!.style.overflow = 'hidden';
    return () => {
      document!.getElementById('body')!.style.overflow = 'scroll';
    };
  }, []);

  return (
    <Fragment>
      {!props.hideBackdrop &&
        ReactDOM.createPortal(
          <BackDropComponent className={props.className || ''} onHide={props.onHide} />,
          document.getElementById('root-popup')!
        )}
      {ReactDOM.createPortal(
        <ModalOverlay
          className={props.className || ''}
          extraStyling={props.extraStyling || ''}
          onHide={props.onHide}
        >
          {props.children}
        </ModalOverlay>,
        document.getElementById('root-popup')!
      )}
    </Fragment>
  );
};

export default Modal;
