import { Button, Typography } from '@mui/material';
import { capitalize } from '../../../helpers/helperFunctions';
import useTranslate from '../../../hooks/useTranslate';
import CheckMark from '../Animation/CheckMark';

interface SuccessProps {
  onAcknowledged: () => void;
  noun: string;
}

const SuccessComponent = (props: SuccessProps) => {
  const { onAcknowledged, noun } = props;
  const t = useTranslate();
  return (
    <div className="success-overlay">
      <div className="success-text">
        <div className="success-wrpr">
          <CheckMark />
        </div>
        <Typography variant="h1" textAlign="center">
          {capitalize(noun)} {t('savedSuccess')}
        </Typography>
        <Button
          variant="contained"
          onClick={onAcknowledged}
          sx={{ background: 'var(--primary-color)' }}
        >
          {t('view')} {noun}
        </Button>
      </div>
    </div>
  );
};

export default SuccessComponent;
