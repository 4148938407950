import AdministrationBusinessAndManagementWide from './administration-business-and-management-wide.webp';
import AdministrationBusinessAndManagement from './administration-business-and-management.webp';
import AnimalsLandAndEnvironmentWide from './animals-land-and-environment-wide.webp';
import AnimalsLandAndEnvironment from './animals-land-and-environment.webp';
import AutomotiveAndGarageServicesWide from './automotive-and-garage-services-wide.webp';
import AutomotiveAndGarageServices from './automotive-and-garage-services.webp';
import ComputingAndIctWide from './computing-and-ict-wide.webp';
import ComputingAndIct from './computing-and-ict.webp';
import ConstructionAndBuildingWide from './construction-and-building-wide.webp';
import ConstructionAndBuilding from './construction-and-building.webp';
import CourierAndDeliveryServicesWide from './courier-and-delivery-services-wide.webp';
import CourierAndDeliveryServices from './courier-and-delivery-services.webp';
import CustomerCareAndSupportWide from './customer-care-and-support-wide.webp';
import CustomerCareAndSupport from './customer-care-and-support.webp';
import DesignArtsAndCraftsWide from './design-arts-and-crafts-wide.webp';
import DesignArtsAndCrafts from './design-arts-and-crafts.webp';
import EducationAndTrainingWide from './education-and-training-wide.webp';
import EducationAndTraining from './education-and-training.webp';
import EngineeringWide from './engineering-wide.webp';
import Engineering from './engineering.webp';
import FacilitiesCleaningAndPropertyServicesWide from './facilities-cleaning-and-property-services-wide.webp';
import FacilitiesCleaningAndPropertyServices from './facilities-cleaning-and-property-services.webp';
import FinancialServicesWide from './financial-services-wide.webp';
import FinancialServices from './financial-services.webp';
import FoodServicesWide from './food-services-wide.webp';
import FoodServices from './food-services.webp';
import HairdressingAndBeautyWide from './hairdressing-and-beauty-wide.webp';
import HairdressingAndBeauty from './hairdressing-and-beauty.webp';
import HealthcareWide from './healthcare-wide.webp';
import Healthcare from './healthcare.webp';
import HeritageCultureAndLibrariesWide from './heritage-culture-and-libraries-wide.webp';
import HeritageCultureAndLibraries from './heritage-culture-and-libraries.webp';
import HospitalityCateringAndTourismWide from './hospitality-catering-and-tourism-wide.webp';
import HospitalityCateringAndTourism from './hospitality-catering-and-tourism.webp';
import LegalServicesWide from './legal-services-wide.webp';
import LegalServices from './legal-services.webp';
import ManufacturingAndProductionWide from './manufacturing-and-production-wide.webp';
import ManufacturingAndProduction from './manufacturing-and-production.webp';
import RecyclingWide from './recycling-wide.webp';
import Recycling from './recycling.webp';
import RetailWide from './retail-wide.webp';
import Retail from './retail.webp';
import SecurityUniformedAndProtectiveServicesWide from './security-uniformed-and-protective-services-wide.webp';
import SecurityUniformedAndProtectiveServices from './security-uniformed-and-protective-services.webp';
import SocialWorkAndCaringServicesWide from './social-work-and-caring-services-wide.webp';
import SocialWorkAndCaringServices from './social-work-and-caring-services.webp';
import SportsWide from './sports-wide.webp';
import Sports from './sports.webp';

const IndImage = {
  AdministrationBusinessAndManagementWide,
  AdministrationBusinessAndManagement,
  AnimalsLandAndEnvironmentWide,
  AnimalsLandAndEnvironment,
  AutomotiveAndGarageServicesWide,
  AutomotiveAndGarageServices,
  ComputingAndIctWide,
  ComputingAndIct,
  ConstructionAndBuildingWide,
  ConstructionAndBuilding,
  CourierAndDeliveryServicesWide,
  CourierAndDeliveryServices,
  CustomerCareAndSupportWide,
  CustomerCareAndSupport,
  DesignArtsAndCraftsWide,
  DesignArtsAndCrafts,
  EducationAndTrainingWide,
  EducationAndTraining,
  EngineeringWide,
  Engineering,
  FacilitiesCleaningAndPropertyServicesWide,
  FacilitiesCleaningAndPropertyServices,
  FinancialServicesWide,
  FinancialServices,
  FoodServicesWide,
  FoodServices,
  HairdressingAndBeautyWide,
  HairdressingAndBeauty,
  HealthcareWide,
  Healthcare,
  HeritageCultureAndLibrariesWide,
  HeritageCultureAndLibraries,
  HospitalityCateringAndTourismWide,
  HospitalityCateringAndTourism,
  LegalServicesWide,
  LegalServices,
  ManufacturingAndProductionWide,
  ManufacturingAndProduction,
  RecyclingWide,
  Recycling,
  RetailWide,
  Retail,
  SecurityUniformedAndProtectiveServicesWide,
  SecurityUniformedAndProtectiveServices,
  SocialWorkAndCaringServicesWide,
  SocialWorkAndCaringServices,
  SportsWide,
  Sports,
};

export default IndImage;
