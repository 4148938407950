import { Box } from '@mui/system';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import React from 'react';
import useMobileTableWidths from '../../hooks/useMobileTableWidths';
import useDetectResize from '../../hooks/useDetectResize';

const CustomMobileSearchBarComponent = () => {
  const { windowDimensions, isMobile } = useDetectResize();
  const { tableWidth } = useMobileTableWidths({
    mobilePercent: 0.8,
    tabletPercent: 0.65,
    mediaWidth: windowDimensions.w,
    isMobile: isMobile,
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 0',
          borderBottom: '1px solid #4f5f713d',
        }}
      >
        <Box
          sx={{
            border: '1px solid #4f5f713d',
            borderRadius: '15px',
            padding: '5px',
            width: tableWidth,
          }}
        >
          <GridToolbarQuickFilter sx={{ width: '100%' }} debounceMs={500} />
        </Box>
      </Box>
      {/* <InlineContainer justifyContent={'flex-start'} alignItems={'center'}>
      <Typography variant='h2'> Sort by: </Typography>
      <SortByAlphaIcon sx={{margin: '0px 5px', padding: '1px'}} onClick={sortListByTitle} />
      <Button sx={{margin: '0px 5px'}}  size='small' variant='contained' onClick={sortListByTitle}>Status</Button>
    </InlineContainer> */}
    </Box>
  );
};

export default CustomMobileSearchBarComponent;
