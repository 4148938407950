import LogoLrgTag from '../images/new-logo-lrg-tag.svg';
import LogoLrg from '../images/new-logo-lrg.svg';
import LogoVTag from '../images/new-logo-v-tag.svg';
import LogoV from '../images/new-logo-v.svg';
import SearchIcon from '../images/search-icon.webp';
import PlaceHolder from '../images/placeholder.webp';
import PlaceHolderWide from '../images/placeholder-wide.webp';

const Image = {
  LogoLrgTag,
  LogoLrg,
  LogoVTag,
  LogoV,
  SearchIcon,
  PlaceHolder,
  PlaceHolderWide,
};

export default Image;
