import { useState } from 'react';

type AutoCompleteItem = string;

const useAutoCompleteItems = () => {
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<AutoCompleteItem[]>([]);

  return {
    optionItems: autoCompleteOptions,
    setOptionItems: setAutoCompleteOptions,
  };
};

export default useAutoCompleteItems;
