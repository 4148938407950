import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, Typography } from '@mui/material';

import { Individual } from '../../typescript/types';
import {
  IndMobileAvatar,
  InlineContainer,
  MultiLineOverFlow,
} from '../../UI/StyledComponents/GlobalStyledComponents';
import { getFullName, stringAvatar } from '../../../helpers/helperFunctions';
import useTranslate from '../../../hooks/useTranslate';
import { HrsBadge, IndStatusBadge } from '../../SharedComponents/SharedBadges';
import { KeywordBox, KeywordTitle } from '../../UI/StyledComponents/RecommendationStyledComponent';
import { convertTagsToString } from '../../../helpers/displayOptions';
import { baseTagStyling, interestStyling, skillStyling } from '../../../helpers/styling';
import { getTags } from '../../../store/keywordSlice';
import { RootState } from '../../../store';

const IndTabletCard = (props: { ind: Individual }) => {
  const { ind } = props;
  const t = useTranslate();
  const TAGS = useSelector((state: RootState) => getTags(state, t));
  const [skillCharCount, setSkillCharCount] = useState<number>(0);
  const [interestsCharCount, setInterestCharCount] = useState<number>(0);

  useEffect(() => {
    const sArr = (convertTagsToString(ind!.skills, true, TAGS?.all) as string[]).map(
      (tag: string, index: number) => {
        if (index < 3) {
          return tag;
        } else {
          return;
        }
      }
    );

    const skillCharCount = sArr.filter((elm) => elm !== undefined).join().length;
    setSkillCharCount(skillCharCount);

    const iArr = (convertTagsToString(ind!.interests, true, TAGS?.all) as string[]).map(
      (tag: string, index: number) => {
        if (index < 3) {
          return tag;
        } else {
          return;
        }
      }
    );
    const charCount = iArr.filter((elm) => elm !== undefined).join().length;
    setInterestCharCount(charCount);
  }, []);

  return (
    //Card wrapper
    <Box
      className="ind-tablet-card"
      sx={{
        position: 'relative',
        padding: '25px',
        height: '245px',
      }}
    >
      <InlineContainer
        justifyContent={'flex-start'}
        alignItems="center"
        width="100%"
        marginBottom="0px"
      >
        {/* logo */}
        <IndMobileAvatar {...stringAvatar(`${ind?.firstName} ${ind?.lastName}`)} />
        <Box
          className="ind-tablet-top-container"
          sx={{
            display: 'inline-grid',
            gap: '5px',
            width: '-webkit-fill-available',
            gridTemplateRows: '40px',
          }}
        >
          {/* full name */}
          <Box
            sx={{
              gridColumn: '1 /span 4',
              gridRow: '1',
            }}
          >
            <InlineContainer justifyContent={'space-between'}>
              <MultiLineOverFlow
                variant="h3"
                fontWeight={900}
                sx={{ width: '100%', paddingTop: '4px' }}
                lineCount={1}
              >
                {getFullName(ind!)}
              </MultiLineOverFlow>
              <InlineContainer justifyContent={'space-around'} width="30% !important">
                <IndStatusBadge isOpen={ind?.status} />
                <HrsBadge numerator={ind?.filledHours} denominator={ind?.availableHours} />
              </InlineContainer>
            </InlineContainer>
          </Box>

          {/* status badge */}
          {/* <Box
                sx={{
                  gridColumn: '1 /span 3',
                  gridRow:'2'
                }}
              >
              </Box> */}
          {/* location */}
          <Box
            textAlign="center"
            sx={{
              gridColumn: '1/span 4',
              gridRow: '2',
            }}
          >
            <Typography
              variant="body1"
              marginTop="6px"
              fontWeight={600}
              sx={{
                whiteSpace: 'break-spaces',
                width: '100%',
                fontSize: '14px !important',
                textAlign: 'left',
              }}
            >
              {ind?.address}
            </Typography>
          </Box>
        </Box>
      </InlineContainer>

      {/* Skills and Interest Box */}
      <Box>
        <KeywordBox>
          <KeywordTitle>
            <Typography variant="subtitle2">{t('skills')}</Typography>
          </KeywordTitle>
          <InlineContainer height="100%">
            <Box
              className="keywords-array inline-btwn hide-scroll"
              sx={{
                flexFlow: 'nowrap',
                overflow: 'hidden',
                marginLeft: '10px',
              }}
            >
              {ind &&
                (convertTagsToString(ind!.skills, true, TAGS?.all) as string[]).map(
                  (tag: string, index: number) => {
                    if (index < 2 && skillCharCount >= 65) {
                      return (
                        <Paper sx={[baseTagStyling, skillStyling]} key={index + '-skillsTag'}>
                          {' '}
                          {tag}
                        </Paper>
                      );
                    } else if (index === 2 && skillCharCount >= 65) {
                      return (
                        <Paper sx={[baseTagStyling, skillStyling]} key={index + '-skillsTag'}>
                          {' '}
                          +{ind!.skills.length - 2} more
                        </Paper>
                      );
                    } else if (index < 3 && skillCharCount < 65) {
                      return (
                        <Paper sx={[baseTagStyling, skillStyling]} key={index + '-skillsTag'}>
                          {tag}
                        </Paper>
                      );
                    } else if (index === 3 && skillCharCount < 65) {
                      return (
                        <Paper sx={[baseTagStyling, skillStyling]} key={index + '-skillsTag'}>
                          {' '}
                          +{ind!.skills.length - 3} more
                        </Paper>
                      );
                    } else if (ind!.skills.length === 0) {
                      return (
                        <Paper sx={[baseTagStyling, skillStyling]} key={index + '-interestsTag'}>
                          {' '}
                          {t('none')}{' '}
                        </Paper>
                      );
                    } else {
                      return null;
                    }
                  }
                )}
            </Box>
          </InlineContainer>
        </KeywordBox>

        <KeywordBox>
          <KeywordTitle>
            <Typography variant="subtitle2">{t('interests')}</Typography>
          </KeywordTitle>
          <InlineContainer height="100%">
            <Box
              className="keywords-array inline-btwn"
              sx={{
                flexFlow: 'nowrap',
                overflow: 'hidden',
                marginTop: '5px',
                marginLeft: '10px',
              }}
            >
              {ind &&
                (convertTagsToString(ind!.interests, true, TAGS?.all) as string[]).map(
                  (tag: string, index: number) => {
                    if (index < 2 && interestsCharCount >= 60) {
                      return (
                        <Paper sx={[baseTagStyling, skillStyling]} key={index + '-skillsTag'}>
                          {' '}
                          {tag}
                        </Paper>
                      );
                    } else if (index === 2 && interestsCharCount >= 60) {
                      return (
                        <Paper sx={[baseTagStyling, skillStyling]} key={index + '-skillsTag'}>
                          {' '}
                          +{ind!.interests.length - 2} more
                        </Paper>
                      );
                    } else if (index < 3 && interestsCharCount < 60) {
                      return (
                        <Box sx={[baseTagStyling, interestStyling]} key={index + '-interestsTag'}>
                          {tag}
                        </Box>
                      );
                    } else if (index === 3 && interestsCharCount < 60) {
                      return (
                        <Paper sx={[baseTagStyling, skillStyling]} key={index + '-interestsTag'}>
                          {' '}
                          +{ind!.interests.length - 3} more
                        </Paper>
                      );
                    } else {
                      return null;
                    }
                  }
                )}
              {ind!.interests.length === 0 && (
                <Paper sx={[baseTagStyling, skillStyling]} key={'no-interestsTag'}>
                  {t('none')}{' '}
                </Paper>
              )}
            </Box>
          </InlineContainer>
        </KeywordBox>
      </Box>
    </Box>
  );
};

export default IndTabletCard;
