import { useEffect } from 'react';

const useFocusTrap = (ref: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    // Include MUI Accordion selectors, typically buttons in the accordion headers
    const focusableElementsString =
      'a[href], area[href], input:not([disabled]), select:not([disabled]), ' +
      'textarea:not([disabled]), button:not([disabled]), [tabindex="0"], ' +
      '.react-select__control';

    const trapFocus = (event: KeyboardEvent) => {
      if (ref.current && event.key === 'Tab') {
        const focusableElements = ref.current.querySelectorAll(focusableElementsString);
        const firstElement = focusableElements[0] as HTMLElement;
        const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

        if (event.shiftKey && document.activeElement === firstElement) {
          lastElement.focus();
          event.preventDefault();
        } else if (!event.shiftKey && document.activeElement === lastElement) {
          firstElement.focus();
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', trapFocus);

    return () => {
      document.removeEventListener('keydown', trapFocus);
    };
  }, [ref]);
};

export default useFocusTrap;
