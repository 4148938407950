import Icon from '../asssets/icons';
import TopWidgetComponent from '../components/DashboardComponents/TopWidgetComponent';
import WidgetComponent from '../components/DashboardComponents/WidgetComponent';

import { useSelector } from 'react-redux';
import { getMyProviderDetails } from '../store/providerSlice';
import { getEmployers } from '../store/employerSlice';
import { getAllJobs, getJobCount } from '../store/jobSlice'; //CHANGE THIS TO PROPER GETTER
import { getAllIndividuals, getIndividualsCount } from '../store/individualSlice';
import React from 'react';
import PageTitleComponent from '../components/PageTitleComponent';
import useTranslate from '../hooks/useTranslate';

const DashboardComponent: React.FC = () => {
  const provider = useSelector(getMyProviderDetails);
  const employerList = useSelector(getEmployers);
  const individualsList = useSelector(getAllIndividuals);
  const jobsList = useSelector(getAllJobs);
  const openJobsCount = useSelector(getJobCount);
  const unplacedIndividuals = useSelector(getIndividualsCount);
  const t = useTranslate();

  const greeting = `${t('hello')} ${provider?.firstName}`;

  return (
    <>
      <PageTitleComponent greeting={greeting} />

      <div className="dash-top inline">
        <TopWidgetComponent
          title={t('globalJobsTitle')}
          count={openJobsCount}
          IconImg={Icon.WhiteJicon}
          isMobile={false}
        />
        <TopWidgetComponent
          title={t('privateIndividualTitle')}
          count={unplacedIndividuals}
          IconImg={Icon.WhiteIicon}
          isMobile={false}
        />
      </div>

      <div className="dash-main inline">
        <div className="dash-col1">
          <WidgetComponent
            widget="Job"
            title={t('jobsWidgetTitle')}
            latestItems={jobsList.filter((job) => job.isJobActive)}
          />
        </div>
        <div className="dash-col2">
          <WidgetComponent
            widget="Individual"
            title={t('individualWidgetTitle')}
            latestItems={individualsList.filter((individual) => individual.status)}
          />
          <WidgetComponent
            widget="Employer"
            title={t('privateEmployerTitle')}
            latestItems={employerList.filter((employer) => employer.status)}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardComponent;

export const MobileDashboardGreeting = () => {
  const provider = useSelector(getMyProviderDetails);
  const t = useTranslate();
  const greeting = `${t('hello')} ${provider?.firstName}`;

  return <PageTitleComponent greeting={greeting} />;
};
