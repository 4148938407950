import React, { useEffect, useState } from 'react';
import { Individual } from '../components/typescript/types';

const totalProperties = [
  'firstName',
  'lastName',
  'pronouns',
  'preferredComms',
  'requiredAccomodation',
  'email',
  'address',
  'phone',
  'dob',
  'preferredRoles',
  'minRate',
  'maxRate',
  'fileAttachments',
  'referenceAttachments',
  'certificationAttachments',
  'skills',
  'interests',
  'restrictionsText',
  'availableHours',
  'filledHours',
  'employmentRecord',
];

const useProfileCompletion = (individual?: Individual) => {
  const [score, setScore] = useState<number>(0);

  const isAttachments = ['referenceAttachments', 'certificationAttachments']; //used to catch the file attachment properties.
  const isResumeCV = ['fileAttachments']; // same, but file attachment properties is a array[2] and we need to check index 0/1 for resumse/coverletter.
  useEffect(() => {
    let numerator = 0;
    const denominator = totalProperties.length;

    if (individual) {
      for (const property of totalProperties) {
        console.log(property);
        let value = individual![`${property as keyof Individual}`]!;
        console.log(value);

        switch (typeof value!) {
          case 'object':
            if (isAttachments.includes(property) && value[0] !== 'empty') {
              numerator++;
            } else if (isAttachments.includes(property) && value[0] === 'empty') {
              numerator--;
            } else if (isResumeCV.includes(property) && value[0] !== 'empty') {
              numerator++;
            } else if (isResumeCV.includes(property) && value[1] !== 'empty') {
              numerator++;
            } else if (isResumeCV.includes(property) && value[0] === 'empty') {
              numerator--;
            } else if (isResumeCV.includes(property) && value[1] === 'empty') {
              numerator--;
            } else if (value?.length === 0) {
              numerator--;
            } else if (value?.length !== 0) {
              numerator++;
            } else {
              console.warn(`UNACCOUNUTED FOR OBJECT! ${property}: ${value}`);
            }
            break;
          case 'string':
            if (value !== '') {
              numerator++;
            } else {
              numerator--;
            }
            break;
          case 'number':
            if (value !== 0) {
              numerator++;
            } else {
              numerator--;
            }
            break;
          default:
            console.warn(`${property} : ${value} unaccounted for type ${typeof value}`);
            break;
        }
      }
    }

    setScore((currState) => (currState = (numerator / (denominator + 1)) * 100)); //'adding plus one because resumse coverletter are stored in array of 2.
  }, [individual]);

  return score;
};

export default useProfileCompletion;
