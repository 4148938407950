import useTranslate from './useTranslate';

const useGridLocale = () => {
  const t = useTranslate();
  const gridLocaleSettings = {
    toolbarDensity: t('density'),
    toolbarColumns: t('columns'),
    toolbarFilters: t('filters'),
    toolbarExport: t('export'),
    toolbarExportLabel: t('export'),
    checkboxSelectionHeaderName: t('checkBoxSelection'),
    noRowsLabel: t('noRows'),
    noResultsOverlayLabel: t('noResults'),
    toolbarQuickFilterPlaceholder: t('search...'),
    columnsPanelTextFieldPlaceholder: t('columnTitle'),
    columnsPanelTextFieldLabel: t('findColumn'),
    columnsPanelShowAllButton: t('showAll'),
    columnsPanelHideAllButton: t('hideAll'),
    filterPanelInputPlaceholder: t('filterValue'),
    filterPanelOperatorAnd: t('and'),
    filterPanelOperatorOr: t('or'),
    filterPanelColumns: t('columns'),
    filterPanelInputLabel: t('value'),
    filterOperatorContains: t('contains'),
    filterOperatorEquals: t('equals'),
    filterOperatorStartsWith: t('startsWith'),
    filterOperatorEndsWith: t('endsWith'),
    filterOperatorIsEmpty: t('isEmpty'),
    filterOperatorIsNotEmpty: t('isNotEmpty'),
    filterPanelOperator: t('operator'),
    filterOperatorIsAnyOf: t('isAnyOf'),
    toolbarDensityCompact: t('compact'),
    toolbarDensityStandard: t('standard'),
    toolbarDensityComfortable: t('comfortable'),
    toolbarExportCSV: t('dlCSV'),
    toolbarExportPrint: t('print'),
    columnMenuLabel: 'Menu',
    columnMenuShowColumns: 'Show columns',
    columnMenuManageColumns: 'Manage columns',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Hide column',
    columnMenuUnsort: 'Unsort',
    columnMenuSortAsc: 'Sort by ASC',
    columnMenuSortDesc: 'Sort by DESC',
  };

  return gridLocaleSettings;
};

export default useGridLocale;
