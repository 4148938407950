import { Typography } from '@mui/material';
import Icon from '../../asssets/icons';
import { validateURL } from '../../helpers/helperFunctions';
import useTranslate from '../../hooks/useTranslate';

const IndividualAttachmentDownload = (props: { fileName?: string }) => {
  const { fileName } = props;
  const t = useTranslate();
  return (
    <div className="inline-a full-width child-spacing align-dl-icon-right">
      <img src={Icon.AttachmentIcon} alt="doc-icon" />
      <Typography variant="body1" color="black">
        {fileName?.split('-').pop() || t('empty')}
      </Typography>
      {fileName?.startsWith('https://') && (
        <a href={validateURL(fileName) ? fileName : ''} target="_self" download={'doc'}>
          <img src={Icon.DocDownload} alt="download-icon" />
        </a>
      )}
    </div>
  );
};

export default IndividualAttachmentDownload;
