import React, { useEffect, useState } from 'react';

const useMobileTableWidths = (props: {
  mobilePercent: number;
  tabletPercent: number;
  mediaWidth: number;
  isMobile: boolean;
}) => {
  const { mobilePercent, tabletPercent, mediaWidth, isMobile } = props;
  const [tableWidth, setTableWidth] = useState<number>(0);

  useEffect(() => {
    const width = isMobile ? mediaWidth * mobilePercent : mediaWidth * tabletPercent;
    setTableWidth((currState: number) => (currState = width));
  }, [mediaWidth, isMobile]);

  return {
    tableWidth,
  };
};

export default useMobileTableWidths;
