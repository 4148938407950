import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import Icon from '../../asssets/icons';
import { Individual } from '../typescript/types';
import IndividualAttachmentDownload from './IndividualAttachmentDownload';
import useTranslate from '../../hooks/useTranslate';
import { Typography } from '@mui/material';
import { OverviewTitle } from '../UI/StyledComponents/ViewStyledComponents';
import { IconStyling } from '../UI/StyledComponents/RecommendationStyledComponent';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';

const IndividualAttachments = (props: { individual?: Individual; showTitle?: boolean }) => {
  const { individual } = props;
  const t = useTranslate();

  return (
    <section>
      {props.showTitle && (
        <InlineContainer
          className="iet-header inline full-width"
          justifyContent={'flex-start'}
          marginTop="1.5em"
        >
          <img className="attch-icon" src={Icon.DocDownload} alt="download-icon" />
          <Typography variant="h3" color="black">
            {t('attachmentsTitle').toUpperCase()}
          </Typography>
        </InlineContainer>
      )}

      <br />

      <OverviewTitle variant="h4" color="primary.main">
        {t('resumeUploadTitle').toUpperCase()}
      </OverviewTitle>
      <IndividualAttachmentDownload fileName={individual?.fileAttachments[0]} key={nanoid()} />

      <OverviewTitle variant="h4" color="primary.main">
        {t('coverLetterUploadTitle').toUpperCase()}
      </OverviewTitle>
      <IndividualAttachmentDownload fileName={individual?.fileAttachments[1]} key={nanoid()} />

      <OverviewTitle variant="h4" color="primary.main">
        {t('references').toUpperCase()}
      </OverviewTitle>
      <div className="stack-vertically">
        {!!individual?.referenceAttachments!.length ? (
          individual?.referenceAttachments!.map((file: string, index: number) => {
            return <IndividualAttachmentDownload fileName={file} key={index + '-reference'} />;
          })
        ) : (
          <IndividualAttachmentDownload />
        )}
      </div>

      <OverviewTitle variant="h4" color="primary.main">
        CERTIFICATIONS
      </OverviewTitle>
      <div className="stack-vertically">
        {!!individual?.certificationAttachments!.length ? (
          individual!.certificationAttachments!.map((file: string, index: number) => {
            return <IndividualAttachmentDownload fileName={file} key={index + '-certs'} />;
          })
        ) : (
          <IndividualAttachmentDownload />
        )}
      </div>
    </section>
  );
};

export default IndividualAttachments;
