export const enTrans = [
  {
    key: 'hello',
    value: 'Hello',
  },
  {
    key: 'dashboardNav',
    value: 'Dashboard',
  },
  {
    key: 'employerNav',
    value: 'Employers',
  },
  {
    key: 'job',
    value: 'job',
  },
  {
    key: 'savingEmployer',
    value: 'Saving Employer',
  },
  {
    key: 'savingJob',
    value: 'Saving Job',
  },
  {
    key: 'savingIndividual',
    value: 'Saving Individual',
  },
  {
    key: 'savedSuccess',
    value: 'saved successfully!',
  },
  {
    key: 'view',
    value: 'View',
  },
  {
    key: 'viewing',
    value: 'Viewing',
  },
  {
    key: 'viewJobBtn',
    value: 'View Job',
  },
  {
    key: 'individual',
    value: 'individual',
  },
  {
    key: 'individualBtnText',
    value: 'individual',
  },
  { key: 'inviteNav', value: 'Invite' },
  { key: 'inviteTitle', value: 'Invite Organization Members' },
  {
    key: 'inviteText',
    value: `Fill out the form below to send an invitation to members of your organization. Invitiations expire within 7 days, but don't worry you can always send another!`,
  },
  {
    key: 'jobNav',
    value: 'Jobs',
  },
  {
    key: 'individualNav',
    value: 'Individual',
  },
  {
    key: 'globalJobsTitle',
    value: 'Jobs Open Network Wide',
  },
  {
    key: 'jobsWidgetTitle',
    value: 'Latest Jobs',
  },
  {
    key: 'public',
    value: 'Public',
  },
  {
    key: 'addJobBtn',
    value: 'Add Job',
  },
  {
    key: 'privateIndividualTitle',
    value: 'Individuals Open to Work',
  },
  {
    key: 'individualWidgetTitle',
    value: 'Latest Individuals',
  },
  {
    key: 'privateBtn',
    value: ' Private',
  },
  {
    key: 'add',
    value: 'Add',
  },
  {
    key: 'addIndividualBtn',
    value: 'Add Individual',
  },
  {
    key: 'privateEmployerTitle',
    value: 'Latest Employers',
  },
  {
    key: 'addEmployerBtn',
    value: 'Add Employer',
  },
  {
    key: 'notificationTitle',
    value: 'Notifications',
  },
  {
    key: 'notificationCountTextI',
    value: 'You have',
  },
  {
    key: 'of',
    value: 'of',
  },
  {
    key: 'a(f)',
    value: ' ',
  },
  {
    key: 'a(m)',
    value: ' ',
  },
  {
    key: 'notificationCountTextII',
    value: 'unread notifications',
  },
  {
    key: 'filterKeyEventsBtn',
    value: 'Filter key events',
  },
  {
    key: 'notificationText',
    value: 'Earlier',
  },
  {
    key: 'notificationMarkAllBtn',
    value: 'Mark all as read',
  },
  {
    key: 'logoutBtn',
    value: 'Log Out',
  },
  {
    key: 'empList',
    value: 'Employers List',
  },
  {
    key: 'columns',
    value: 'Columns',
  },
  {
    key: 'filters',
    value: 'Filters',
  },
  {
    key: 'density',
    value: 'Density',
  },
  {
    key: 'export',
    value: 'Export',
  },
  {
    key: 'checkBoxSelection',
    value: 'Checkbox selection',
  },
  {
    key: 'search',
    value: 'Search',
  },
  {
    key: 'orgName',
    value: 'Organization Name',
  },
  {
    key: 'parentOrgName',
    value: 'Parent Organization Name',
  },
  {
    key: 'supportLead',
    value: 'Employment Support Lead',
  },
  {
    key: 'mainCname',
    value: 'Main Contact Name',
  },
  {
    key: 'mainCphone',
    value: 'Main Contact Phone Number',
  },
  {
    key: 'mainCemail',
    value: 'Main Contact Email',
  },
  {
    key: 'address',
    value: 'Address',
  },
  {
    key: 'industry',
    value: 'Industry',
  },
  {
    key: 'description',
    value: 'Description',
  },
  {
    key: 'multiLocation',
    value: 'Multiple Locations',
  },
  {
    key: 'status',
    value: 'Status',
  },
  {
    key: 'reqSkills',
    value: 'Required Skills',
  },
  {
    key: 'keywords',
    value: 'Keywords',
  },
  {
    key: 'skillsAndIntersts',
    value: 'skills and interests',
  },
  {
    key: 'onboardDate',
    value: 'Date onboarded',
  },
  {
    key: 'jobList',
    value: 'Job List',
  },
  {
    key: 'jobTitleJobList',
    value: 'Job Title',
  },
  {
    key: 'closeDate',
    value: 'Closing Date',
  },
  {
    key: 'hrsPerWk',
    value: 'hours/week',
  },
  {
    key: 'schedule',
    value: 'Schedule',
  },
  {
    key: 'prefStartDate',
    value: 'Ideal Start Date',
  },
  {
    key: 'startDateLabel',
    value: 'Start Date',
  },
  {
    key: 'certReq',
    value: 'Certification Required',
  },
  {
    key: 'trainingAvail',
    value: 'Training Available',
  },
  {
    key: 'openToTraining',
    value: 'Open to coaching during training',
  },
  {
    key: 'trainingReq',
    value: 'Training Required',
  },
  {
    key: 'notesComments',
    value: 'Notes/Comments',
  },
  {
    key: 'individualList',
    value: 'Individuals List',
  },
  {
    key: 'fullNameIndividual',
    value: 'Full Name',
  },
  {
    key: 'phoneNumber',
    value: 'Phone Number',
  },
  {
    key: 'email',
    value: 'Email',
  },
  {
    key: 'dob',
    value: 'Birth Date',
  },
  {
    key: 'popoCheck',
    value: 'Last Police Check Date',
  },
  {
    key: 'creditCheck',
    value: 'Last Credit Check Date',
  },
  {
    key: 'prefRoles',
    value: 'Preferred Roles',
  },
  {
    key: 'minRate',
    value: 'Minimum Rate/Hour*',
  },
  {
    key: 'maxRate',
    value: 'Maximum Rate/Hour',
  },
  {
    key: 'skills',
    value: 'Skills',
  },
  {
    key: 'interests',
    value: 'Interests',
  },
  {
    key: 'restrictions',
    value: 'Restrictions',
  },
  {
    key: 'daysAvail',
    value: 'Available Days',
  },
  {
    key: 'currentWorkingHrs',
    value: 'Hours Currently working',
  },
  {
    key: 'totalAvailHrs',
    value: 'Total Available Hours',
  },
  {
    key: 'dateIntake',
    value: 'Intake Date',
  },
  {
    key: 'inviteTitle',
    value: 'Invite Organization Members',
  },
  {
    key: 'inviteText',
    value:
      'Fill out the form below to send an invitation to members of your organization. Invitiations expire within 7 days, but don’t worry you can always send another!',
  },
  {
    key: 'inviteEmailMsg',
    value: 'This email will be used to login to MyJobMatch',
  },
  {
    key: 'fName',
    value: 'First Name',
  },
  {
    key: 'lName',
    value: 'Last Name',
  },
  {
    key: 'inviteBtn',
    value: 'Send Invite',
  },
  {
    key: 'settingsTitle',
    value: 'Settings',
  },
  {
    key: 'basicInfo',
    value: 'Basic Information',
  },
  {
    key: 'profileImg',
    value: 'Profile Photo',
  },
  {
    key: 'userEmail',
    value: 'Account Email',
  },
  {
    key: 'changeEmailMsg',
    value: 'To change, contact support.',
  },
  {
    key: 'submitSettingsBtn',
    value: 'Update',
  },
  {
    key: 'submitSaveBtn',
    value: 'Save',
  },
  {
    key: 'employerBtnText',
    value: 'Employer',
  },
  {
    key: 'initialContract',
    value: 'Initial contract',
  },
  {
    key: 'followUpContract',
    value: 'Follow up contract',
  },
  {
    key: 'presentation',
    value: 'Presentation',
  },
  {
    key: 'jobPostingNew',
    value: 'Job posting - new',
  },
  {
    key: 'jobPostingRenew',
    value: 'Job posting - renew',
  },
  {
    key: 'activityType',
    value: 'Activity Type',
  },
  {
    key: 'closeJobTooltip',
    value: 'Close the job before deleting it',
  },
  {
    key: 'jobBtnText',
    value: `Job`,
  },
  {
    key: 'matches',
    value: 'Matches',
  },
  {
    key: 'helpBtn',
    value: 'Help',
  },
  {
    key: 'none',
    value: 'None',
  },
  {
    key: 'helpPrompt',
    value: 'Select one of the options below:',
  },
  {
    key: 'helpReportOption',
    value: 'Report a bug, issue or error',
  },
  {
    key: 'helpEmailOption',
    value: 'Send us an email',
  },
  {
    key: 'helpGuideOption',
    value: 'Read the user guide',
  },
  {
    key: 'here',
    value: 'here',
  },
  {
    key: 'NotificationTitle',
    value: 'Notifications',
  },
  {
    key: 'jobNewNotif',
    value: 'has a new job',
  },
  {
    key: 'jobUpdatedNotif',
    value: 'has updated a job',
  },
  {
    key: 'jobClosingNotif1',
    value: 'position for',
  },
  {
    key: 'jobClosingNotif2',
    value: 'is',
  },
  {
    key: 'jobClosingNotif3',
    value: 'closing in',
  },
  {
    key: 'jobClosingNotif4',
    value: 'day(s)',
  },
  {
    key: 'jobClosingNotif5',
    value: 'closing today!',
  },
  {
    key: 'newProvNotif',
    value: 'was added to your organization',
  },
  {
    key: 'profilUpdatedNotif',
    value: 'profile was updated',
  },
  {
    key: 'individualAddedNotif',
    value: 'added a job seeker',
  },
  {
    key: 'updated',
    value: 'updated',
  },
  {
    key: 'open',
    value: 'Open',
  },
  {
    key: 'closed',
    value: 'Closed',
  },
  {
    key: 'individualUpdatedNotif',
    value: 'a new individual’s profile',
  },
  { key: 'aNewPerson', value: 'a new individual' },
  {
    key: 'individualMilkExpirationNotif1',
    value: 'has been open to work for',
  },
  {
    key: 'individualMilkExpirationNotif2',
    value: 'days without any changes',
  },
  {
    key: 'individualMatchesNotif',
    value: 'has new job a recommendation(s)',
  },
  {
    key: 'employerViewTitle',
    value: 'EMPLOYER DETAILS',
  },
  {
    key: 'employerName',
    value: 'Employer',
  },
  {
    key: 'employersName',
    value: `E.g. Amazon`,
  },
  {
    key: 'addImg',
    value: `Upload logo`,
  },
  {
    key: 'addImgText',
    value: 'Add Image',
  },
  {
    key: 'defaultOption',
    value: 'default',
  },
  {
    key: 'leadProvLabel',
    value: 'Employment Support Lead',
  },
  {
    key: 'orgNameLabel',
    value: 'Organization name ',
  },
  {
    key: 'jobInputPlaceholder',
    value: 'E.g. Loblaws - Dupont St.',
  },
  {
    key: 'orgNameHelperText',
    value:
      'This is the name of your organization followed by an identifier (if multiple locations).',
  },
  {
    key: 'parentOrgNameLabel',
    value: 'Parent Organization',
  },
  {
    key: 'parentOrgNamePlaceholder',
    value: 'E.g. Loblaws',
  },
  {
    key: 'parentOrgNameHelperText',
    value: 'If only one location, Parent name is the same as Organization name.',
  },
  {
    key: 'multiLocLabel',
    value: 'Multiple Locations',
  },
  {
    key: 'industryLabel',
    value: 'Industry *',
  },
  {
    key: 'thousandMaxCharMsg',
    value: 'Max. 3000 characters.',
  },
  {
    key: 'twoThousandMaxCharMsg',
    value: 'Max. 3000 characters.',
  },
  {
    key: 'addressHelperText',
    value:
      'In order to save this profile, and to match by location, an address must be selected from the drop down.',
  },
  {
    key: 'reqSkills',
    value: 'Required Skills',
  },
  {
    key: 'reqSkillsPlaceholder',
    value: 'Standing for long hours, lifting over 50 lbs... etc.',
  },
  {
    key: 'keywordLabel',
    value: 'Keywords',
  },
  {
    key: 'keywordPlaceholder',
    value:
      'The more keywords used the more matches you generate between employers and individuals.',
  },
  {
    key: 'mcFirstNameLabel',
    value: 'Main contact’s first name',
  },
  {
    key: 'mcLastNameLabel',
    value: 'Main contact’s last name',
  },
  {
    key: 'mcPhoneNumberLabel',
    value: 'Main contact’s phone number',
  },
  {
    key: 'mcPhoneNumberMsg',
    value: 'Main contact’s phone number required',
  },
  {
    key: 'mcEmailLabel',
    value: 'Main contact’s email',
  },
  {
    key: 'employer',
    value: 'employer',
  },
  {
    key: 'employerProfile',
    value: 'Employer Profile',
  },
  {
    key: 'employerViewEditBtn',
    value: 'Edit Employer',
  },
  {
    key: 'employerDetails',
    value: 'Employer details',
  },
  {
    key: 'employerViewHeader2',
    value: 'EMPLOYER OVERVIEW',
  },
  {
    key: 'employerViewHeader3',
    value: 'EMPLOYER DESCRIPTION',
  },
  {
    key: 'emptyDetailsMsgEmployerView',
    value: 'No details available for this employer...',
  },
  {
    key: 'emptyJobListingMsgEmployerView',
    value: 'Currently no jobs listed with this employer.',
  },
  {
    key: 'emptyNotesMsgEmployerView',
    value: 'Currently no notes made for this employer.',
  },
  {
    key: 'nearest',
    value: 'Nearest',
  },
  {
    key: 'aligned',
    value: 'Aligned',
  },
  {
    key: 'cumulative',
    value: 'Cumulative',
  },
  {
    key: 'nearestIndividualBtn',
    value: 'Nearest Individuals',
  },
  {
    key: 'alignedIndividualBtn',
    value: 'Most Aligned Individuals',
  },
  {
    key: 'alignedEmployersBtn',
    value: 'Most Aligned Employers',
  },
  {
    key: 'cumBtn',
    value: 'Cumulative Matches',
  },
  {
    key: 'jobDetailTitle',
    value: 'JOB DETAILS',
  },
  {
    key: 'orgNameLabel',
    value: 'Organization Name*',
  },
  {
    key: 'jobStatusLabel',
    value: 'Job Status',
  },
  {
    key: 'jobClosed',
    value: 'Closed',
  },
  {
    key: 'jobOpened',
    value: 'Open',
  },
  {
    key: 'jobTypeLabel',
    value: 'Job Type',
  },
  {
    key: 'irlText',
    value: 'In-person',
  },
  {
    key: 'remoteText',
    value: 'Remote',
  },
  {
    key: 'hybridText',
    value: 'Hybrid',
  },
  {
    key: 'addresPlaceholder',
    value: 'Address',
  },
  {
    key: 'addressLabel',
    value: 'Address *',
  },
  {
    key: 'esProviderLabel',
    value: 'Employment Support Provider (auto-populated)',
  },
  {
    key: 'esProviderplaceholder',
    value:
      'The lead employment support provider for this employer is auto populated for this job. You can change this after creating a new employer.',
  },
  {
    key: 'jobTitleLabel',
    value: 'Job title *',
  },
  {
    key: 'jobTitlePlaceholder',
    value: 'Cashier - Day/Night shift, Quinpool Road',
  },
  {
    key: 'descriptionLabel',
    value: 'Description',
  },
  {
    key: 'jobDescriptionText',
    value: 'Job Description',
  },
  {
    key: 'keywordsLabel',
    value: 'Keywords',
  },
  {
    key: 'skillsInterestsLabel',
    value: 'Interest and skill keywords',
  },
  {
    key: 'skillsInterestPlaceholder',
    value:
      'The more keywords used the more matches you generate between employers and individuals. Keywords are a combination of skills and interests.',
  },
  {
    key: 'skills&interestNotesLabel',
    value: 'Skills & interest (additional notes)',
  },
  {
    key: 'skills&interestplaceholder',
    value: 'Briefly descibe what you look for in this job.',
  },
  {
    key: 'skills&interestHelperText',
    value:
      'Didn’t find the keywords you were looking for? Add additional information to help you in skill matching.',
  },
  {
    key: 'hourlyRate',
    value: 'Hourly rate',
  },
  {
    key: 'minHrRateLabel',
    value: 'Minimum hourly rate',
  },
  {
    key: 'maxHrRateLabel',
    value: 'Maximum hourly rate',
  },
  {
    key: 'wklyHrsLabel',
    value: 'Weekly hours',
  },
  {
    key: 'iconText',
    value: 'icon',
  },
  {
    key: 'reqDaysLabel',
    value: 'Required Days',
  },
  {
    key: 'reqDaysPlaceholder',
    value: 'Select the applicable days for this job.',
  },
  {
    key: 'Monday',
    value: 'Monday',
  },
  {
    key: 'Tuesday',
    value: 'Tuesday',
  },
  {
    key: 'Wednesday',
    value: 'Wednesday',
  },
  {
    key: 'Thursday',
    value: 'Thursday',
  },
  {
    key: 'Friday',
    value: 'Friday',
  },
  {
    key: 'Saturday',
    value: 'Saturday',
  },
  {
    key: 'Sunday',
    value: 'Sunday',
  },
  {
    key: 'Mon',
    value: 'Mon',
  },
  {
    key: 'Tues',
    value: 'Tues',
  },
  {
    key: 'Wed',
    value: 'Wed',
  },
  {
    key: 'Thur',
    value: 'Thur',
  },
  {
    key: 'Fri',
    value: 'Fri',
  },
  {
    key: 'Sat',
    value: 'Sat',
  },
  {
    key: 'Sun',
    value: 'Sun',
  },
  {
    key: 'MonToSun',
    value: 'Mon-Sun',
  },
  {
    key: 'MonToFri',
    value: 'Mon-Fri',
  },
  {
    key: 'idealStartDate',
    value: 'Ideal Start Date',
  },
  {
    key: 'closingDateJob',
    value: 'Closing Date',
  },
  {
    key: 'closingDateCheckBoxLabel',
    value: 'Enable closing date for this job',
  },
  {
    key: 'trainingReqLabel',
    value: 'Training Required',
  },
  {
    key: 'certsReqLabel',
    value: 'Certification Required',
  },
  {
    key: 'trainingAvailLabel',
    value: 'Training Available',
  },
  {
    key: 'openToCoachingLabel',
    value: 'Open to job coach support during training',
  },
  {
    key: 'openToCoachSupportColumn',
    value: 'Open to Job Coach Support',
  },
  {
    key: 'howToApply',
    value: 'How to apply',
  },
  {
    key: 'notesPlaceholder',
    value:
      'Please include details on how the employer would like to receive applications for this job (e.g. through the website, via the employment support provider, onsite, etc.).',
  },
  {
    key: '',
    value: 'icon',
  },
  {
    key: 'attachmentsTitle',
    value: 'Attachments',
  },
  {
    key: 'jobDescriptionText',
    value: 'Job Description',
  },
  {
    key: 'uploadJobDescriptionBtn',
    value: 'Upload Job Description',
  },
  {
    key: 'additionalFilesTitle',
    value: 'Additional File(s)',
  },
  {
    key: 'additionalFilesText',
    value: 'Additional File',
  },
  {
    key: 'jobFormSubmitBtn',
    value: 'Save Job',
  },
  {
    key: 'providerCardTitle',
    value: 'EMPLOYMENT SUPPORT LEAD',
  },
  {
    key: 'overviewTitleJobView',
    value: 'JOB OVERVIEW',
  },
  {
    key: 'empty',
    value: 'Empty',
  },
  {
    key: 'backBtnJobView',
    value: 'Back to Job List',
  },
  {
    key: 'backBtnEmployerView',
    value: 'Back to Employer List',
  },
  {
    key: 'individualFormTitle',
    value: 'Individual Details',
  },
  {
    key: 'leadProviderLabel',
    value: 'Employment Support Lead',
  },
  {
    key: 'empStatus',
    value: 'Employer Status',
  },
  {
    key: 'individualFirstNameLabel',
    value: 'First Name',
  },
  {
    key: 'individualLastNameLabel',
    value: 'Last Name',
  },
  {
    key: 'phoneNumber',
    value: 'Phone Number',
  },
  {
    key: 'chooseYourProNoun',
    value: 'Preferred pronouns',
  },
  {
    key: 'agencyIdLabel',
    value: 'Agency ID',
  },
  {
    key: 'govIdLabel',
    value: 'Government ID',
  },
  {
    key: 'prefCommsLabel',
    value: 'Preferred Communication',
  },
  {
    key: 'N/A',
    value: 'N/A',
  },
  {
    key: 'accomodationsLabel',
    value: 'Accommodations',
  },
  {
    key: 'acommodationsPlaceholder',
    value:
      'E.g. Specialized equipment, modifications to the work environment or adjustments to work schedules or responsibilities.',
  },
  {
    key: 'selectAddressPlaceholder',
    value: 'Please select an address from the drop down in order to correctly register an address.',
  },
  {
    key: 'dobLabel',
    value: 'Birth date',
  },
  {
    key: 'dobPlaceholder',
    value: 'mm/dd/yyyy',
  },
  {
    key: 'dobHelperText',
    value: 'Enter a date of birth, individuals need to be 14 years or older.',
  },
  {
    key: 'preferredRolesHelperText',
    value: 'What positions is this individual looking for?',
  },
  {
    key: 'skillsTitle',
    value: 'Skills',
  },
  {
    key: 'interestsTitle',
    value: 'Interests',
  },
  {
    key: 'restrictionsTitle',
    value: 'Restrictions',
  },
  {
    key: 'restrictionsTextPlaceholder',
    value: 'E.g. Heavy lifting, no night shifts, pet allergy',
  },
  {
    key: 'restrictionsHelperText',
    value: 'A brief synopsis of any possible restricions (Max 150 characters)',
  },
  {
    key: 'indStatusLabel',
    value: 'This individual is ',
  },
  {
    key: 'individual',
    value: ' Individual',
  },
  {
    key: 'indStatusOpen',
    value: 'Open to work',
  },
  {
    key: 'indStatusNotOpen',
    value: 'Not Open to work',
  },
  {
    key: 'daysAvailTitle',
    value: 'Availability Days',
  },
  {
    key: 'daysAvailHelperText',
    value: 'Select the applicable days, even if the individual is not open to work right now.',
  },
  {
    key: 'totalHrsLabel',
    value: 'Total hours',
  },
  {
    key: 'hrsprWk',
    value: 'Hours per Week',
  },
  {
    key: 'totalHrsPlaceholder',
    value: 'Input the amount of hours the individual is interested in working per week.',
  },
  {
    key: 'HrsPlaceholder',
    value: 'E.g. 16',
  },
  {
    key: 'currentEmploymentTitle',
    value: 'Employment Schedule & History',
  },
  {
    key: 'addJobBtn',
    value: 'Add a Job',
  },
  {
    key: 'attachmentsTitle',
    value: 'Attachments',
  },
  {
    key: 'resumeUploadTitle',
    value: 'Resume',
  },
  {
    key: 'resumeUploadLabel',
    value: 'Upload Resume',
  },
  {
    key: 'coverLetterUploadTitle',
    value: 'Cover Letter',
  },
  {
    key: 'coverLetterUploadLabel',
    value: 'Upload Cover Letter',
  },
  {
    key: 'refTitle',
    value: 'References',
  },
  {
    key: 'refHelperText',
    value: 'Select up to 3',
  },
  {
    key: 'refLabel',
    value: 'Upload Reference(s)',
  },
  {
    key: 'refButtonLabel',
    value: 'reference(s) attached',
  },
  {
    key: 'certButtonLabel',
    value: 'certification(s) attached',
  },
  {
    key: 'certificationTitle',
    value: 'Certification',
  },
  {
    key: 'certsHelperText',
    value: 'Select up to five',
  },
  {
    key: 'certsLabel',
    value: 'Upload Certification(s)',
  },
  {
    key: 'individualSubmitBtn',
    value: 'Save Individual',
  },
  {
    key: 'empInfoMainTitle',
    value: 'INDIVIDUAL OVERVIEW',
  },
  {
    key: 'empScheduleTitle',
    value: 'CURRENT EMPLOYMENT SCHEDULE',
  },
  {
    key: 'emptyNotesMsgIndividual',
    value: 'Currently no notes made for this individual.',
  },
  {
    key: 'nearestJobBtn',
    value: 'Nearest Jobs',
  },
  {
    key: 'nearestEmployersBtn',
    value: 'Nearest Employers',
  },
  {
    key: 'alignedJobsBtn',
    value: 'Most Aligned Jobs',
  },
  {
    key: 'recommendataionsCumBtn',
    value: 'Cumulative Matches',
  },
  {
    key: 'individualViewBackBtn',
    value: 'Back to Individuals List',
  },
  {
    key: 'individualViewEditBtn',
    value: 'Edit Individual',
  },
  {
    key: 'jobViewEditBtn',
    value: 'Edit Job',
  },
  {
    key: 'lessMinute',
    value: `less than  a minute ago`,
  },
  {
    key: 'ago',
    value: 'ago',
  },
  {
    key: 'daysAgo',
    value: 'days ago',
  },
  {
    key: 'oneDayPast',
    value: 'a day ago',
  },
  {
    key: 'on',
    value: 'on',
  },
  {
    key: 'inactive',
    value: 'inactive',
  },
  {
    key: 'active',
    value: 'active',
  },
  {
    key: 'addedBy',
    value: 'added by',
  },
  {
    key: 'added',
    value: 'Added',
  },
  {
    key: 'yes',
    value: 'yes',
  },
  {
    key: 'no',
    value: 'no',
  },
  {
    key: 'na',
    value: 'N/A',
  },
  {
    key: 'dayCES',
    value: 'Day(s)',
  },
  {
    key: 'hours',
    value: 'Hours',
  },
  {
    key: 'role',
    value: 'Position',
  },
  {
    key: 'jobSource',
    value: 'Job Source',
  },
  {
    key: 'references',
    value: 'Reference(s)',
  },
  {
    key: 'employmentHistory',
    value: 'Employment History',
  },
  {
    key: 'noNotifications',
    value: 'You have no new notifications',
  },
  {
    key: 'hasNewJob',
    value: 'has a new job match',
  },
  {
    key: 'rowPerPage',
    value: 'rows per page',
  },
  {
    key: 'density',
    value: 'Density',
  },
  {
    key: 'columns',
    value: 'Columns',
  },
  {
    key: 'filters',
    value: 'Filters',
  },
  {
    key: 'export',
    value: 'Export',
  },
  {
    key: 'noRows',
    value: 'No rows',
  },
  {
    key: 'noResults',
    value: 'No results found.',
  },
  {
    key: 'search...',
    value: 'Search...',
  },
  {
    key: 'columnTitle',
    value: 'Column title',
  },
  {
    key: 'findColumn',
    value: 'Find column',
  },
  {
    key: 'hideAll',
    value: 'Hide all',
  },
  {
    key: 'showAll',
    value: 'Show all',
  },
  {
    key: 'operator',
    value: 'operator',
  },
  {
    key: 'value',
    value: 'value',
  },
  {
    key: 'and',
    value: 'and',
  },
  {
    key: 'or',
    value: 'or',
  },
  {
    key: 'contains',
    value: 'contains',
  },
  {
    key: 'equals',
    value: 'equals',
  },
  {
    key: 'startsWith',
    value: 'starts with',
  },
  {
    key: 'endsWith',
    value: 'ends with',
  },
  {
    key: 'isEmpty',
    value: 'is empty',
  },
  {
    key: 'isNotEmpty',
    value: 'is not empty',
  },
  {
    key: 'isAnyOf',
    value: 'is any of',
  },
  {
    key: 'filterValue',
    value: `Filter Value`,
  },
  {
    key: 'compact',
    value: 'compact',
  },
  {
    key: 'standard',
    value: `standard`,
  },
  {
    key: 'comfortable',
    value: `comfortable`,
  },
  {
    key: 'dlCSV',
    value: 'Download CSV',
  },
  {
    key: 'print',
    value: 'Print',
  },
  {
    key: 'tMenu',
    value: 'Menu',
  },
  {
    key: 'tShowCol',
    value: 'Show columns',
  },
  {
    key: 'tMngCol',
    value: 'Manage columns',
  },
  {
    key: 'tFilter',
    value: 'Filter',
  },
  {
    key: 'tHideCol',
    value: 'Hide column',
  },
  {
    key: 'unsort',
    value: 'Unsort',
  },
  {
    key: 'sortUp',
    value: 'Sort by ASC',
  },
  {
    key: 'sortDown',
    value: 'Sort by DESC',
  },
  {
    key: 'selectLeadProv',
    value: 'Select a lead provider',
  },
  {
    key: 'enterOrgName',
    value: 'Enter the employer’s organization name.',
  },

  {
    key: 'duplicateOrgFound',
    value: 'A duplicate organization name was found, please change to proceed.',
  },
  {
    key: 'duplicateOrgFoundMsg',
    value: 'Organization duplicate found',
  },
  {
    key: 'duplicateParentOrgFoundMsg',
    value: 'Parent Organization duplicate found',
  },
  {
    key: 'selectIndustryText',
    value: 'Select your industry',
  },
  {
    key: 'selectIndutryTextII',
    value: 'Select an industry',
  },
  {
    key: 'mainBranchNameReqMsg',
    value: 'A main branch name is required',
  },
  {
    key: 'leadProviderReqMsg',
    value: 'A lead provider must be selected',
  },
  {
    key: 'descErrorMsg',
    value: 'Please, enter a description with less than 3000 characters',
  },
  {
    key: 'addressReqMsg',
    value: 'You must select an address from one of the options',
  },
  {
    key: 'addressReqMsgII',
    value:
      'In order to save this profile and to match by locations, an address must be selected from the drop down.',
  },
  {
    key: 'validPhoneReq',
    value: 'Please input a valid 10-digit number',
  },
  {
    key: 'phoneErrorMsg',
    value: 'Number is too long!',
  },
  {
    key: 'phoneHelperText',
    value: 'Please input a valid 10-digit number with an optional 5-digit extension',
  },
  {
    key: 'emailErrorMsg',
    value: 'Please enter a valid email address',
  },
  {
    key: 'notesErrorMsg',
    value: 'Sorry, your notes should be less than 3000 characters',
  },
  {
    key: 'created',
    value: 'Created',
  },
  {
    key: 'creator',
    value: 'Creator',
  },
  {
    key: 'goBack',
    value: 'Go Back',
  },

  {
    key: 'noDetailsAvailable',
    value: 'No details available for this job...',
  },
  {
    key: 'noNotesOnEmployer',
    value: 'You currently have no notes on this employer…',
  },
  {
    key: 'empOrgReqMsg',
    value: 'You must select an employer organization',
  },
  {
    key: 'addressErrorMsg',
    value: 'Select the full address from the drop down.',
  },
  {
    key: 'jobDuplicateMsg',
    value: 'Job duplicate found',
  },
  {
    key: 'orgSelectPrompt',
    value:
      'Please select an organization name before creating a job title. Note: Changing the organization name will reset this field.',
  },
  {
    key: 'jobTitleErrorMsg',
    value: 'Please add a job title.',
  },
  {
    key: 'minRateErrorMsg',
    value: 'Value must be less than the max. hourly rate',
  },
  {
    key: 'maxRateErrorMsg',
    value: 'Value must be greater than the min. hourly rate',
  },
  {
    key: 'estHrsPerWeek',
    value: 'Estimated weekly hours',
  },
  {
    key: 'jobClosingDateHelperText',
    value: 'Please select a date that is at least 24 hours in the future.',
  },
  {
    key: 'selectProvPlease',
    value: 'Please select a provider',
  },
  {
    key: 'selectProvider',
    value: 'Select a provider',
  },
  {
    key: 'firstNameReqMsg',
    value: 'A first name must be provided',
  },
  {
    key: 'keepNumberUpdToDate',
    value: 'Keep your work number up to date',
  },
  {
    key: 'indExistsMsg',
    value: 'Individual exists',
  },
  {
    key: 'phoneNumberReqMsg',
    value: 'A phone number must be provided',
  },
  {
    key: 'validAddressReqMsg',
    value: 'Please provide a valid address.',
  },
  {
    key: 'dobRequired',
    value: 'Date of birth required.',
  },

  {
    key: 'birthDate',
    value: 'Birthdate',
  },
  {
    key: 'dobErrorMsg',
    value: 'Must be 14 years or older to legally work in Ontario.',
  },
  {
    key: 'expectedSalaryErrorMsg',
    value: 'I’m in the wrong field! Real wages please.',
  },
  {
    key: 'numberErrorMsg',
    value: 'Invalid input, please remove the decimal point or add a value to the tenths place.',
  },
  {
    key: 'restrictionsHelperText',
    value: 'We recommend keeping this short, try shortening it to 150 characters or less.',
  },
  {
    key: 'availableHoursErrorMsg',
    value: 'Please provide hours less than 60 hours.',
  },
  {
    key: 'availableHrsHelperText',
    value: 'Roughly how many total hours would they like to work per week?',
  },
  {
    key: 'availableHoursErrorMsg',
    value: 'Input hours between 0 and 99',
  },
  {
    key: 'thousandMaxChars',
    value: 'Max. characters 3000',
  },
  {
    key: 'selectDayLabel',
    value: 'Please select a day.',
  },
  {
    key: 'selectDayReqMsg',
    value: 'You must select a day',
  },
  {
    key: 'Rotating',
    value: 'Rotating',
  },
  {
    key: 'hoursReqErrorMsg',
    value: 'Please let us know how many hours you work(ed) per week.',
  },
  {
    key: 'employerNamePleaseMsg',
    value: 'Please provide an employer name.',
  },
  {
    key: 'roleReqMsg',
    value: 'Please provide a position.',
  },
  {
    key: 'reqRoleLabel',
    value: 'E.g. Baker',
  },
  {
    key: 'individualPlacedByMJM',
    value: 'Did MyJobMatch help the individual get this job?',
  },
  {
    key: 'individualPlacedByMJMII',
    value: 'Select if the individual got the job through MyJobMatch.',
  },
  {
    key: 'noLongWorksHere',
    value: 'Individual no longer works here.',
  },
  {
    key: 'roleActiveMsg',
    value: 'This role is currently active.',
  },
  {
    key: 'lastNameReqMsg',
    value: 'A last name must be provided',
  },
  {
    key: 'validNameReqMsg',
    value: `Please input a valid name. Only alphabets, numbers, spaces, and dashes'-' allowed.`,
  },
  {
    key: 'proifileUpdated',
    value: 'Profile Updated!',
  },
  {
    key: 'iUnderstand',
    value: 'Okay, I understand',
  },
  {
    key: 'closeAllJobsMsgPrompt',
    value: 'Please close all jobs for this employer before setting inactive.',
  },
  {
    key: 'permanentlyDelete',
    value: 'Permanently Delete Employer?',
  },
  {
    key: 'deleteThisMsg',
    value: 'Yes, Delete this',
  },
  {
    key: 'doNotDeleteThisMsg',
    value: 'No, I changed my mind',
  },
  {
    key: 'deleteSuccess',
    value: 'Delete successful',
  },
  {
    key: 'deletePermanently',
    value: 'Permanently Delete',
  },
  {
    key: 'permanentlyDeleteJobWarningMsg',
    value:
      '<p>Click “YES, DELETE THIS JOB" to proceed with deletion. <b>This will delete the job from all matching opportunities (individual and employer)</b>.</p>',
  },
  {
    key: 'deleteAllJobWarningMsg',
    value:
      '<p>Click “YES, DELETE THIS EMPLOYER" to proceed. <b>This will delete the employer and all jobs associated with it</b>.</p>',
  },
  {
    key: 'deleteIndividualWarningMsg',
    value:
      '<p>Click “YES, DELETE THIS INDIVIDUAL” to proceed with deletion. <b>This will delete the individual from all matching opportunities (job and employer)</b>.</p>',
  },
  {
    key: 'delete',
    value: 'Delete',
  },
  {
    key: 'emptyInstructions',
    value: `No instructions provided.`,
  },
  {
    key: 'assignLeadToJobMsg',
    value: `Assign new lead provider to all jobs associated with this employer? If yes, after updating the employer please reload the page to see changes made.`,
  },
  //KEYWORDS
  {
    key: 'Account Administration',
    value: 'Account Administration',
  },
  {
    key: 'Account Management',
    value: 'Account Management',
  },
  {
    key: 'Accounting',
    value: 'Accounting',
  },
  {
    key: 'Accounts Payable',
    value: 'Accounts Payable',
  },
  {
    key: 'Administration',
    value: 'Administration',
  },
  {
    key: 'Administrative Support',
    value: 'Administrative Support',
  },
  {
    key: 'Adobe',
    value: 'Adobe',
  },
  {
    key: 'Agile',
    value: 'Agile',
  },
  {
    key: 'Answer Phone',
    value: 'Answer Phone',
  },
  {
    key: 'Application Coding',
    value: 'Application Coding',
  },
  {
    key: 'Assembly Work',
    value: 'Assembly Work',
  },
  {
    key: 'Auditing',
    value: 'Auditing',
  },
  {
    key: 'Back-End Programming Languages',
    value: 'Back-End Programming Languages',
  },
  {
    key: 'Banking',
    value: 'Banking',
  },
  {
    key: 'Beta Testing',
    value: 'Beta Testing',
  },
  {
    key: 'Blended Learning',
    value: 'Blended Learning',
  },
  {
    key: 'Blog Writing',
    value: 'Blog Writing',
  },
  {
    key: 'Booking Travel',
    value: 'Booking Travel',
  },
  {
    key: 'Bookkeeping',
    value: 'Bookkeeping',
  },
  {
    key: 'Brand Management',
    value: 'Brand Management',
  },
  {
    key: 'Budget Forecasting',
    value: 'Budget Forecasting',
  },
  {
    key: 'Budget Management',
    value: 'Budget Management',
  },
  {
    key: 'Budgeting',
    value: 'Budgeting',
  },
  {
    key: 'Buggies',
    value: 'Buggies',
  },
  {
    key: 'Buggy Retrieval',
    value: 'Buggy Retrieval',
  },
  {
    key: 'Business Analysis',
    value: 'Business Analysis',
  },
  {
    key: 'Business Development',
    value: 'Business Development',
  },
  {
    key: 'Bussing',
    value: 'Bussing',
  },
  {
    key: 'C#',
    value: 'C#',
  },
  {
    key: 'C++',
    value: 'C++',
  },
  {
    key: 'Cafe Attendant',
    value: 'Cafe Attendant',
  },
  {
    key: 'Car Detailing',
    value: 'Car Detailing',
  },
  {
    key: 'Career Analysis',
    value: 'Career Analysis',
  },
  {
    key: 'Cash Flow Analysis',
    value: 'Cash Flow Analysis',
  },
  {
    key: 'Cashier',
    value: 'Cashier',
  },
  {
    key: 'Change Management',
    value: 'Change Management',
  },
  {
    key: 'Cleaner',
    value: 'Cleaner',
  },
  {
    key: 'Cleaning',
    value: 'Cleaning',
  },
  {
    key: 'Cleaning Baskets',
    value: 'Cleaning Baskets',
  },
  {
    key: 'Clerical',
    value: 'Clerical',
  },
  {
    key: 'Coding',
    value: 'Coding',
  },
  {
    key: 'Cold Calling',
    value: 'Cold Calling',
  },
  {
    key: 'Communications / Public Relations',
    value: 'Communications / Public Relations',
  },
  {
    key: 'Community Engagement',
    value: 'Community Engagement',
  },
  {
    key: 'Computer',
    value: 'Computer',
  },
  {
    key: 'Computer Science',
    value: 'Computer Science',
  },
  {
    key: 'Computer Skills',
    value: 'Computer Skills',
  },
  {
    key: 'Computer Repair',
    value: 'Computer Repair',
  },
  {
    key: 'Concierge',
    value: 'Concierge',
  },
  {
    key: 'Concessions',
    value: 'Concessions',
  },
  {
    key: 'Construction',
    value: 'Construction',
  },
  {
    key: 'Content Creation',
    value: 'Content Creation',
  },
  {
    key: 'Content Marketing',
    value: 'Content Marketing',
  },
  {
    key: 'Cooking (skill)',
    value: 'Cooking (skill)',
  },
  {
    key: 'Copywriting',
    value: 'Copywriting',
  },
  {
    key: 'Cost Accounting',
    value: 'Cost Accounting',
  },
  {
    key: 'Counselling',
    value: 'Counselling',
  },
  {
    key: 'CRM',
    value: 'CRM',
  },
  {
    key: 'Crossing Guard',
    value: 'Crossing Guard',
  },
  {
    key: 'CSS',
    value: 'CSS',
  },
  {
    key: 'Culinary',
    value: 'Culinary',
  },
  {
    key: 'Customer Relations',
    value: 'Customer Relations',
  },
  {
    key: 'Customer Service',
    value: 'Customer Service',
  },
  {
    key: 'Customer Support',
    value: 'Customer Support',
  },
  {
    key: 'Data Analytics',
    value: 'Data Analytics',
  },
  {
    key: 'Data Collection',
    value: 'Data Collection',
  },
  {
    key: 'Data Entry',
    value: 'Data Entry',
  },
  {
    key: 'Data Modeling',
    value: 'Data Modeling',
  },
  {
    key: 'Database Administration',
    value: 'Database Administration',
  },
  {
    key: 'Database Management',
    value: 'Database Management',
  },
  {
    key: 'Debugging',
    value: 'Debugging',
  },
  {
    key: 'Dental Hygiene',
    value: 'Dental Hygiene',
  },
  {
    key: 'Detailing',
    value: 'Detailing',
  },
  {
    key: 'Digital Marketing',
    value: 'Digital Marketing',
  },
  {
    key: 'Dishwashing',
    value: 'Dishwashing',
  },
  {
    key: 'Dispatch',
    value: 'Dispatch',
  },
  {
    key: 'Document Management',
    value: 'Document Management',
  },
  {
    key: 'Documentation',
    value: 'Documentation',
  },
  {
    key: 'Dynamic Web Page Design',
    value: 'Dynamic Web Page Design',
  },
  {
    key: 'Editing & Proofreading',
    value: 'Editing & Proofreading',
  },
  {
    key: 'Electrical Engineering',
    value: 'Electrical Engineering',
  },
  {
    key: 'Email',
    value: 'Email',
  },
  {
    key: 'Email Marketing',
    value: 'Email Marketing',
  },
  {
    key: 'Emergency Support',
    value: 'Emergency Support',
  },
  {
    key: 'Engineering/Computer Engineering',
    value: 'Engineering/Computer Engineering',
  },
  {
    key: 'Estate Planning',
    value: 'Estate Planning',
  },
  {
    key: 'Event Management',
    value: 'Event Management',
  },
  {
    key: 'Excel',
    value: 'Excel',
  },
  {
    key: 'Executive Leadership',
    value: 'Executive Leadership',
  },
  {
    key: 'Executive Support',
    value: 'Executive Support',
  },
  {
    key: 'Expense Reporting',
    value: 'Expense Reporting',
  },
  {
    key: 'Facilities Management',
    value: 'Facilities Management',
  },
  {
    key: 'File',
    value: 'File',
  },
  {
    key: 'Filing',
    value: 'Filing',
  },
  {
    key: 'Financial Accounting',
    value: 'Financial Accounting',
  },
  {
    key: 'Financial Analysis',
    value: 'Financial Analysis',
  },
  {
    key: 'Financial Compliance',
    value: 'Financial Compliance',
  },
  {
    key: 'Financial Management',
    value: 'Financial Management',
  },
  {
    key: 'Financial Planning',
    value: 'Financial Planning',
  },
  {
    key: 'Financial Reporting',
    value: 'Financial Reporting',
  },
  {
    key: 'Fitness Training',
    value: 'Fitness Training',
  },
  {
    key: 'Folding',
    value: 'Folding',
  },
  {
    key: 'Food Safety',
    value: 'Food Safety',
  },
  {
    key: 'Food Preparation',
    value: 'Food Preparation',
  },
  {
    key: 'Food Processing',
    value: 'Food Processing',
  },
  {
    key: 'Forecasting',
    value: 'Forecasting',
  },
  {
    key: 'Forensic Accounting',
    value: 'Forensic Accounting',
  },
  {
    key: 'Framework Templates',
    value: 'Framework Templates',
  },
  {
    key: 'Front-End Web Technologies',
    value: 'Front-End Web Technologies',
  },
  {
    key: 'Fundraising',
    value: 'Fundraising',
  },
  {
    key: 'Games Creation',
    value: 'Games Creation',
  },
  {
    key: 'Garbage Removal',
    value: 'Garbage Removal',
  },
  {
    key: 'Google Ads',
    value: 'Google Ads',
  },
  {
    key: 'Google Analytics',
    value: 'Google Analytics',
  },
  {
    key: 'Google Design Sprint Method',
    value: 'Google Design Sprint Method',
  },
  {
    key: 'Graphic Design',
    value: 'Graphic Design',
  },
  {
    key: 'Greeter',
    value: 'Greeter',
  },
  {
    key: 'Handout Promotional Items',
    value: 'Handout Promotional Items',
  },
  {
    key: 'Heavy Equipment Operation',
    value: 'Heavy Equipment Operation',
  },
  {
    key: 'Housekeeping',
    value: 'Housekeeping',
  },
  {
    key: 'HTML',
    value: 'HTML',
  },
  {
    key: 'HTML/CSS',
    value: 'HTML/CSS',
  },
  {
    key: 'Human Resources',
    value: 'Human Resources',
  },
  {
    key: 'IBM Cognos Impromptu',
    value: 'IBM Cognos Impromptu',
  },
  {
    key: 'Illustrator',
    value: 'Illustrator',
  },
  {
    key: 'Immigration',
    value: 'Immigration',
  },
  {
    key: 'Impact Assessment',
    value: 'Impact Assessment',
  },
  {
    key: 'Inclusive Classroom',
    value: 'Inclusive Classroom',
  },
  {
    key: 'Income Tax Planning',
    value: 'Income Tax Planning',
  },
  {
    key: 'InDesign',
    value: 'InDesign',
  },
  {
    key: 'Industrial Mechanism',
    value: 'Industrial Mechanism',
  },
  {
    key: 'Instagram Management',
    value: 'Instagram Management',
  },
  {
    key: 'Insurance',
    value: 'Insurance',
  },
  {
    key: 'Interactive Classroom',
    value: 'Interactive Classroom',
  },
  {
    key: 'Intuit QuickBooks',
    value: 'Intuit QuickBooks',
  },
  {
    key: 'Inventory Management',
    value: 'Inventory Management',
  },
  {
    key: 'Investment Management',
    value: 'Investment Management',
  },
  {
    key: 'InVision',
    value: 'InVision',
  },
  {
    key: 'Invoicing',
    value: 'Invoicing',
  },
  {
    key: 'Child Care (skill)',
    value: 'Child Care (skill)',
  },
  {
    key: 'Driving (skill)',
    value: 'Driving (skill)',
  },
  {
    key: 'IT Support',
    value: 'IT Support',
  },
  {
    key: 'Janitorial Services',
    value: 'Janitorial Services',
  },
  {
    key: 'Java',
    value: 'Java',
  },
  {
    key: 'JavaScript',
    value: 'JavaScript',
  },
  {
    key: 'Jira',
    value: 'Jira',
  },
  {
    key: 'Journalism',
    value: 'Journalism',
  },
  {
    key: 'Justinmind',
    value: 'Justinmind',
  },
  {
    key: 'Kitchen Assistant',
    value: 'Kitchen Assistant',
  },
  {
    key: 'Kitchen Help',
    value: 'Kitchen Help',
  },
  {
    key: 'Labour',
    value: 'Labour',
  },
  {
    key: 'Labour Relations',
    value: 'Labour Relations',
  },
  {
    key: 'Language Interpretation',
    value: 'Language Interpretation',
  },
  {
    key: 'Language Skills (Spoken and/or Written)',
    value: 'Language Skills (Spoken and/or Written)',
  },
  {
    key: 'Lead Generation',
    value: 'Lead Generation',
  },
  {
    key: 'Legal',
    value: 'Legal',
  },
  {
    key: 'Legal Support',
    value: 'Legal Support',
  },
  {
    key: 'Lifting',
    value: 'Lifting',
  },
  {
    key: 'Light Duty Cleaning',
    value: 'Light Duty Cleaning',
  },
  {
    key: 'LinkedIn Management',
    value: 'LinkedIn Management',
  },
  {
    key: 'Machine Operation',
    value: 'Machine Operation',
  },
  {
    key: 'Maintenance',
    value: 'Maintenance',
  },
  {
    key: 'Landscaping',
    value: 'Landscaping',
  },
  {
    key: 'Management Accounting',
    value: 'Management Accounting',
  },
  {
    key: 'MAP Testing',
    value: 'MAP Testing',
  },
  {
    key: 'Market Analysis',
    value: 'Market Analysis',
  },
  {
    key: 'Market Research',
    value: 'Market Research',
  },
  {
    key: 'Marketing',
    value: 'Marketing',
  },
  {
    key: 'Marketing Campaigns',
    value: 'Marketing Campaigns',
  },
  {
    key: 'Massage Therapy',
    value: 'Massage Therapy',
  },
  {
    key: 'Mechanical Engineering',
    value: 'Mechanical Engineering',
  },
  {
    key: 'Media Communication',
    value: 'Media Communication',
  },
  {
    key: 'Medical Administration',
    value: 'Medical Administration',
  },
  {
    key: 'Meeting Coordination',
    value: 'Meeting Coordination',
  },
  {
    key: 'Meeting Minutes Recording',
    value: 'Meeting Minutes Recording',
  },
  {
    key: 'Merchandising',
    value: 'Merchandising',
  },
  {
    key: 'Machine Learning/AI',
    value: 'Machine Learning/AI',
  },
  {
    key: 'Microsoft Excel',
    value: 'Microsoft Excel',
  },
  {
    key: 'Microsoft Office Suite',
    value: 'Microsoft Office Suite',
  },
  {
    key: 'Mopping',
    value: 'Mopping',
  },
  {
    key: 'NetSuite',
    value: 'NetSuite',
  },
  {
    key: 'Networking',
    value: 'Networking',
  },
  {
    key: 'Node',
    value: 'Node',
  },
  {
    key: 'Nurse aide',
    value: 'Nurse aide',
  },
  {
    key: 'Nursing',
    value: 'Nursing',
  },
  {
    key: 'Object-Oriented Design',
    value: 'Object-Oriented Design',
  },
  {
    key: 'Occupational Health and Safety',
    value: 'Occupational Health and Safety',
  },
  {
    key: 'Office Supply Management',
    value: 'Office Supply Management',
  },
  {
    key: 'Office Support',
    value: 'Office Support',
  },
  {
    key: 'Oil Changes',
    value: 'Oil Changes',
  },
  {
    key: 'Omnigraffle',
    value: 'Omnigraffle',
  },
  {
    key: 'Oracle Business Intelligence',
    value: 'Oracle Business Intelligence',
  },
  {
    key: 'Order Processing',
    value: 'Order Processing',
  },
  {
    key: 'Outlook',
    value: 'Outlook',
  },
  {
    key: 'Packing',
    value: 'Packing',
  },
  {
    key: 'Packaging',
    value: 'Packaging',
  },
  {
    key: 'Paralegal',
    value: 'Paralegal',
  },
  {
    key: 'Patient Services',
    value: 'Patient Services',
  },
  {
    key: 'Payroll',
    value: 'Payroll',
  },
  {
    key: 'Personalized Learning',
    value: 'Personalized Learning',
  },
  {
    key: 'Phone Skills',
    value: 'Phone Skills',
  },
  {
    key: 'Phone Support',
    value: 'Phone Support',
  },
  {
    key: 'Photo Editing',
    value: 'Photo Editing',
  },
  {
    key: 'Photocopy',
    value: 'Photocopy',
  },
  {
    key: 'Photoshop',
    value: 'Photoshop',
  },
  {
    key: 'PHP',
    value: 'PHP',
  },
  {
    key: 'Physical Labour',
    value: 'Physical Labour',
  },
  {
    key: 'Policy Development',
    value: 'Policy Development',
  },
  {
    key: 'Porter',
    value: 'Porter',
  },
  {
    key: 'Powerpoint',
    value: 'Powerpoint',
  },
  {
    key: 'Process Development',
    value: 'Process Development',
  },
  {
    key: 'Process Improvement',
    value: 'Process Improvement',
  },
  {
    key: 'Procurement',
    value: 'Procurement',
  },
  {
    key: 'Product Design',
    value: 'Product Design',
  },
  {
    key: 'Product Management',
    value: 'Product Management',
  },
  {
    key: 'Production',
    value: 'Production',
  },
  {
    key: 'Program Development',
    value: 'Program Development',
  },
  {
    key: 'Programing',
    value: 'Programing',
  },
  {
    key: 'Project Life Cycle',
    value: 'Project Life Cycle',
  },
  {
    key: 'Project Management',
    value: 'Project Management',
  },
  {
    key: 'Project Management Software',
    value: 'Project Management Software',
  },
  {
    key: 'Proofreading',
    value: 'Proofreading',
  },
  {
    key: 'Prospecting',
    value: 'Prospecting',
  },
  {
    key: 'Python',
    value: 'Python',
  },
  {
    key: 'Quality Assurance',
    value: 'Quality Assurance',
  },
  {
    key: 'Quality Control',
    value: 'Quality Control',
  },
  {
    key: 'Rails',
    value: 'Rails',
  },
  {
    key: 'React',
    value: 'React',
  },
  {
    key: 'Reading 3D',
    value: 'Reading 3D',
  },
  {
    key: 'Receiving',
    value: 'Receiving',
  },
  {
    key: 'Reconciliation',
    value: 'Reconciliation',
  },
  {
    key: 'Recruitment',
    value: 'Recruitment',
  },
  {
    key: 'Recycle Materials',
    value: 'Recycle Materials',
  },
  {
    key: 'Redux',
    value: 'Redux',
  },
  {
    key: 'Relationship Management',
    value: 'Relationship Management',
  },
  {
    key: 'Release Management',
    value: 'Release Management',
  },
  {
    key: 'Report Generation',
    value: 'Report Generation',
  },

  {
    key: 'Research',
    value: 'Research',
  },
  {
    key: 'Resolutions',
    value: 'Resolutions',
  },
  {
    key: 'Resource Allocation',
    value: 'Resource Allocation',
  },
  {
    key: 'Responsive Design',
    value: 'Responsive Design',
  },
  {
    key: 'Revenue Forecasting',
    value: 'Revenue Forecasting',
  },
  {
    key: 'Reviewing Contracts',
    value: 'Reviewing Contracts',
  },
  {
    key: 'Risk Management',
    value: 'Risk Management',
  },
  {
    key: 'Roadmapping',
    value: 'Roadmapping',
  },
  {
    key: 'Ruby',
    value: 'Ruby',
  },
  {
    key: 'Sales',
    value: 'Sales',
  },
  {
    key: 'Salesforce',
    value: 'Salesforce',
  },
  {
    key: 'Sanitizing',
    value: 'Sanitizing',
  },
  {
    key: 'SAS Financial Management',
    value: 'SAS Financial Management',
  },
  {
    key: 'SASS',
    value: 'SASS',
  },
  {
    key: 'Scan Documents',
    value: 'Scan Documents',
  },
  {
    key: 'Scheduling & Planning',
    value: 'Scheduling & Planning',
  },
  {
    key: 'Scope Management',
    value: 'Scope Management',
  },
  {
    key: 'Scrum Management',
    value: 'Scrum Management',
  },
  {
    key: 'Search Engine Marketing (SEM)',
    value: 'Search Engine Marketing (SEM)',
  },
  {
    key: 'Search Engine Optimization (SEO)',
    value: 'Search Engine Optimization (SEO)',
  },
  {
    key: 'Security Compliance',
    value: 'Security Compliance',
  },
  {
    key: 'Security Management',
    value: 'Security Management',
  },
  {
    key: 'Senior Care',
    value: 'Senior Care',
  },
  {
    key: 'Server',
    value: 'Server',
  },
  {
    key: 'Server-Side Scripting',
    value: 'Server-Side Scripting',
  },
  {
    key: 'Shift Work',
    value: 'Shift Work',
  },
  {
    key: 'Shipping',
    value: 'Shipping',
  },
  {
    key: 'Social Media',
    value: 'Social Media',
  },
  {
    key: 'Social Media Management',
    value: 'Social Media Management',
  },
  {
    key: 'Social Work',
    value: 'Social Work',
  },
  {
    key: 'Social-Emotional Learning',
    value: 'Social-Emotional Learning',
  },
  {
    key: 'Software Development',
    value: 'Software Development',
  },
  {
    key: 'Software Workflow',
    value: 'Software Workflow',
  },
  {
    key: 'SQL',
    value: 'SQL',
  },
  {
    key: 'Stakeholder Management',
    value: 'Stakeholder Management',
  },
  {
    key: 'Standing',
    value: 'Standing',
  },
  {
    key: 'Statistical Analysis',
    value: 'Statistical Analysis',
  },
  {
    key: 'Stocking',
    value: 'Stocking',
  },
  {
    key: 'Story Mapping',
    value: 'Story Mapping',
  },
  {
    key: 'Storytelling',
    value: 'Storytelling',
  },
  {
    key: 'Supervision',
    value: 'Supervision',
  },
  {
    key: 'Supply Chain',
    value: 'Supply Chain',
  },
  {
    key: 'Sweeping',
    value: 'Sweeping',
  },
  {
    key: 'Tableau',
    value: 'Tableau',
  },
  {
    key: 'Tape Management',
    value: 'Tape Management',
  },
  {
    key: 'Retail (skill)',
    value: 'Retail (skill)',
  },
  {
    key: 'Tax Preparation',
    value: 'Tax Preparation',
  },
  {
    key: 'Telecommunication',
    value: 'Telecommunication',
  },
  {
    key: 'Trades',
    value: 'Trades',
  },
  {
    key: 'Training',
    value: 'Training',
  },
  {
    key: 'Training Design /Facilitation',
    value: 'Training Design /Facilitation',
  },
  {
    key: 'Troubleshooting',
    value: 'Troubleshooting',
  },
  {
    key: 'Trucking',
    value: 'Trucking',
  },
  {
    key: 'TypeScript',
    value: 'TypeScript',
  },
  {
    key: 'Typography',
    value: 'Typography',
  },
  {
    key: 'User Testing',
    value: 'User Testing',
  },
  {
    key: 'Unloading',
    value: 'Unloading',
  },
  {
    key: 'User Research',
    value: 'User Research',
  },
  {
    key: 'UX/UI Design',
    value: 'UX/UI Design',
  },
  {
    key: 'Vendor Management',
    value: 'Vendor Management',
  },
  {
    key: 'Version Control',
    value: 'Version Control',
  },
  {
    key: 'Video Editing',
    value: 'Video Editing',
  },
  {
    key: 'Waterfall',
    value: 'Waterfall',
  },
  {
    key: 'Wealth Management',
    value: 'Wealth Management',
  },
  {
    key: 'Wipe Tables',
    value: 'Wipe Tables',
  },
  {
    key: 'Wiping',
    value: 'Wiping',
  },
  {
    key: 'Word',
    value: 'Word',
  },
  {
    key: 'Animals',
    value: 'Animals',
  },
  {
    key: 'Art',
    value: 'Art',
  },
  {
    key: 'Automotive',
    value: 'Automotive',
  },
  {
    key: 'Biking',
    value: 'Biking',
  },
  {
    key: 'Camp',
    value: 'Camp',
  },
  {
    key: 'Clothing',
    value: 'Clothing',
  },
  {
    key: 'Community Services',
    value: 'Community Services',
  },
  {
    key: 'Computers',
    value: 'Computers',
  },
  {
    key: 'Data Management',
    value: 'Data Management',
  },
  {
    key: 'Daycare',
    value: 'Daycare',
  },
  {
    key: 'Design',
    value: 'Design',
  },
  {
    key: 'Electronics',
    value: 'Electronics',
  },
  {
    key: 'Event Services',
    value: 'Event Services',
  },
  {
    key: 'Food Retailer',
    value: 'Food Retailer',
  },
  {
    key: 'Food Services',
    value: 'Food Services',
  },
  {
    key: 'Gardening',
    value: 'Gardening',
  },
  {
    key: 'Grocery Store',
    value: 'Grocery Store',
  },
  {
    key: 'Health Care',
    value: 'Health Care',
  },
  {
    key: 'Heavy Equipment',
    value: 'Heavy Equipment',
  },
  {
    key: 'Hospitality Services',
    value: 'Hospitality Services',
  },
  {
    key: 'Information Technology',
    value: 'Information Technology',
  },
  {
    key: 'Janitorial',
    value: 'Janitorial',
  },
  {
    key: 'Landscaping',
    value: 'Landscaping',
  },
  {
    key: 'Legal Services',
    value: 'Legal Services',
  },
  {
    key: 'Library',
    value: 'Library',
  },
  {
    key: 'Manufacturer',
    value: 'Manufacturer',
  },
  {
    key: 'Media',
    value: 'Media',
  },
  {
    key: 'Movies',
    value: 'Movies',
  },
  {
    key: 'Music',
    value: 'Music',
  },
  {
    key: 'Outdoors',
    value: 'Outdoors',
  },
  {
    key: 'Painting',
    value: 'Painting',
  },
  {
    key: 'Park',
    value: 'Park',
  },
  {
    key: 'Reading',
    value: 'Reading',
  },
  {
    key: 'Recycling',
    value: 'Recycling',
  },
  {
    key: 'Security',
    value: 'Security',
  },
  {
    key: 'Senior Facility',
    value: 'Senior Facility',
  },
  {
    key: 'Theatre',
    value: 'Theatre',
  },
  {
    key: 'Tobacconists',
    value: 'Tobacconists',
  },
  {
    key: 'Warehouse',
    value: 'Warehouse',
  },
  {
    key: 'Technology',
    value: 'Technology',
  },
  {
    key: 'Library Assistance',
    value: 'Library Assistance',
  },
  {
    key: 'Carting',
    value: 'Carting',
  },
  {
    key: 'Membership Management',
    value: 'Membership Management',
  },
  {
    key: 'Line Cook',
    value: 'Line Cook',
  },
  {
    key: 'Walking (skill)',
    value: 'Walking (skill)',
  },
  {
    key: 'Cooking (interest)',
    value: 'Cooking (interest)',
  },
  {
    key: 'Walking (interest)',
    value: 'Walking (interest)',
  },
  {
    key: 'Sports (skill)',
    value: 'Sports (skill)',
  },
  {
    key: 'Sports (interest)',
    value: 'Sports (interest)',
  },
  {
    key: 'Standup Comedy',
    value: 'Standup Comedy',
  },
  {
    key: 'Accounts Receivable',
    value: 'Accounts Receivable',
  },
  {
    key: 'Child Care (interest)',
    value: 'Child Care (interest)',
  },
  {
    key: 'Education (skill)',
    value: 'Education (skill)',
  },
  {
    key: 'Writing (skill)',
    value: 'Writing (skill)',
  },
  {
    key: 'Driving (interest)',
    value: 'Driving (interest)',
  },
  {
    key: 'Education (interest)',
    value: 'Education (interest)',
  },
  {
    key: 'Retail (interest)',
    value: 'Retail (interest)',
  },
  {
    key: 'Writing (interest)',
    value: 'Writing (interest)',
  },
  {
    key: 'Event Setup',
    value: 'Event Setup',
  },
  {
    key: 'Telemarketing',
    value: 'Telemarketing',
  },
  {
    key: 'Sorting',
    value: 'Sorting',
  },
  {
    key: 'Baking (interest)',
    value: 'Baking (interest)',
  },
  {
    key: 'Baking (skill)',
    value: 'Baking (skill)',
  },
  {
    key: 'Transportation',
    value: 'Transportation',
  },
  {
    key: 'Teamwork',
    value: 'Teamwork',
  },
  {
    key: 'Professional Driving (G License or Equivalent)',
    value: 'Professional Driving (G License or Equivalent)',
  },
  {
    key: 'Commercial Driving',
    value: 'Commercial Driving',
  },
  {
    key: 'Microsoft Access',
    value: 'Microsoft Access',
  },
  {
    key: 'Forklift Operation',
    value: 'Forklift Operation',
  },
  {
    key: 'Logistics Management',
    value: 'Logistics Management',
  },
  {
    key: 'Deliveries',
    value: 'Deliveries',
  },
  {
    key: 'Repetitive Movements',
    value: 'Repetitive Movements',
  },
  {
    key: 'Advertising',
    value: 'Advertising',
  },
  {
    key: 'Bowling',
    value: 'Bowling',
  },
  {
    key: 'Dining',
    value: 'Dining',
  },
  {
    key: 'Taekwondo',
    value: 'Taekwondo',
  },
  {
    key: 'Gym',
    value: 'Gym',
  },
  {
    key: 'Dog Walking',
    value: 'Dog Walking',
  },
  {
    key: 'Animal Care',
    value: 'Animal Care',
  },
  {
    key: 'Mascot',
    value: 'Mascot',
  },
  {
    key: 'Bike Repair',
    value: 'Bike Repair',
  },
  // REPORTING TRANSLATIONS
  {
    key: 'reportsTitle',
    value: 'Insights',
  },
  {
    key: 'viewJobMatches',
    value: 'View Job Matches',
  },
  {
    key: 'viewEmployerMatches',
    value: 'View Employer Matches',
  },
  {
    key: 'by',
    value: 'by',
  },
  {
    key: 'thatAre',
    value: 'that are',
  },
  {
    key: 'global',
    value: 'global',
  },
  {
    key: 'myOrg',
    value: 'my org',
  },
  {
    key: 'topRanked',
    value: 'Top ranked',
  },
  {
    key: 'topRankedSkillsAndInterests',
    value: 'Ranked skills & interests',
  },
  {
    key: 'rankedJobKeywords',
    value: 'Ranked job keywords',
  },
  {
    key: 'IndProfileTab',
    value: 'Skill and Interest Profile',
  },
  {
    key: 'JobProfileTab',
    value: 'Keyword Profile',
  },
  {
    key: 'IndPieTab',
    value: 'Individual Status Report',
  },
  {
    key: 'indStatus',
    value: 'Individual Status',
  },
  {
    key: 'reportTitleI',
    value:
      'Identify the top 10 most common skills or interests of Individuals (private to your organization) to help identify the kinds of jobs, companies and industries your organization could target. Toggle by those who are ‘open to work’, ‘not open to work’ or ‘ALL’.',
  },
  {
    key: 'reportTitleII',
    value:
      'Identify the top 10 most common keywords associated with Jobs. Toggle by “Open jobs” “Closed jobs” or “All jobs”.',
  },
  {
    key: 'reportTitleIII',
    value:
      'Use this report to identify Individuals (private to your organization) who could benefit from having more skills or interests in their profile (which helps improve matching opportunities).',
  },
  {
    key: 'reportTitleIV',
    value:
      'Use this report to identify Jobs that could benefit from having more skills or interests in its description (which helps improve matching opportunities).',
  },
  {
    key: 'reportTitleV',
    value:
      'Use this report to identify Individuals (private to your organization) who are ‘open to work’ and ‘not open to work’ and how many hours they are currently working and want to work.',
  },
  {
    key: 'jobKeywords',
    value: 'job keywords',
  },
  {
    key: 'indDistributionTitle',
    value: 'Individuals grouped by total skills',
  },
  {
    key: 'indDistributionTitleII',
    value: 'Individuals grouped by total interests',
  },
  {
    key: 'jobDistributionTitle',
    value: 'Jobs grouped by total keywords',
  },
  {
    key: 'indDistributionSubtitle',
    value: 'Individuals open to work vs. not open to work',
  },
  {
    key: 'noGroupingsMessage',
    value: 'No groupings found for this tag',
  },
  {
    key: 'noDataMessage',
    value: 'No Data',
  },
  {
    key: 'submitRequest',
    value: 'Submit Request',
  },
  {
    key: 'submitKeywordMsg',
    value: `Don't forget to hit the submit request button below!`,
  },
  {
    key: 'submitPromptI',
    value: `Not finding the`,
  },
  {
    key: 'submitPromptII',
    value: `you are looking for? Submit a request here.`,
  },
  {
    key: 'overview',
    value: 'Overview & Description',
  },
  {
    key: 'my-clients',
    value: 'My Clients',
  },
  {
    key: 'no-conn-id',
    value: 'No search results',
  },
  {
    key: 'no-apply',
    value: 'No active applications',
  },
  {
    key: 'no-like',
    value: 'No liked jobs',
  },
  {
    key: 'activity-feed',
    value: 'Engagement',
  },
  {
    key: 'jobs-liked',
    value: 'Jobs Liked',
  },
  /////////// duplicate then add to frTrans below
  {
    key: 'changeStatusMessage-1',
    value: 'Changing an application status to',
  },
  {
    key: 'changeStatusMessage-2',
    value: 'Offer of Employment',
  },
  {
    key: 'changeStatusMessage-3',
    value: 'will notify all individuals who have started an application that they are',
  },
  {
    key: 'changeStatusMessage-4',
    value: 'Not a Match',
  },
  {
    key: 'changeStatusMessage-5',
    value: 'and remove the job posting from their Active Applications list.',
  },
  {
    key: 'panelForms-continue',
    value: 'Continue',
  },
  {
    key: 'popupTitle-areYouSure',
    value: 'Are you sure?',
  },
  {
    key: 'selectRegisterOption-referenceCode',
    value: 'Reference code',
  },
  {
    key: 'db-profileCompletionTitle',
    value: 'Profile Completion',
  },
  {
    key: 'qt-organization',
    value: 'Organization',
  },
  {
    key: 'jobs-applicationsStarted',
    value: 'Applications Started',
  },
  {
    key: 'jobs-applications',
    value: 'Applications',
  },
  {
    key: 'connected-ind',
    value: 'Active applications',
  },
  ////breadcrumb below, already added, don't duplicate
  {
    key: 'db-jobInterestHours',
    value: 'Hours to fill: ',
  },
  {
    key: 'button-back',
    value: 'Back',
  },
  {
    key: 'Troubleshooting (interest)',
    value: 'Troubleshooting (interest)',
  },
  {
    key: 'Teamwork (skill)',
    value: 'Teamwork (skill)',
  },
  {
    key: 'theirProfile',
    value: 'their profile',
  },
  {
    key: 'weeklySchedule',
    value: 'Please let us know your work schedule.',
  },
  {
    key: 'employmentRecord-weekly-schedule-label',
    value: 'Weekly Schedule',
  },
  {
    key: 'myactivity-removeApplied',
    value: 'removed their application for',
  },
  {
    key: 'myactivity-archive',
    value: 'archived their successful application for',
  },
];

export const frTrans = [
  {
    key: 'hello',
    value: 'Bonjour',
  },
  {
    key: 'dashboardNav',
    value: 'Tableau de bord',
  },
  {
    key: 'employerNav',
    value: 'Employeur',
  },
  {
    key: 'inviteNav',
    value: 'Inviter',
  },
  { key: 'inviteTitle', value: 'Inviter des membres de l’organisation' },
  {
    key: 'inviteText',
    value: `Remplissez le formulaire ci-dessous pour envoyer une invitation aux membres de votre organisation. Les invitations expirent dans un délai de 7 jours, mais ne vous inquiétez pas, vous pourrez toujours en envoyer une autre!`,
  },
  {
    key: 'jobNav',
    value: 'Postes',
  },
  {
    key: 'individualNav',
    value: 'Personne',
  },
  {
    key: 'employer',
    value: 'employeur',
  },
  {
    key: 'individual',
    value: 'personne',
  },
  {
    key: 'individualBtnText',
    value: 'la Personne',
  },
  {
    key: 'globalJobsTitle',
    value: 'Postes ouverts à l’échelle du réseau',
  },
  {
    key: 'jobsWidgetTitle',
    value: 'Derniers postes',
  },
  {
    key: 'public',
    value: 'publique',
  },
  {
    key: 'addJobBtn',
    value: 'Ajouter un poste',
  },
  {
    key: 'privateIndividualTitle',
    value: 'Personnes en recherche d’emploi',
  },
  {
    key: 'individualWidgetTitle',
    value: 'Dernières personnes',
  },
  {
    key: 'privateBtn',
    value: ' Privé',
  },
  {
    key: 'add',
    value: 'Ajouter',
  },
  {
    key: 'addIndividualBtn',
    value: 'Ajouter une personne',
  },
  {
    key: 'privateEmployerTitle',
    value: 'Derniers employeurs',
  },
  {
    key: 'addEmployerBtn',
    value: 'Ajouter un employeur',
  },
  {
    key: 'notificationTitle',
    value: 'Notifications',
  },
  {
    key: 'notificationCountTextI',
    value: 'Vous avez',
  },
  {
    key: 'of',
    value: 'sur',
  },
  {
    key: 'a(f)',
    value: 'une',
  },
  {
    key: 'a(m)',
    value: 'un',
  },
  {
    key: 'notificationCountTextII',
    value: 'notifications non lues.',
  },
  {
    key: 'filterKeyEventsBtn',
    value: 'Filtrer les événements clés',
  },
  {
    key: 'notificationText',
    value: 'Auparavant',
  },
  {
    key: 'notificationMarkAllBtn',
    value: 'Tout marquer comme lu',
  },
  {
    key: 'logoutBtn',
    value: 'Déconnexion',
  },
  {
    key: 'empList',
    value: 'Liste des employeurs',
  },
  {
    key: 'columns',
    value: 'Colonnes',
  },
  {
    key: 'filters',
    value: 'Filtres',
  },
  {
    key: 'density',
    value: 'Densité',
  },
  {
    key: 'export',
    value: 'Exportation',
  },
  {
    key: 'search',
    value: 'Recherche',
  },
  {
    key: 'orgName',
    value: 'Nom de l’organisation',
  },
  {
    key: 'parentOrgName',
    value: 'Nom de l’organisation mère',
  },
  {
    key: 'supportLead',
    value: 'Responsable du soutien à l’emploi',
  },
  {
    key: 'mainCname',
    value: 'Nom de la personne-ressource principale',
  },
  {
    key: 'mainCphone',
    value: 'Numéro de téléphone de la personne-ressource principale',
  },
  {
    key: 'mainCemail',
    value: 'E-mail',
  },
  {
    key: 'address',
    value: 'Adresse',
  },
  {
    key: 'industry',
    value: 'Industrie',
  },
  {
    key: 'description',
    value: 'Description',
  },
  {
    key: 'multiLocation',
    value: 'Lieux multiples',
  },
  {
    key: 'status',
    value: 'Statut',
  },
  {
    key: 'reqSkills',
    value: 'Compétences exigées',
  },
  {
    key: 'keywords',
    value: 'Mots-clés',
  },
  {
    key: 'skillsAndIntersts',
    value: 'compétences et intérêts',
  },
  {
    key: 'onboardDate',
    value: 'Date d’intégration',
  },
  {
    key: 'jobList',
    value: 'Liste des postes',
  },
  {
    key: 'jobTitleJobList',
    value: 'Intitulé du poste',
  },
  {
    key: 'closeDate',
    value: 'Date de clôture',
  },
  {
    key: 'hrsPerWk',
    value: 'Heures par semaine',
  },
  {
    key: 'schedule',
    value: 'Horaire',
  },
  {
    key: 'prefStartDate',
    value: 'Date de début idéale',
  },
  {
    key: 'startDateLabel',
    value: 'Date de début',
  },
  {
    key: 'certReq',
    value: 'Certification exigée',
  },
  {
    key: 'trainingAvail',
    value: 'Formation disponible',
  },
  {
    key: 'openToTraining',
    value: 'Possibilité d’un accompagnement professionnel pendant la formation',
  },
  {
    key: 'trainingReq',
    value: 'Formation exigée',
  },
  {
    key: 'notesComments',
    value: 'Remarques/Commentaires',
  },
  {
    key: 'individualList',
    value: 'Liste des personnes',
  },
  {
    key: 'fullNameIndividual',
    value: 'Nom complet',
  },
  {
    key: 'phoneNumber',
    value: 'Numéro de téléphone',
  },
  {
    key: 'email',
    value: 'Adresse électronique',
  },
  {
    key: 'dob',
    value: 'Date de naissance',
  },
  {
    key: 'popoCheck',
    value: 'Date de la dernière vérification policière',
  },
  {
    key: 'creditCheck',
    value: 'Date de la dernière vérification de solvabilité',
  },
  {
    key: 'prefRoles',
    value: 'Fonctions préférées',
  },
  {
    key: 'minRate',
    value: 'Tarif minimum par heure*',
  },
  {
    key: 'maxRate',
    value: 'Tarif maximum par heure',
  },
  {
    key: 'skills',
    value: 'Compétences',
  },
  {
    key: 'interests',
    value: 'Intérêts',
  },
  {
    key: 'restrictions',
    value: 'Restrictions',
  },
  {
    key: 'daysAvail',
    value: 'Jours disponibles',
  },
  {
    key: 'currentWorkingHrs',
    value: 'Heures actuellement travaillées',
  },
  {
    key: 'totalAvailHrs',
    value: 'Total des heures disponibles',
  },
  {
    key: 'dateIntake',
    value: 'Date d’admission',
  },
  {
    key: 'inviteTitle',
    value: 'Inviter des membres de l’organisation',
  },
  {
    key: 'inviteText',
    value:
      'Remplissez le formulaire ci-dessous pour envoyer une invitation aux membres de votre organisation. Les invitations expirent dans un délai de 7 jours, mais ne vous inquiétez pas, vous pourrez toujours en envoyer une autre!',
  },
  {
    key: 'fName',
    value: 'Prénom',
  },
  {
    key: 'lName',
    value: 'Nom de famille',
  },
  {
    key: 'inviteEmailMsg',
    value: `Il s’agit de l’adresse électronique qui sera utilisée pour se connecter à MyJobMatch`,
  },
  {
    key: 'inviteBtn',
    value: 'Envoyer l’invitation',
  },
  {
    key: 'settingsTitle',
    value: 'Paramètres',
  },
  {
    key: 'basicInfo',
    value: 'Renseignements de base',
  },
  {
    key: 'profileImg',
    value: 'Photo de profil',
  },
  {
    key: 'userEmail',
    value: 'E-mail du compte',
  },
  {
    key: 'changeEmailMsg',
    value: 'Pour changer, contactez le support.',
  },
  {
    key: 'submitSettingsBtn',
    value: 'Mettre à jour',
  },
  {
    key: 'submitSaveBtn',
    value: 'Enregistrer',
  },
  {
    key: 'employerBtnText',
    value: `l'employeur`,
  },
  {
    key: 'initialContract',
    value: 'Contrat initial',
  },
  {
    key: 'followUpContract',
    value: 'Contrat de suivi',
  },
  {
    key: 'presentation',
    value: 'Présentation',
  },
  {
    key: 'jobPostingNew',
    value: "Offre d'emploi - nouveau",
  },
  {
    key: 'jobPostingRenew',
    value: "Offre d'emploi - renouvellement",
  },
  {
    key: 'activityType',
    value: "Type d'activité",
  },
  {
    key: 'closeJobTooltip',
    value: 'Fermez le travail avant de le supprimer',
  },
  {
    key: 'jobBtnText',
    value: `LE POSTE`,
  },
  {
    key: 'matches',
    value: 'recommandations',
  },
  {
    key: 'helpBtn',
    value: 'Aide',
  },
  {
    key: 'none',
    value: 'Aucune',
  },
  {
    key: 'helpPrompt',
    value: 'Sélectionnez l’une des options ci-dessous',
  },
  {
    key: 'helpReportOption',
    value: 'Signaler un bogue, un problème ou une erreur',
  },
  {
    key: 'helpEmailOption',
    value: 'Nous envoyer un courriel',
  },
  {
    key: 'helpGuideOption',
    value: `Lire le guide d'utilisation`,
  },
  {
    key: 'here',
    value: 'ici',
  },
  {
    key: 'NotificationTitle',
    value: 'Notifications',
  },
  {
    key: 'jobNewNotif',
    value: 'propose un nouveau poste',
  },
  {
    key: 'jobUpdatedNotif',
    value: 'a mis à jour un poste',
  },
  {
    key: 'jobClosingNotif1',
    value: 'le poste pour',
  },
  {
    key: 'jobClosingNotif2',
    value: 'sera',
  },
  {
    key: 'jobClosingNotif3',
    value: 'clôturé dans',
  },
  {
    key: 'jobClosingNotif4',
    value: 'jour(s)',
  },
  {
    key: 'jobClosingNotif5',
    value: 'clôturé aujourd’hui!',
  },
  {
    key: 'newProvNotif',
    value: 'a été ajouté à votre organisation',
  },
  {
    key: 'profilUpdatedNotif',
    value: 'a été mis à jour',
  },
  {
    key: 'individualAddedNotif',
    value: 'a ajouté un chercheur d’emploi',
  },
  {
    key: 'updated',
    value: 'a mis à jour ',
  },
  {
    key: 'open',
    value: 'Ouvert',
  },
  {
    key: 'closed',
    value: 'Fermé',
  },
  {
    key: 'individualUpdatedNotif',
    value: 'profil d’une nouvelle personne',
  },
  { key: 'aNewPerson', value: 'une nouvelle personne' },
  {
    key: 'individualMilkExpirationNotif1',
    value: 'est en recherche d’emploi depuis',
  },
  {
    key: 'individualMilkExpirationNotif2',
    value: 'jours sans aucun changement',
  },
  {
    key: 'individualMatchesNotif',
    value: 'a de nouvelles recommandations d’emploi',
  },
  {
    key: 'selectAddressPlaceholder',
    value:
      'Veuillez sélectionner une adresse dans le menu déroulant afin d’enregistrer correctement une adresse.',
  },
  {
    key: 'addressHelperText',
    value:
      'Pour enregistrer ce profil et effectuer une mise en correspondance par lieu, vous devez sélectionner une adresse dans le menu déroulant.',
  },
  {
    key: 'dobLabel',
    value: 'Date de naissance',
  },
  {
    key: 'dobPlaceholder',
    value: 'mm/jj/aaaa',
  },
  {
    key: 'dobHelperText',
    value: 'Veuillez saisir une date de naissance ; les personnes doivent avoir au moins 14 ans.',
  },
  {
    key: 'preferredRolesHelperText',
    value: 'Quels sont les postes recherchés par cette personne?',
  },
  {
    key: 'skillsTitle',
    value: 'Compétences',
  },
  {
    key: 'interestsTitle',
    value: 'Intérêts',
  },
  {
    key: 'restrictionsTitle',
    value: 'Restrictions',
  },
  {
    key: 'restrictionsTextPlaceholder',
    value:
      'Exemple : pas de charges lourdes à soulever, pas de quarts de nuit, allergie aux animaux domestiques',
  },
  {
    key: 'restrictionsHelperText',
    value: 'Résumé de toutes les restrictions possibles (maximum 150 caractères)',
  },
  {
    key: 'individual',
    value: 'personne',
  },
  {
    key: 'indStatusLabel',
    value: 'Statut de la personne',
  },
  {
    key: 'indStatusOpen',
    value: 'En recherche d’emploi',
  },
  {
    key: 'indStatusNotOpen',
    value: `Ne recherche pas d'emploi`,
  },
  {
    key: 'daysAvailTitle',
    value: 'Jours de disponibilité',
  },
  {
    key: 'daysAvailHelperText',
    value:
      'Sélectionnez les jours applicables, même si la personne n’est pas en recherche d’emploi pour le moment.',
  },
  {
    key: 'totalHrsLabel',
    value: 'Nombre total d’heures',
  },
  {
    key: 'hrsprWk',
    value: 'Hours per Week',
  },
  {
    key: 'totalHrsPlaceholder',
    value: 'Saisissez le nombre d’heures que la personne souhaite travailler par semaine.',
  },
  {
    key: 'HrsPlaceholder',
    value: 'E.g. 16',
  },
  {
    key: 'currentEmploymentTitle',
    value: "Calendrier d'emploi et historique",
  },
  {
    key: 'addJobBtn',
    value: 'Ajouter un poste',
  },
  {
    key: 'attachmentsTitle',
    value: 'Pièces jointes',
  },
  {
    key: 'resumeUploadTitle',
    value: 'CV',
  },
  {
    key: 'resumeUploadLabel',
    value: 'Joindre un CV',
  },
  {
    key: 'coverLetterUploadTitle',
    value: 'Lettre d’accompagnement',
  },
  {
    key: 'coverLetterUploadLabel',
    value: 'Joindre une lettre d’accompagnement',
  },
  {
    key: 'refTitle',
    value: 'Références',
  },
  {
    key: 'refHelperText',
    value: '3 au maximum',
  },
  {
    key: 'refLabel',
    value: 'Joindre des références',
  },

  {
    key: 'certsHelperText',
    value: '5 au maximum',
  },
  {
    key: 'certsLabel',
    value: 'Joindre des certifications',
  },
  {
    key: 'individualSubmitBtn',
    value: 'Enregistrer la personne',
  },
  {
    key: 'empInfoMainTitle',
    value: 'APERÇU DE LA PERSONNE',
  },
  {
    key: 'empScheduleTitle',
    value: 'HORAIRE D’EMPLOI ACTUEL',
  },
  {
    key: 'emptyNotesMsgIndividual',
    value: 'Il n’y a actuellement aucun commentaire pour cette personne.',
  },
  {
    key: 'nearestJobBtn',
    value: 'Postes les plus proches',
  },
  {
    key: 'nearestEmployersBtn',
    value: 'Employeurs les plus proches',
  },
  {
    key: 'alignedJobsBtn',
    value: 'Postes les plus adéquats',
  },
  {
    key: 'recommendationsWidgetAlignedBtn',
    value: 'Emplois les plus adéquats',
  },
  {
    key: 'recommendataionsCumBtn',
    value: 'Recommandations cumulées',
  },
  {
    key: 'individualViewBackBtn',
    value: 'Retour à la liste des personnes',
  },
  {
    key: 'individualViewEditBtn',
    value: 'Modifier la personne',
  },
  {
    key: 'jobViewEditBtn',
    value: 'Modifier la poste',
  },
  {
    key: 'job',
    value: 'poste',
  },
  {
    key: 'employerViewTitle',
    value: 'DÉTAILS DE L’EMPLOYEUR',
  },
  {
    key: 'employerName',
    value: `Employeur`,
  },
  {
    key: 'employersName',
    value: `E.g. Amazon`,
  },
  {
    key: 'addImg',
    value: `Ajouter une image`,
  },
  {
    key: 'addImgText',
    value: 'Ajouter une image',
  },
  {
    key: 'defaultOption',
    value: 'par défaut',
  },
  {
    key: 'leadProvLabel',
    value: 'Responsable du soutien à l’emploi',
  },
  {
    key: 'orgNameLabel',
    value: 'Nom de l’organisation*',
  },
  {
    key: 'jobInputPlaceholder',
    value: 'Exemple : Loblaws – rue Dupont',
  },
  {
    key: 'orgNameHelperText',
    value:
      'Il s’agit du nom de votre organisation suivi d’un élément d’identification (en cas de lieux multiples).',
  },
  {
    key: 'parentOrgNameLabel',
    value: 'Organisation mère',
  },
  {
    key: 'parentOrgNamePlaceholder',
    value: 'Exemple : Loblaws',
  },
  {
    key: 'parentOrgNameHelperText',
    value:
      'S’il n’y a qu’un seul lieu, le nom de l’organisation mère est identique à celui de l’organisation.',
  },
  {
    key: 'multiLocLabel',
    value: 'Lieux multiples',
  },
  {
    key: 'industryLabel',
    value: 'Industrie*',
  },
  {
    key: 'thousandMaxCharMsg',
    value: 'Maximum 3000 caractères.',
  },
  {
    key: 'twoThousandMaxCharMsg',
    value: 'Maximum 3000 caractères.',
  },
  {
    key: 'reqSkills',
    value: 'Compétences exigées',
  },
  {
    key: 'reqSkillsPlaceholder',
    value: 'Se tenir debout pendant de longues heures, soulever plus de 50 livres, etc.',
  },
  {
    key: 'keywordLabel',
    value: 'Mots-clés',
  },
  {
    key: 'keywordPlaceholder',
    value:
      'Plus vous utilisez de mots-clés, plus vous générez de correspondances entre des employeurs et des personnes.',
  },
  {
    key: 'mcFirstNameLabel',
    value: 'Prénom de la personne-ressource principale',
  },
  {
    key: 'mcLastNameLabel',
    value: 'Nom de famille de la personne-ressource principale',
  },
  {
    key: 'mcPhoneNumberLabel',
    value: 'Numéro de téléphone de la personne-ressource principale',
  },
  {
    key: 'mcPhoneNumberMsg',
    value: 'Numéro de téléphone du contact principal requis',
  },
  {
    key: 'mcEmailLabel',
    value: 'Adresse électronique de la personne-ressource principale',
  },
  {
    key: 'employerProfile',
    value: 'Profil de l’employeur',
  },
  {
    key: 'savingEmployer',
    value: 'Employeur épargnant',
  },
  {
    key: 'savingJob',
    value: 'Poste épargnant',
  },
  {
    key: 'savingIndividual',
    value: 'Personne épargnant',
  },
  {
    key: 'savedSuccess',
    value: 'enregistré avec succès!',
  },
  {
    key: 'view',
    value: 'Voir',
  },
  {
    key: 'viewing',
    value: 'Affichage',
  },
  {
    key: 'viewJobBtn',
    value: 'Afficher le poste',
  },
  {
    key: 'employerViewEditBtn',
    value: 'Modifier l’employeur',
  },
  {
    key: 'employerDetails',
    value: 'Détails concernant l’employeur',
  },
  {
    key: 'employerViewHeader2',
    value: 'APERÇU DE L’EMPLOYEUR',
  },
  {
    key: 'employerViewHeader3',
    value: 'DESCRIPTION DE L’EMPLOYEUR',
  },
  {
    key: 'emptyDetailsMsgEmployerView',
    value: 'Pas de renseignements disponibles pour cet employeur...',
  },
  {
    key: 'emptyJobListingMsgEmployerView',
    value: 'Il n’y a actuellement aucun poste à pourvoir chez cet employeur.',
  },
  {
    key: 'emptyNotesMsgEmployerView',
    value: 'Il n’y a actuellement aucun commentaire pour cet employeur.',
  },
  {
    key: 'nearestIndividualBtn',
    value: 'Personnes les plus proches',
  },
  {
    key: 'nearest',
    value: 'la plus proches',
  },
  {
    key: 'aligned',
    value: 'le plus aligné',
  },
  {
    key: 'cumulative',
    value: 'cumulatif',
  },
  {
    key: 'alignedIndividualBtn',
    value: 'Personnes les plus adéquates',
  },
  {
    key: 'alignedEmployersBtn',
    value: 'Employeurs les plus adéquate',
  },
  {
    key: 'cumBtn',
    value: 'Recommandations cumulées',
  },
  {
    key: 'jobDetailTitle',
    value: 'DÉTAILS DU POSTE',
  },
  {
    key: 'orgNameLabel',
    value: 'Nom de l’organisation*',
  },
  {
    key: 'jobStatusLabel',
    value: 'Statut du poste',
  },
  {
    key: 'jobClosed',
    value: 'Fermé',
  },
  {
    key: 'jobOpened',
    value: 'Ouvert',
  },
  {
    key: 'jobTypeLabel',
    value: 'Type de poste',
  },
  {
    key: 'irlText',
    value: 'Sur place',
  },
  {
    key: 'remoteText',
    value: 'À distance',
  },
  {
    key: 'hybridText',
    value: 'Hybride',
  },
  {
    key: 'addresPlaceholder',
    value: 'Adresse',
  },
  {
    key: 'addressLabel',
    value: 'Adresse*',
  },
  {
    key: 'esProviderLabel',
    value: 'Fournisseur de soutien à l’emploi (renseigné automatiquement)',
  },
  {
    key: 'esProviderplaceholder',
    value:
      'Le principal fournisseur de soutien à l’emploi de cet employeur est automatiquement renseigné pour ce poste.',
  },
  {
    key: 'jobTitleLabel',
    value: 'Intitulé du poste*',
  },
  {
    key: 'jobTitlePlaceholder',
    value: 'Caissier/caissière –  quart de jour/nuit, Quinpool Road',
  },
  {
    key: 'descriptionLabel',
    value: 'Description',
  },
  {
    key: 'jobDescriptionText',
    value: 'Description du poste',
  },
  {
    key: 'keywordsLabel',
    value: 'Mots-clés*',
  },
  {
    key: 'skillsInterestsLabel',
    value: 'Mots-clés concernant les intérêts et les compétences*',
  },
  {
    key: 'skillsInterestPlaceholder',
    value:
      'Plus vous utilisez de mots-clés, plus vous générez de correspondances entre des employeurs et des personnes. Les mots-clés sont une combinaison de compétences et d’intérêts.',
  },
  {
    key: 'skills&interestNotesLabel',
    value: 'Compétences et intérêts (notes supplémentaires)',
  },
  {
    key: 'skills&interestplaceholder',
    value: 'Décrivez brièvement ce que vous recherchez dans ce poste.',
  },
  {
    key: 'skills&interestHelperText',
    value:
      'Vous n’avez pas trouvé les mots-clés que vous cherchiez? Ajoutez des renseignements supplémentaires pour faciliter la mise en correspondance des compétences.',
  },
  {
    key: 'hourlyRate',
    value: 'Taux horaire',
  },
  {
    key: 'minHrRateLabel',
    value: 'Taux horaire minimal',
  },
  {
    key: 'maxHrRateLabel',
    value: 'Taux horaire maximal',
  },
  {
    key: 'wklyHrsLabel',
    value: 'Heures par semaine*',
  },
  {
    key: 'iconText',
    value: 'icône',
  },
  {
    key: 'reqDaysLabel',
    value: 'Jours requis',
  },
  {
    key: 'reqDaysPlaceholder',
    value: 'Sélectionnez les jours applicables pour ce poste.',
  },
  {
    key: 'Monday',
    value: 'Lundi',
  },
  {
    key: 'Tuesday',
    value: 'Mardi',
  },
  {
    key: 'Wednesday',
    value: 'Mercredi',
  },
  {
    key: 'Thursday',
    value: 'Jeudi',
  },
  {
    key: 'Friday',
    value: 'Vendredi',
  },
  {
    key: 'Saturday',
    value: 'Samedi',
  },
  {
    key: 'Sunday',
    value: 'Dimanche',
  },
  {
    key: 'Mon',
    value: 'Lun.',
  },
  {
    key: 'Tues',
    value: 'Mar.',
  },
  {
    key: 'Wed',
    value: 'Mer.',
  },
  {
    key: 'Thur',
    value: 'Jeu.',
  },
  {
    key: 'Fri',
    value: 'Ven.',
  },
  {
    key: 'Sat',
    value: 'Sam.',
  },
  {
    key: 'Sun',
    value: 'Dim.',
  },
  {
    key: 'MonToSun',
    value: 'Lun.-Dim.',
  },
  {
    key: 'MonToFri',
    value: 'Lun.-Ven.',
  },
  {
    key: 'idealStartDate',
    value: 'Date de début idéale',
  },
  {
    key: 'closingDateJob',
    value: 'Date de clôture',
  },
  {
    key: 'closingDateCheckBoxLabel',
    value: 'Activer une date de clôture pour ce poste',
  },
  {
    key: 'trainingReqLabel',
    value: 'Formation exigée',
  },
  {
    key: 'certsReqLabel',
    value: 'Certification exigée',
  },
  {
    key: 'trainingAvailLabel',
    value: 'Formation exigée',
  },
  {
    key: 'openToCoachingLabel',
    value: 'Possibilité d’un accompagnement professionnel pendant la formation',
  },
  {
    key: 'howToApply',
    value: 'Comment postuler',
  },
  {
    key: 'notesPlaceholder',
    value:
      'Veuillez préciser comment l’employeur souhaite recevoir les candidatures à ce poste (sur le site Web, par l’intermédiaire du fournisseur de soutien à l’emploi, sur place, etc.).',
  },
  {
    key: 'attachmentsTitle',
    value: 'Pièces jointes',
  },
  {
    key: 'jobDescriptionText',
    value: 'Description du poste',
  },
  {
    key: 'uploadJobDescriptionBtn',
    value: 'Joindre la description du poste',
  },
  {
    key: 'additionalFilesTitle',
    value: 'Fichiers supplémentaires',
  },
  {
    key: 'additionalFilesText',
    value: 'Fichiers supplémentaires',
  },
  {
    key: 'jobFormSubmitBtn',
    value: 'Enregistrer le poste',
  },
  {
    key: 'providerCardTitle',
    value: 'RESPONSABLE DU SOUTIEN À L’EMPLOI',
  },
  {
    key: 'overviewTitleJobView',
    value: 'APERÇU DU POSTE',
  },
  {
    key: 'empty',
    value: 'Vide',
  },
  {
    key: 'backBtnJobView',
    value: 'Retour à la liste des postes',
  },
  {
    key: 'backBtnEmployerView',
    value: 'Retour à la liste des employeurs',
  },
  {
    key: 'individualFormTitle',
    value: 'Détails de la personne',
  },
  {
    key: 'leadProviderLabel',
    value: 'Responsable du soutien à l’emploi',
  },
  {
    key: 'empStatus',
    value: `Statut de l'employeur`,
  },
  {
    key: 'individualFirstNameLabel',
    value: 'Prénom',
  },
  {
    key: 'individualLastNameLabel',
    value: 'Nom de famille',
  },
  {
    key: 'phoneNumber',
    value: 'Numéro de téléphone',
  },
  {
    key: 'chooseYourProNoun',
    value: 'Pronoms préférés',
  },
  {
    key: 'agencyIdLabel',
    value: 'Identifiant de l’organisme',
  },
  {
    key: 'govIdLabel',
    value: 'Pièce d’identité gouvernementale',
  },
  {
    key: 'prefCommsLabel',
    value: 'Communication préférée',
  },
  {
    key: 'N/A',
    value: 'Sans objet',
  },
  {
    key: 'accomodationsLabel',
    value: 'Mesures d’adaptation',
  },
  {
    key: 'acommodationsPlaceholder',
    value:
      'Exemple : matériel spécialisé, modification de l’environnement de travail ou adaptation des horaires de travail ou des responsabilités.',
  },
  {
    key: 'selectAddressPlaceholder',
    value:
      'Veuillez sélectionner une adresse dans le menu déroulant afin d’enregistrer correctement une adresse.',
  },
  {
    key: 'dobLabel',
    value: 'Date de naissance*',
  },
  {
    key: 'dobPlaceholder',
    value: 'mm/jj/aaaa',
  },
  {
    key: 'preferredRolesHelperText',
    value: 'Quels sont les postes recherchés par cette personne?',
  },
  {
    key: 'restrictionsTitle',
    value: 'Restrictions',
  },
  {
    key: 'restrictionsTextPlaceholder',
    value:
      'Exemple : pas de charges lourdes à soulever, pas de quarts de nuit, allergie aux animaux domestiques',
  },
  {
    key: 'restrictionsHelperText',
    value: 'Résumé de toutes les restrictions possibles (maximum 150 caractères)',
  },
  {
    key: 'indStatusLabel',
    value: 'Statut de la personne',
  },
  {
    key: 'indStatusOpen',
    value: 'En recherche d’emploi',
  },
  {
    key: 'daysAvailTitle',
    value: 'Jours de disponibilité',
  },
  {
    key: 'addJobBtn',
    value: 'Ajouter un poste',
  },
  {
    key: 'attachmentsTitle',
    value: 'Pièces jointes',
  },
  {
    key: 'resumeUploadTitle',
    value: 'CV',
  },
  {
    key: 'resumeUploadLabel',
    value: 'Joindre un CV',
  },
  {
    key: 'coverLetterUploadTitle',
    value: 'Lettre d’accompagnement',
  },
  {
    key: 'coverLetterUploadLabel',
    value: 'Joindre une lettre d’accompagnement',
  },
  {
    key: 'refTitle',
    value: 'Références',
  },
  {
    key: 'refHelperText',
    value: '3 au maximum',
  },
  {
    key: 'refLabel',
    value: 'Joindre des références',
  },
  {
    key: 'refButtonLabel',
    value: 'référence(s) jointe(s)',
  },
  {
    key: 'certButtonLabel',
    value: 'attestation(s) ci-jointe(s)',
  },
  {
    key: 'certificationTitle',
    value: 'Certification',
  },
  {
    key: 'certsHelperText',
    value: 'Certifications (5 au maximum)',
  },
  {
    key: 'certsLabel',
    value: 'Joindre des certifications',
  },
  {
    key: 'empInfoMainTitle',
    value: 'APERÇU DE LA PERSONNE',
  },
  {
    key: 'empScheduleTitle',
    value: 'HORAIRE D’EMPLOI ACTUEL',
  },
  {
    key: 'emptyNotesMsgIndividual',
    value: 'Il n’y a actuellement aucun commentaire pour cette personne.',
  },
  {
    key: 'recommendationsWidgetAlignedBtn',
    value: 'Emplois les plus adéquats',
  },
  {
    key: 'recommendataionsCumBtn',
    value: 'Recommandations cumulées',
  },
  {
    key: 'individualViewBackBtn',
    value: 'Retour à la liste des personnes',
  },
  {
    key: 'individualViewEditBtn',
    value: 'Modifier la personne',
  },
  {
    key: 'lessMinute',
    value: `il y a moins d'une minute`,
  },
  {
    key: 'ago',
    value: 'depuis',
  },
  {
    key: 'daysAgo',
    value: 'jours avant',
  },
  {
    key: 'oneDayPast',
    value: 'un jour avant',
  },
  {
    key: 'on',
    value: 'le',
  },
  {
    key: 'inactive',
    value: 'inactif',
  },
  {
    key: 'active',
    value: 'actif',
  },
  {
    key: 'addedBy',
    value: 'ajouté par',
  },
  {
    key: 'added',
    value: 'Ajouté',
  },
  {
    key: 'yes',
    value: 'oui',
  },
  {
    key: 'no',
    value: 'non',
  },
  {
    key: 'na',
    value: 'Sans objet',
  },
  {
    key: 'dayCES',
    value: 'Journée(s)',
  },
  {
    key: 'hours',
    value: 'Heures',
  },
  {
    key: 'role',
    value: 'Le poste',
  },
  {
    key: 'jobSource',
    value: "Source d'emploi",
  },
  {
    key: 'references',
    value: 'Référence(s)',
  },
  {
    key: 'employmentHistory',
    value: "historique d'emploi",
  },
  {
    key: 'noNotifications',
    value: 'Vous n’avez aucune nouvelle notification',
  },
  {
    key: 'hasNewJob',
    value: 'nouvelle correspondance avec un poste',
  },
  {
    key: 'rowPerPage',
    value: 'lignes par page',
  },
  {
    key: 'density',
    value: 'densité',
  },
  {
    key: 'columns',
    value: 'Colonnes',
  },
  {
    key: 'filters',
    value: 'Filtres',
  },
  {
    key: 'export',
    value: 'Exporter',
  },
  {
    key: 'checkBoxSelection',
    value: 'Sélection',
  },
  {
    key: 'noRows',
    value: 'Pas de lignes',
  },
  {
    key: 'noResults',
    value: 'Aucun résultat trouvé.',
  },
  {
    key: 'search...',
    value: 'Recherche...',
  },
  {
    key: 'columnTitle',
    value: 'Nom de colonne',
  },
  {
    key: 'findColumn',
    value: 'Rechercher la colonne',
  },
  {
    key: 'hideAll',
    value: 'masquer tout',
  },
  {
    key: 'showAll',
    value: 'Afficher tout',
  },
  {
    key: 'operator',
    value: 'Opérateur',
  },
  {
    key: 'value',
    value: 'valeur',
  },
  {
    key: 'and',
    value: 'et',
  },
  {
    key: 'or',
    value: 'ou',
  },
  {
    key: 'contains',
    value: 'contient',
  },
  {
    key: 'equals',
    value: 'équivaut à',
  },
  {
    key: 'startsWith',
    value: 'commence par',
  },
  {
    key: 'endsWith',
    value: 'se termine par',
  },
  {
    key: 'isEmpty',
    value: 'est vide',
  },
  {
    key: 'isNotEmpty',
    value: `n'est pas vide`,
  },
  {
    key: 'isAnyOf',
    value: `est une des valeurs`,
  },
  {
    key: 'filterValue',
    value: `Valeur du filtre`,
  },
  {
    key: 'compact',
    value: 'compact',
  },
  {
    key: 'standard',
    value: `standard`,
  },
  {
    key: 'comfortable',
    value: `confortable`,
  },
  {
    key: 'dlCSV',
    value: 'Télécharger CSV',
  },
  {
    key: 'print',
    value: 'imprimer',
  },
  {
    key: 'compact',
    value: 'compact',
  },
  {
    key: 'standard',
    value: `standard`,
  },
  {
    key: 'comfortable',
    value: `confortable`,
  },
  {
    key: 'tMenu',
    value: 'Menu',
  },
  {
    key: 'tShowCol',
    value: 'Afficher les colonnes',
  },
  {
    key: 'tMngCol',
    value: 'Manage columns',
  },
  {
    key: 'tFilter',
    value: 'Filtre',
  },
  {
    key: 'tHideCol',
    value: 'Masquer les colonnes',
  },
  {
    key: 'unsort',
    value: 'Ne pas trier',
  },
  {
    key: 'sortUp',
    value: 'Tri asc.',
  },
  {
    key: 'sortDown',
    value: 'Tri desc.',
  },
  //START Rest of translations
  {
    key: 'selectLeadProv',
    value: 'Sélectionnez un fournisseur principal',
  },
  {
    key: 'enterOrgName',
    value: 'Indiquez le nom de l’employeur.',
  },
  {
    key: 'duplicateOrgFound',
    value: 'Un nom d’organisation en double a été trouvé; veuillez modifier le nom pour continuer.',
  },
  {
    key: 'duplicateOrgFoundMsg',
    value: 'Une organisation en double a été trouvée',
  },
  {
    key: 'duplicateParentOrgFoundMsg',
    value: 'Une organisation mère en double a été trouvée',
  },
  {
    key: 'selectIndustryText',
    value: 'Sélectionnez votre industrie',
  },
  {
    key: 'selectIndutryTextII',
    value: 'Sélectionnez une industrie',
  },
  {
    key: 'mainBranchNameReqMsg',
    value: 'Le nom de la succursale principale est requis',
  },
  {
    key: 'leadProviderReqMsg',
    value: 'Un fournisseur principal doit être sélectionné',
  },
  {
    key: 'descErrorMsg',
    value: 'Veuillez saisir une description comportant moins de 3000 caractères',
  },
  {
    key: 'addressReqMsg',
    value: 'Vous devez sélectionner une adresse parmi l’une des options proposées',
  },
  {
    key: 'addressReqMsgII',
    value:
      'Pour enregistrer ce profil et effectuer une mise en correspondance par lieu, vous devez sélectionner une adresse dans la liste déroulante.',
  },
  {
    key: 'validPhoneReq',
    value: 'Veuillez saisir un numéro valide à 10 chiffres',
  },
  {
    key: 'phoneErrorMsg',
    value: 'Le numéro est trop long!',
  },
  {
    key: 'phoneHelperText',
    value:
      'Veuillez saisir un numéro valide comportant 10 chiffres (possibilité de saisir un numéro de poste comportant 5 chiffres)',
  },
  {
    key: 'emailErrorMsg',
    value: 'Veuillez indiquer une adresse électronique valide',
  },
  {
    key: 'notesErrorMsg',
    value: 'Désolé, vos commentaires doivent comporter moins de 1 000 caractères',
  },
  {
    key: 'created',
    value: 'Créé le',
  },
  {
    key: 'creator',
    value: 'Créateur',
  },
  {
    key: 'goBack',
    value: 'Retour',
  },
  {
    key: 'openToCoachingLabel',
    value: 'Possibilité d’un accompagnement professionnel',
  },
  {
    key: 'openToCoachSupportColumn',
    value: 'Possibilité d’un accompagnement professionnel',
  },
  {
    key: 'noDetailsAvailable',
    value: 'Pas de renseignements disponibles pour ce poste...',
  },
  {
    key: 'noNotesOnEmployer',
    value: 'Vous n’avez actuellement aucun commentaire sur cet employeur…',
  },
  {
    key: 'empOrgReqMsg',
    value: 'Vous devez sélectionner un employeur',
  },
  {
    key: 'addressErrorMsg',
    value: 'Sélectionnez l’adresse complète dans le menu déroulant.',
  },
  {
    key: 'jobDuplicateMsg',
    value: 'Un poste en double a été trouvé',
  },
  {
    key: 'orgSelectPrompt',
    value:
      'Veuillez sélectionner un nom d’organisation avant de créer un intitulé de poste. Remarque : toute modification du nom de l’organisation aura pour effet de réinitialiser ce champ.',
  },
  {
    key: 'jobTitleErrorMsg',
    value: 'Veuillez indiquer un intitulé de poste.',
  },
  {
    key: 'minRateErrorMsg',
    value: 'La valeur doit être inférieure au taux horaire maximal',
  },
  {
    key: 'maxRateErrorMsg',
    value: 'La valeur doit être supérieure au taux horaire minimal',
  },
  {
    key: 'estHrsPerWeek',
    value: 'Estimation des heures requises par semaine (60 heures max.)',
  },
  {
    key: 'jobClosingDateHelperText',
    value: 'Veuillez sélectionner une date située au moins 24 heures dans le futur.',
  },
  {
    key: 'selectProvPlease',
    value: 'Veuillez sélectionner un fournisseur',
  },
  {
    key: 'selectProvider',
    value: 'Sélectionnez un fournisseur',
  },
  {
    key: 'firstNameReqMsg',
    value: 'Un prénom doit être indiqué',
  },
  {
    key: 'validNameReqMsg',
    value:
      'Veuillez saisir un nom valide. Seuls les lettres, les chiffres, les tirets (-) et les espaces sont autorisés.',
  },
  {
    key: 'indExistsMsg',
    value: 'La personne existe',
  },
  {
    key: 'phoneNumberReqMsg',
    value: 'Un numéro de téléphone doit être indiqué',
  },
  {
    key: 'validAddressReqMsg',
    value: 'Veuillez fournir une adresse valide.',
  },
  {
    key: 'dobRequired',
    value: 'La date de naissance est requise.',
  },
  {
    key: 'birthDate',
    value: 'date de naissance',
  },
  {
    key: 'dobErrorMsg',
    value: 'La personne doit avoir 14 ans ou plus pour travailler légalement en Ontario.',
  },
  {
    key: 'expectedSalaryErrorMsg',
    value: 'Je me trouve dans le mauvais champ! Veuillez indiquer des salaires réels.',
  },
  {
    key: 'numberErrorMsg',
    value:
      'Saisie non valide; veuillez supprimer la virgule décimale ou ajouter une valeur décimale.',
  },
  {
    key: 'restrictionsHelperText',
    value: 'Nous vous recommandons de rédiger un texte court comprenant au plus 150 caractères.',
  },
  {
    key: 'availableHoursErrorMsg',
    value: 'Veuillez indiquer moins de 60 heures.',
  },
  {
    key: 'availableHrsHelperText',
    value: 'Environ combien d’heures la personne aimerait-elle travailler en tout par semaine?',
  },
  {
    key: 'availableHoursErrorMsg',
    value: 'Veuillez saisir un nombre d’heures situé entre 0 et 99',
  },
  {
    key: 'thousandMaxChars',
    value: 'Max. 1 000 caractères',
  },
  {
    key: 'selectDayLabel',
    value: 'Veuillez sélectionner un jour.',
  },
  {
    key: 'selectDayReqMsg',
    value: 'Vous devez sélectionner un jour',
  },
  {
    key: 'Rotating',
    value: 'Roulement',
  },
  {
    key: 'hoursReqErrorMsg',
    value: "Veuillez nous indiquer combien d'heures vous travaillez par semaine.",
  },
  {
    key: 'employerNamePleaseMsg',
    value: 'Veuillez fournir un nom d’employeur.',
  },
  {
    key: 'roleReqMsg',
    value: 'Veuillez indiquer une fonction.',
  },
  {
    key: 'reqRoleLabel',
    value: 'E.g. Baker',
  },
  {
    key: 'individualPlacedByMJM',
    value: 'MyJobMatch a-t-il aidé la personne à obtenir ce poste?',
  },
  {
    key: 'individualPlacedByMJMII',
    value: 'Cochez cette case si la personne a obtenu le poste grâce à MyJobMatch.',
  },
  {
    key: 'noLongWorksHere',
    value: 'La personne n’occupe plus ce poste.',
  },
  {
    key: 'roleActiveMsg',
    value: 'La personne occupe actuellement ce poste.',
  },
  {
    key: 'lastNameReqMsg',
    value: 'Un nom de famille doit être indiqué',
  },
  {
    key: 'keepNumberUpdToDate',
    value: 'Veillez à ce que votre numéro de téléphone professionnel soit à jour',
  },
  {
    key: 'proifileUpdated',
    value: 'Profil mis à jour!',
  },
  {
    key: 'iUnderstand',
    value: 'OK, je comprends',
  },
  {
    key: 'closeAllJobsMsgPrompt',
    value: 'Veuillez clôturer tous les postes de cet employeur avant de le rendre inactif.',
  },
  {
    key: 'permanentlyDeleteEmployer',
    value: 'Supprimer définitivement l’employeur?',
  },
  {
    key: 'importantMsgI',
    value:
      'IMPORTANT : tous les postes associés à cet employeur seront supprimés. Cliquez sur Oui pour continuer et supprimer l’employeur et tous les postes qui lui sont associés.',
  },
  {
    key: 'deleteThisMsg',
    value: 'Oui, procédez à la suppression',
  },
  {
    key: 'doNotDeleteThisMsg',
    value: 'Non, j’ai changé d’avis',
  },
  {
    key: 'deleteSuccess',
    value: 'Suppression réussie',
  },
  {
    key: 'deletePermanently',
    value: 'Suppression définitive',
  },
  {
    key: 'permanentlyDeleteJobWarningMsg',
    value:
      'Ce poste sera supprimé définitivement. Cliquez sur Oui pour procéder à la suppression;<b> toutes les correspondances entre le poste et des personnes seront également supprimées</b>.',
  },
  {
    key: 'deleteAllJobWarningMsg',
    value:
      'Tous les postes associés à cet employeur seront supprimés définitivement. <br/> Click yes to proceed and delete <b>the employer and all of the jobs associated with it</b>.',
  },
  {
    key: 'deleteIndividualWarningMsg',
    value:
      '<p>Cette personne sera supprimée définitivement.<br/> Cliquez sur Oui pour procéder à la suppression, <b>toutes les correspondances entre des postes et la personne ET entre des employeurs et la personne seront également supprimées.</b>.</p>',
  },
  {
    key: 'delete',
    value: 'Supprimer',
  },
  {
    key: 'emptyInstructions',
    value: `Mode d'emploi introuvable`,
  },
  {
    key: 'assignLeadToJobMsg',
    value: `Attribuer un nouveau fournisseur principal à tous les emplois associés à cet employeur? Si oui, après avoir mis à jour l’employeur, veuillez recharger la page pour afficher les modifications apportées.`,
  },

  // KEYWORDS
  {
    key: 'Account Administration',
    value: 'Administration de compte',
  },
  {
    key: 'Account Management',
    value: 'Gestion de compte',
  },
  {
    key: 'Accounting',
    value: 'Comptabilité',
  },
  {
    key: 'Accounts Payable',
    value: 'Comptes fournisseurs',
  },
  {
    key: 'Administration',
    value: 'Administration',
  },
  {
    key: 'Administrative Support',
    value: 'Soutien administratif',
  },
  {
    key: 'Adobe',
    value: 'Adobe',
  },
  {
    key: 'Agile',
    value: 'Agile',
  },
  {
    key: 'Answer Phone',
    value: 'Répondre au téléphone',
  },
  {
    key: 'Application Coding',
    value: 'Codage d’applications',
  },
  {
    key: 'Assembly Work',
    value: 'Travaux d’assemblage',
  },
  {
    key: 'Auditing',
    value: 'Audit',
  },
  {
    key: 'Back-End Programming Languages',
    value: 'Langages de programmation dorsaux',
  },
  {
    key: 'Banking',
    value: 'Banque',
  },
  {
    key: 'Beta Testing',
    value: 'Tests bêta',
  },
  {
    key: 'Blended Learning',
    value: 'Apprentissage mixte',
  },
  {
    key: 'Blog Writing',
    value: 'Rédaction d’articles de blogue',
  },
  {
    key: 'Booking Travel',
    value: 'Réservation de voyages',
  },
  {
    key: 'Bookkeeping',
    value: 'Tenue des comptes',
  },
  {
    key: 'Brand Management',
    value: 'Gestion de la marque',
  },
  {
    key: 'Budget Forecasting',
    value: 'Prévisions budgétaires',
  },
  {
    key: 'Budget Management',
    value: 'Gestion budgétaire',
  },
  {
    key: 'Budgeting',
    value: 'Budgétisation',
  },
  {
    key: 'Buggies',
    value: 'Chariots',
  },
  {
    key: 'Buggy Retrieval',
    value: 'Récupération de chariots',
  },
  {
    key: 'Business Analysis',
    value: 'Analyse opérationnelle',
  },
  {
    key: 'Business Development',
    value: 'Développement commercial',
  },
  {
    key: 'Bussing',
    value: 'Aide-serveur',
  },
  {
    key: 'C#',
    value: 'C#',
  },
  {
    key: 'C++',
    value: 'C++',
  },
  {
    key: 'Cafe Attendant',
    value: 'Serveur de café',
  },
  {
    key: 'Car Detailing',
    value: 'Esthétique automobile',
  },
  {
    key: 'Career Analysis',
    value: 'Analyse de carrière',
  },
  {
    key: 'Cash Flow Analysis',
    value: 'Analyse des flux de trésorerie',
  },
  {
    key: 'Cashier',
    value: 'Caissier',
  },
  {
    key: 'Change Management',
    value: 'Gestion du changement',
  },
  {
    key: 'Cleaner',
    value: 'Nettoyeur',
  },
  {
    key: 'Cleaning',
    value: 'Nettoyage',
  },
  {
    key: 'Cleaning Baskets',
    value: 'Nettoyage de paniers',
  },
  {
    key: 'Clerical',
    value: 'Travail de bureau',
  },
  {
    key: 'Coding',
    value: 'Codage',
  },
  {
    key: 'Cold Calling',
    value: 'Prospection téléphonique',
  },
  {
    key: 'Communications / Public Relations',
    value: 'Communication/Relations publiques',
  },
  {
    key: 'Community Engagement',
    value: 'Engagement communautaire',
  },
  {
    key: 'Computer',
    value: 'Ordinateur',
  },
  {
    key: 'Computer Science',
    value: 'Informatique',
  },
  {
    key: 'Computer Skills',
    value: 'Compétences informatiques',
  },
  {
    key: 'Computer Repair',
    value: 'Réparation d’ordinateurs',
  },
  {
    key: 'Concierge',
    value: 'Conciergerie',
  },
  {
    key: 'Concessions',
    value: 'Concessions',
  },
  {
    key: 'Construction',
    value: 'Construction',
  },
  {
    key: 'Content Creation',
    value: 'Création de contenu',
  },
  {
    key: 'Content Marketing',
    value: 'Marketing de contenu',
  },
  {
    key: 'Cooking (skill)',
    value: 'Cuisine (compétence)',
  },
  {
    key: 'Copywriting',
    value: 'Rédaction',
  },
  {
    key: 'Cost Accounting',
    value: 'Comptabilité analytique',
  },
  {
    key: 'Counselling',
    value: 'Conseils',
  },
  {
    key: 'CRM',
    value: 'Gestion de la relation client',
  },
  {
    key: 'Crossing Guard',
    value: 'Brigadier',
  },
  {
    key: 'CSS',
    value: 'CSS',
  },
  {
    key: 'Culinary',
    value: 'Culinaire',
  },
  {
    key: 'Customer Relations',
    value: 'Relation client',
  },
  {
    key: 'Customer Service',
    value: 'Service à la clientèle',
  },
  {
    key: 'Customer Support',
    value: 'Soutien à la clientèle',
  },
  {
    key: 'Data Analytics',
    value: 'Analyse de données',
  },
  {
    key: 'Data Collection',
    value: 'Collecte de données',
  },
  {
    key: 'Data Entry',
    value: 'Saisie de données',
  },
  {
    key: 'Data Modeling',
    value: 'Modélisation de données',
  },
  {
    key: 'Database Administration',
    value: 'Administration de bases de données',
  },
  {
    key: 'Database Management',
    value: 'Gestion de base de données',
  },
  {
    key: 'Debugging',
    value: 'Débogage',
  },
  {
    key: 'Dental Hygiene',
    value: 'Hygiène dentaire',
  },
  {
    key: 'Detailing',
    value: 'Détaillage',
  },
  {
    key: 'Digital Marketing',
    value: 'Marketing numérique',
  },
  {
    key: 'Dishwashing',
    value: 'Plonge',
  },
  {
    key: 'Dispatch',
    value: 'Expédition',
  },
  {
    key: 'Document Management',
    value: 'Gestion de documents',
  },
  {
    key: 'Documentation',
    value: 'Documentation',
  },
  {
    key: 'Dynamic Web Page Design',
    value: 'Conception de pages Web dynamiques',
  },
  {
    key: 'Editing & Proofreading',
    value: 'Édition et relecture',
  },
  {
    key: 'Electrical Engineering',
    value: 'Génie électrique',
  },
  {
    key: 'Email',
    value: 'Courriel',
  },
  {
    key: 'Email Marketing',
    value: 'Marketing par courriel',
  },
  {
    key: 'Emergency Support',
    value: 'Assistance d’urgence',
  },
  {
    key: 'Engineering/Computer Engineering',
    value: 'Ingénierie/Ingénierie informatique',
  },
  {
    key: 'Estate Planning',
    value: 'Planification successorale',
  },
  {
    key: 'Event Management',
    value: 'Gestion d’événements',
  },
  {
    key: 'Excel',
    value: 'Excel',
  },
  {
    key: 'Executive Leadership',
    value: 'Leadership exécutif',
  },
  {
    key: 'Executive Support',
    value: 'Soutien à la haute direction',
  },
  {
    key: 'Expense Reporting',
    value: 'Rapports de dépenses',
  },
  {
    key: 'Facilities Management',
    value: 'Gestion des installations',
  },
  {
    key: 'File',
    value: 'Dossier',
  },
  {
    key: 'Filing',
    value: 'Classer',
  },
  {
    key: 'Financial Accounting',
    value: 'Comptabilité financière',
  },
  {
    key: 'Financial Analysis',
    value: 'Analyse financière',
  },
  {
    key: 'Financial Compliance',
    value: 'Conformité financière',
  },
  {
    key: 'Financial Management',
    value: 'Gestion financière',
  },
  {
    key: 'Financial Planning',
    value: 'Planification financière',
  },
  {
    key: 'Financial Reporting',
    value: 'Information financière',
  },
  {
    key: 'Fitness Training',
    value: 'Entraînement physique',
  },
  {
    key: 'Folding',
    value: 'Plier',
  },
  {
    key: 'Food Safety',
    value: 'Food Safety',
  },
  {
    key: 'Food Preparation',
    value: 'Préparation des aliments',
  },
  {
    key: 'Food Processing',
    value: 'Transformation des aliments',
  },
  {
    key: 'Forecasting',
    value: 'Prévisions',
  },
  {
    key: 'Forensic Accounting',
    value: 'Juricomptabilité',
  },
  {
    key: 'Framework Templates',
    value: 'Modèles de cadres',
  },
  {
    key: 'Front-End Web Technologies',
    value: 'Technologies Web frontales',
  },
  {
    key: 'Fundraising',
    value: 'Collecte de fonds',
  },
  {
    key: 'Games Creation',
    value: 'Création de jeux',
  },
  {
    key: 'Garbage Removal',
    value: 'Ramassage des poubelles',
  },
  {
    key: 'Google Ads',
    value: 'Google Ads',
  },
  {
    key: 'Google Analytics',
    value: 'Google Analytics',
  },
  {
    key: 'Google Design Sprint Method',
    value: 'Méthode Design Sprint de Google',
  },
  {
    key: 'Graphic Design',
    value: 'Conception graphique',
  },
  {
    key: 'Greeter',
    value: 'Accueil',
  },
  {
    key: 'Handout Promotional Items',
    value: 'Articles promotionnels',
  },
  {
    key: 'Heavy Equipment Operation',
    value: 'Conduite de matériel lourd',
  },
  {
    key: 'Housekeeping',
    value: 'Entretien ménager',
  },
  {
    key: 'HTML',
    value: 'HTML',
  },
  {
    key: 'HTML/CSS',
    value: 'HTML/CSS',
  },
  {
    key: 'Human Resources',
    value: 'Ressources humaines',
  },
  {
    key: 'IBM Cognos Impromptu',
    value: 'IBM Cognos Impromptu',
  },
  {
    key: 'Illustrator',
    value: 'Illustrator',
  },
  {
    key: 'Immigration',
    value: 'Immigration',
  },
  {
    key: 'Impact Assessment',
    value: 'Évaluation de l’impact',
  },
  {
    key: 'Inclusive Classroom',
    value: 'Classe inclusive',
  },
  {
    key: 'Income Tax Planning',
    value: 'Planification fiscale',
  },
  {
    key: 'InDesign',
    value: 'InDesign',
  },
  {
    key: 'Industrial Mechanism',
    value: 'Mécanisme industriel',
  },
  {
    key: 'Instagram Management',
    value: 'Gestion de compte Instagram',
  },
  {
    key: 'Insurance',
    value: 'Assurance',
  },
  {
    key: 'Interactive Classroom',
    value: 'Salle de classe interactive',
  },
  {
    key: 'Intuit QuickBooks',
    value: 'Intuit QuickBooks',
  },
  {
    key: 'Inventory Management',
    value: 'Gestion des stocks',
  },
  {
    key: 'Investment Management',
    value: 'Gestion d’investissements',
  },
  {
    key: 'InVision',
    value: 'InVision',
  },
  {
    key: 'Invoicing',
    value: 'Facturation',
  },
  {
    key: 'Child Care (skill)',
    value: 'Garde d’enfants (compétence)',
  },
  {
    key: 'Driving (skill)',
    value: 'Conduite (compétence)',
  },
  {
    key: 'IT Support',
    value: 'Soutien informatique',
  },
  {
    key: 'Janitorial Services',
    value: 'Services de nettoyage et d’entretien',
  },
  {
    key: 'Java',
    value: 'Java',
  },
  {
    key: 'JavaScript',
    value: 'Javascript',
  },
  {
    key: 'Jira',
    value: 'Jira',
  },
  {
    key: 'Journalism',
    value: 'Journalisme',
  },
  {
    key: 'Justinmind',
    value: 'Justinmind',
  },
  {
    key: 'Kitchen Assistant',
    value: 'Commis de cuisine',
  },
  {
    key: 'Kitchen Help',
    value: 'Aide de cuisine',
  },
  {
    key: 'Labour',
    value: 'Travail',
  },
  {
    key: 'Labour Relations',
    value: 'Relations de travail',
  },
  {
    key: 'Language Interpretation',
    value: 'Interprétation linguistique',
  },
  {
    key: 'Language Skills (Spoken and/or Written)',
    value: 'Compétences linguistiques (orales ou écrites)',
  },
  {
    key: 'Lead Generation',
    value: 'Génération de pistes',
  },
  {
    key: 'Legal',
    value: 'Droit',
  },
  {
    key: 'Legal Support',
    value: 'Soutien juridique',
  },
  {
    key: 'Lifting',
    value: 'Soulever',
  },
  {
    key: 'Light Duty Cleaning',
    value: 'Nettoyage léger',
  },
  {
    key: 'LinkedIn Management',
    value: 'Gestion de compte LinkedIn',
  },
  {
    key: 'Machine Operation',
    value: 'Fonctionnement de machines',
  },
  {
    key: 'Maintenance',
    value: 'Entretien',
  },
  {
    key: 'Landscaping',
    value: 'Aménagement paysager',
  },
  {
    key: 'Management Accounting',
    value: 'Comptabilité de gestion',
  },
  {
    key: 'MAP Testing',
    value: 'Tests MAP',
  },
  {
    key: 'Market Analysis',
    value: 'Analyse de marché',
  },
  {
    key: 'Market Research',
    value: 'Étude de marché',
  },
  {
    key: 'Marketing',
    value: 'Marketing',
  },
  {
    key: 'Marketing Campaigns',
    value: 'Campagnes marketing',
  },
  {
    key: 'Massage Therapy',
    value: 'Massothérapie',
  },
  {
    key: 'Mechanical Engineering',
    value: 'Génie mécanique',
  },
  {
    key: 'Media Communication',
    value: 'Communication médiatique',
  },
  {
    key: 'Medical Administration',
    value: 'Administration médicale',
  },
  {
    key: 'Meeting Coordination',
    value: 'Coordination de réunions',
  },
  {
    key: 'Meeting Minutes Recording',
    value: 'Enregistrement des procès-verbaux de réunion',
  },
  {
    key: 'Merchandising',
    value: 'Marchandisage',
  },
  {
    key: 'Machine Learning/AI',
    value: 'Apprentissage automatique/IA',
  },
  {
    key: 'Microsoft Excel',
    value: 'Microsoft Excel',
  },
  {
    key: 'Microsoft Office Suite',
    value: 'Suite Microsoft Office',
  },
  {
    key: 'Mopping',
    value: 'Passer la serpillière',
  },
  {
    key: 'NetSuite',
    value: 'NetSuite',
  },
  {
    key: 'Networking',
    value: 'Réseautage',
  },
  {
    key: 'Node',
    value: 'Nœud',
  },
  {
    key: 'Nurse aide',
    value: 'Aide-soignant',
  },
  {
    key: 'Nursing',
    value: 'Soins infirmiers',
  },
  {
    key: 'Object-Oriented Design',
    value: 'Conception orientée objet',
  },
  {
    key: 'Occupational Health and Safety',
    value: 'Santé et sécurité au travail',
  },
  {
    key: 'Office Supply Management',
    value: 'Gestion des fournitures de bureau',
  },
  {
    key: 'Office Support',
    value: 'Soutien du bureau',
  },
  {
    key: 'Oil Changes',
    value: 'Vidange d’huile',
  },
  {
    key: 'Omnigraffle',
    value: 'OmniGraffle',
  },
  {
    key: 'Oracle Business Intelligence',
    value: 'Oracle Business Intelligence',
  },
  {
    key: 'Order Processing',
    value: 'Traitement des commandes',
  },
  {
    key: 'Outlook',
    value: 'Outlook',
  },
  {
    key: 'Packing',
    value: 'Conditionnement',
  },
  {
    key: 'Packaging',
    value: 'Emballage',
  },
  {
    key: 'Paralegal',
    value: 'Services parajuridiques',
  },
  {
    key: 'Patient Services',
    value: 'Services aux patients',
  },
  {
    key: 'Payroll',
    value: 'Paie',
  },
  {
    key: 'Personalized Learning',
    value: 'Apprentissage personnalisé',
  },
  {
    key: 'Phone Skills',
    value: 'Communication téléphonique',
  },
  {
    key: 'Phone Support',
    value: 'Assistance au téléphone',
  },
  {
    key: 'Photo Editing',
    value: 'Retouche photo',
  },
  {
    key: 'Photocopy',
    value: 'Photocopie',
  },
  {
    key: 'Photoshop',
    value: 'Photoshop',
  },
  {
    key: 'PHP',
    value: 'PHP',
  },
  {
    key: 'Physical Labour',
    value: 'Travail physique',
  },
  {
    key: 'Policy Development',
    value: 'Élaboration de politiques',
  },
  {
    key: 'Porter',
    value: 'Porteur',
  },
  {
    key: 'Powerpoint',
    value: 'PowerPoint',
  },
  {
    key: 'Process Development',
    value: 'Élaboration de processus',
  },
  {
    key: 'Process Improvement',
    value: 'Amélioration des processus',
  },
  {
    key: 'Procurement',
    value: 'Approvisionnement',
  },
  {
    key: 'Product Design',
    value: 'Conception de produits',
  },
  {
    key: 'Product Management',
    value: 'Gestion des produits',
  },
  {
    key: 'Production',
    value: 'Production',
  },
  {
    key: 'Program Development',
    value: 'Élaboration de programmes',
  },
  {
    key: 'Programing',
    value: 'Programmation',
  },
  {
    key: 'Project Life Cycle',
    value: 'Cycle de projet',
  },
  {
    key: 'Project Management',
    value: 'Gestion de projet',
  },
  {
    key: 'Project Management Software',
    value: 'Logiciel de gestion de projet',
  },
  {
    key: 'Proofreading',
    value: 'Relecture',
  },
  {
    key: 'Prospecting',
    value: 'Prospection',
  },
  {
    key: 'Python',
    value: 'Python',
  },
  {
    key: 'Quality Assurance',
    value: 'Assurance de la qualité',
  },
  {
    key: 'Quality Control',
    value: 'Contrôle de la qualité',
  },
  {
    key: 'Rails',
    value: 'Rails',
  },
  {
    key: 'React',
    value: 'React',
  },
  {
    key: 'Reading 3D',
    value: 'Lecture 3D',
  },
  {
    key: 'Receiving',
    value: 'Recevoir',
  },
  {
    key: 'Reconciliation',
    value: 'Rapprochement',
  },
  {
    key: 'Recruitment',
    value: 'Recrutement',
  },
  {
    key: 'Recycle Materials',
    value: 'Recyclage des matériaux',
  },
  {
    key: 'Redux',
    value: 'Redux',
  },
  {
    key: 'Relationship Management',
    value: 'Gestion des relations',
  },
  {
    key: 'Release Management',
    value: 'Gestion des versions',
  },
  {
    key: 'Report Generation',
    value: 'Génération de rapport',
  },

  {
    key: 'Research',
    value: 'Recherche',
  },
  {
    key: 'Resolutions',
    value: 'Résolutions',
  },
  {
    key: 'Resource Allocation',
    value: 'Allocation des ressources',
  },
  {
    key: 'Responsive Design',
    value: 'Conception adaptative',
  },
  {
    key: 'Revenue Forecasting',
    value: 'Prévision des recettes',
  },
  {
    key: 'Reviewing Contracts',
    value: 'Examen des contrats',
  },
  {
    key: 'Risk Management',
    value: 'Gestion des risques',
  },
  {
    key: 'Roadmapping',
    value: 'Feuille de route',
  },
  {
    key: 'Ruby',
    value: 'Ruby',
  },
  {
    key: 'Sales',
    value: 'Ventes',
  },
  {
    key: 'Salesforce',
    value: 'Force de vente',
  },
  {
    key: 'Sanitizing',
    value: 'Assainissement',
  },
  {
    key: 'SAS Financial Management',
    value: 'SAS Financial Management',
  },
  {
    key: 'SASS',
    value: 'Sass',
  },
  {
    key: 'Scan Documents',
    value: 'Numérisation de documents',
  },
  {
    key: 'Scheduling & Planning',
    value: 'Ordonnancement et planification',
  },
  {
    key: 'Scope Management',
    value: 'Gestion de la portée',
  },
  {
    key: 'Scrum Management',
    value: 'Gestion Scrum',
  },
  {
    key: 'Search Engine Marketing (SEM)',
    value: 'Marketing des moteurs de recherche',
  },
  {
    key: 'Search Engine Optimization (SEO)',
    value: 'Référencement naturel',
  },
  {
    key: 'Security Compliance',
    value: 'Conformité de sécurité',
  },
  {
    key: 'Security Management',
    value: 'Gestion de la sécurité',
  },
  {
    key: 'Senior Care',
    value: 'Soins aux personnes âgées',
  },
  {
    key: 'Server',
    value: 'Serveur',
  },
  {
    key: 'Server-Side Scripting',
    value: 'Programmation côté serveur',
  },
  {
    key: 'Shift Work',
    value: 'Travail posté',
  },
  {
    key: 'Shipping',
    value: 'Livraison',
  },
  {
    key: 'Social Media',
    value: 'Médias sociaux',
  },
  {
    key: 'Social Media Management',
    value: 'Gestion des médias sociaux',
  },
  {
    key: 'Social Work',
    value: 'Travail social',
  },
  {
    key: 'Social-Emotional Learning',
    value: 'Apprentissage socioémotionnel',
  },
  {
    key: 'Software Development',
    value: 'Développement de logiciels',
  },
  {
    key: 'Software Workflow',
    value: 'Flux de travail logiciel',
  },
  {
    key: 'SQL',
    value: 'SQL',
  },
  {
    key: 'Stakeholder Management',
    value: 'Gestion des parties prenantes',
  },
  {
    key: 'Standing',
    value: 'Debout',
  },
  {
    key: 'Statistical Analysis',
    value: 'Analyse statistique',
  },
  {
    key: 'Stocking',
    value: 'Stockage',
  },
  {
    key: 'Story Mapping',
    value: 'Cartographie des récits',
  },
  {
    key: 'Storytelling',
    value: 'Narration',
  },
  {
    key: 'Supervision',
    value: 'Supervision',
  },
  {
    key: 'Supply Chain',
    value: 'Chaîne d’approvisionnement',
  },
  {
    key: 'Sweeping',
    value: 'Balayage',
  },
  {
    key: 'Tableau',
    value: 'Tableau',
  },
  {
    key: 'Tape Management',
    value: 'Gestion des bandes',
  },
  {
    key: 'Retail (skill)',
    value: 'Commerce de détail (compétence)',
  },
  {
    key: 'Tax Preparation',
    value: 'Préparation de déclarations de revenus',
  },
  {
    key: 'Telecommunication',
    value: 'Télécommunication',
  },
  {
    key: 'Trades',
    value: 'Métiers',
  },
  {
    key: 'Training',
    value: 'Formation',
  },
  {
    key: 'Training Design /Facilitation',
    value: 'Conception/Animation de formations',
  },
  {
    key: 'Troubleshooting',
    value: 'Dépannage',
  },
  {
    key: 'Trucking',
    value: 'Camionnage',
  },
  {
    key: 'TypeScript',
    value: 'TypeScript',
  },
  {
    key: 'Typography',
    value: 'Typographie',
  },
  {
    key: 'User Testing',
    value: 'Tests d’utilisation',
  },
  {
    key: 'Unloading',
    value: 'Déchargement',
  },
  {
    key: 'User Research',
    value: 'Recherche sur les utilisateurs',
  },
  {
    key: 'UX/UI Design',
    value: 'Conception IU/EU',
  },
  {
    key: 'Vendor Management',
    value: 'Gestion des fournisseurs',
  },
  {
    key: 'Version Control',
    value: 'Contrôle des versions',
  },
  {
    key: 'Video Editing',
    value: 'Montage vidéo',
  },
  {
    key: 'Waterfall',
    value: 'Cascade',
  },
  {
    key: 'Wealth Management',
    value: 'Gestion de patrimoine',
  },
  {
    key: 'Wipe Tables',
    value: 'Nettoyage de tables',
  },
  {
    key: 'Wiping',
    value: 'Essuyage',
  },
  {
    key: 'Word',
    value: 'Mot',
  },
  {
    key: 'Animals',
    value: 'Animaux',
  },
  {
    key: 'Art',
    value: 'Art',
  },
  {
    key: 'Automotive',
    value: 'Automobile',
  },
  {
    key: 'Biking',
    value: 'Cyclisme',
  },
  {
    key: 'Camp',
    value: 'Camp',
  },
  {
    key: 'Clothing',
    value: 'Vêtements',
  },
  {
    key: 'Community Services',
    value: 'Services communautaires',
  },
  {
    key: 'Computers',
    value: 'Ordinateurs',
  },
  {
    key: 'Data Management',
    value: 'Gestion de données',
  },
  {
    key: 'Daycare',
    value: 'Garderie',
  },
  {
    key: 'Design',
    value: 'Conception',
  },
  {
    key: 'Electronics',
    value: 'Électronique',
  },
  {
    key: 'Event Services',
    value: 'Services événementiels',
  },
  {
    key: 'Food Retailer',
    value: 'Détaillant alimentaire',
  },
  {
    key: 'Food Services',
    value: 'Services alimentaires',
  },
  {
    key: 'Gardening',
    value: 'Jardinage',
  },
  {
    key: 'Grocery Store',
    value: 'Épicerie',
  },
  {
    key: 'Health Care',
    value: 'Soins de santé',
  },
  {
    key: 'Heavy Equipment',
    value: 'Matériel lourd',
  },
  {
    key: 'Hospitality Services',
    value: 'Services de l’accueil',
  },
  {
    key: 'Information Technology',
    value: 'Informatique',
  },
  {
    key: 'Janitorial',
    value: 'Conciergerie',
  },
  {
    key: 'Landscaping',
    value: 'Aménagement paysager',
  },
  {
    key: 'Legal Services',
    value: 'Services juridiques',
  },
  {
    key: 'Library',
    value: 'Bibliothèque',
  },
  {
    key: 'Manufacturer',
    value: 'Fabricant',
  },
  {
    key: 'Media',
    value: 'Médias',
  },
  {
    key: 'Movies',
    value: 'Films',
  },
  {
    key: 'Music',
    value: 'Musique',
  },
  {
    key: 'Outdoors',
    value: 'Plein air',
  },
  {
    key: 'Painting',
    value: 'Peinture',
  },
  {
    key: 'Park',
    value: 'Parc',
  },
  {
    key: 'Reading',
    value: 'Lecture',
  },
  {
    key: 'Recycling',
    value: 'Recyclage',
  },
  {
    key: 'Security',
    value: 'Sécurité',
  },
  {
    key: 'Senior Facility',
    value: 'Établissement pour personnes âgées',
  },
  {
    key: 'Theatre',
    value: 'Théâtre',
  },
  {
    key: 'Tobacconists',
    value: 'Marchand de bac',
  },
  {
    key: 'Warehouse',
    value: 'Entrepôt',
  },
  {
    key: 'Technology',
    value: 'Technologie',
  },
  {
    key: 'Library Assistance',
    value: 'Aide à la bibliothèque',
  },
  {
    key: 'Carting',
    value: 'Roulage',
  },
  {
    key: 'Membership Management',
    value: 'Gestion des adhésions',
  },
  {
    key: 'Line Cook',
    value: 'Cuisinier à la chaîne',
  },
  {
    key: 'Walking (skill)',
    value: 'Marche (compétence)',
  },
  {
    key: 'Cooking (interest)',
    value: 'Cuisine (intérêt)',
  },
  {
    key: 'Walking (interest)',
    value: 'Marche (intérêt)',
  },
  {
    key: 'Sports (skill)',
    value: 'Sport (compétence)',
  },
  {
    key: 'Sports (interest)',
    value: 'Sport (intérêt)',
  },
  {
    key: 'Standup Comedy',
    value: 'Monologue comique',
  },
  {
    key: 'Accounts Receivable',
    value: 'Comptes débiteurs',
  },
  {
    key: 'Child Care (interest)',
    value: 'Garde d’enfants (intérêt)',
  },
  {
    key: 'Education (skill)',
    value: 'Éducation (compétence)',
  },
  {
    key: 'Writing (skill)',
    value: 'Rédaction (compétence)',
  },
  {
    key: 'Driving (interest)',
    value: 'Conduite (intérêt)',
  },
  {
    key: 'Education (interest)',
    value: 'Éducation (intérêt)',
  },
  {
    key: 'Retail (interest)',
    value: 'Commerce de détail (intérêt)',
  },
  {
    key: 'Writing (interest)',
    value: 'Rédaction (intérêt)',
  },
  {
    key: 'Event Setup',
    value: 'Organisation d’événements',
  },
  {
    key: 'Telemarketing',
    value: 'Télémarketing',
  },
  {
    key: 'Sorting',
    value: 'Tri',
  },
  {
    key: 'Baking (interest)',
    value: 'Boulangerie (intérêt)',
  },
  {
    key: 'Baking (skill)',
    value: 'Boulangerie (compétence)',
  },
  {
    key: 'Transportation',
    value: 'Transport',
  },
  {
    key: 'Teamwork',
    value: 'Travail en équipe',
  },
  {
    key: 'Professional Driving (G License or Equivalent)',
    value: 'Conduite professionnelle (permis G ou équivalent)',
  },
  {
    key: 'Commercial Driving',
    value: 'Conduite commerciale',
  },
  {
    key: 'Microsoft Access',
    value: 'Microsoft Access',
  },
  {
    key: 'Forklift Operation',
    value: 'Conduite de chariot élévateur',
  },
  {
    key: 'Logistics Management',
    value: 'Gestion logistique',
  },
  {
    key: 'Deliveries',
    value: 'Livraisons',
  },
  {
    key: 'Repetitive Movements',
    value: 'Mouvements répétitifs',
  },
  {
    key: 'Advertising',
    value: 'Publicité',
  },
  {
    key: 'Bowling',
    value: 'Jeu de quilles',
  },
  {
    key: 'Dining',
    value: 'Restauration',
  },
  {
    key: 'Taekwondo',
    value: 'Taekwondo',
  },
  {
    key: 'Gym',
    value: 'Sport',
  },
  {
    key: 'Dog Walking',
    value: 'Promenade de chien',
  },
  {
    key: 'Animal Care',
    value: 'Soins aux animaux',
  },
  {
    key: 'Mascot',
    value: 'Mascotte',
  },
  {
    key: 'Bike Repair',
    value: 'Réparation de vélo',
  },
  {
    key: 'reportsTitle',
    value: 'Données',
  },
  {
    key: 'viewJobMatches',
    value: 'Afficher les postes pouvant correspondre',
  },
  {
    key: 'viewEmployerMatches',
    value: 'Afficher les employeurs pouvant correspondre',
  },
  {
    key: 'thatAre',
    value: ' ',
  },
  {
    key: 'by',
    value: 'par',
  },
  {
    key: 'global',
    value: 'globaux',
  },
  {
    key: 'myOrg',
    value: 'Mon Org',
  },
  {
    key: 'topRanked',
    value: 'Classement des compétences',
  },
  {
    key: 'topRankedSkillsAndInterests',
    value: 'Classement des compétences et des intérêts',
  },
  {
    key: 'rankedJobKeywords',
    value: 'Mots-clés associés aux postes les mieux classés',
  },
  {
    key: 'IndProfileTab',
    value: 'Profil de compétences et d’intérêts',
  },
  {
    key: 'JobProfileTab',
    value: 'Profil de mots-clés',
  },
  {
    key: 'IndPieTab',
    value: 'Rapport sur la personne',
  },
  {
    key: 'indStatus',
    value: 'Statut personne',
  },
  {
    key: 'reportTitleI',
    value:
      'Consultez les 10 compétences ou intérêts les plus courants chez les personnes (relevant de votre organisation) pour déterminer les types de postes, d’entreprises et d’industries que votre organisation pourrait cibler. Basculez entre les personnes « en recherche d’emploi », les personnes qui ne sont « pas en recherche d’emploi » et « toutes les personnes ».',
  },
  {
    key: 'reportTitleII',
    value:
      'Consultez les 10 mots-clés les plus courants associés aux postes. Basculez entre les « postes ouverts », les « postes fermés » et « tous les postes ».',
  },
  {
    key: 'reportTitleIII',
    value:
      'Ce rapport dresse la liste des personnes (relevant de votre organisation) dont le profil pourrait inclure un plus grand nombre de compétences ou d’intérêts (afin d’améliorer les correspondances).',
  },
  {
    key: 'reportTitleIV',
    value:
      'Ce rapport dresse la liste des postes dont la description pourrait inclure un plus grand nombre de compétences ou d’intérêts (afin d’améliorer les correspondances).',
  },
  {
    key: 'reportTitleV',
    value:
      'Ce rapport dresse la liste des personnes (relevant de votre organisation) qui sont « en recherche d’emploi » et de celles qui ne sont « pas en recherche d’emploi » et indique le nombre d’heures qu’elles travaillent actuellement et qu’elles souhaitent travailler.',
  },
  {
    key: 'jobKeywords',
    value: 'Mots-clés associés aux postes',
  },
  {
    key: 'indDistributionTitle',
    value: 'Personnes regroupées en fonction de toutes les compétences',
  },
  {
    key: 'indDistributionTitleII',
    value: 'Personnes regroupées en fonction de toutes intérêts',
  },
  {
    key: 'jobDistributionTitle',
    value: 'Postes regroupés en fonction de tous les mots-clés',
  },
  {
    key: 'indDistributionSubtitle',
    value: 'Personnes en recherche d’emploi et personnes qui ne recherchent pas d’emploi',
  },
  {
    key: 'noGroupingsMessage',
    value: 'Aucun regroupement trouvé pour cette étiquette',
  },
  {
    key: 'noDataMessage',
    value: 'Aucune donnée',
  },
  {
    key: 'submitRequest',
    value: 'Envoyer la demande',
  },
  {
    key: 'submitKeywordMsg',
    value: 'N’oubliez pas de cliquer sur le bouton Envoyer la demande ci-dessous!',
  },
  {
    key: 'submitPromptI',
    value: 'Vous ne trouvez pas',
  },
  {
    key: 'submitPromptII',
    value: 'que vous cherchez? Envoyez une demande en cliquant ici.',
  },
  {
    key: 'overview',
    value: 'Overview & Description',
  },
  {
    key: 'button-back',
    value: 'Retour',
  },
  {
    key: 'my-clients',
    value: 'My Clients',
  },
  {
    key: 'no-conn-id',
    value: 'No search results',
  },
  {
    key: 'no-apply',
    value: 'No active applications',
  },
  {
    key: 'no-like',
    value: 'No liked jobs',
  },
  {
    key: 'activity-feed',
    value: 'Engagement',
  },
  {
    key: 'jobs-liked',
    value: 'Jobs Liked',
  },
  /////////// B2C
  {
    key: 'changeStatusMessage-1',
    value: 'Changing an application status to',
  },
  {
    key: 'changeStatusMessage-2',
    value: 'Offre d’emploi',
  },
  {
    key: 'changeStatusMessage-3',
    value: 'will notify all individuals who have started an application that they are',
  },
  {
    key: 'changeStatusMessage-4',
    value: 'Non concordant',
  },
  {
    key: 'changeStatusMessage-5',
    value: 'and remove the job posting from their Active Applications list.',
  },
  {
    key: 'panelForms-continue',
    value: 'Continuer',
  },
  {
    key: 'popupTitle-areYouSure',
    value: 'Are you sure?',
  },
  {
    key: 'selectRegisterOption-referenceCode',
    value: 'Code de Référence',
  },
  {
    key: 'db-profileCompletionTitle',
    value: 'Profil créé',
  },
  {
    key: 'qt-organization',
    value: 'Organization',
  },
  {
    key: 'jobs-applicationsStarted',
    value: 'Applications Started',
  },
  {
    key: 'jobs-applications',
    value: 'Applications',
  },
  {
    key: 'connected-ind',
    value: 'Active Applications',
  },
  {
    key: 'db-jobInterestHours',
    value: 'Heures à renseigner: ',
  },
  {
    key: 'button-back',
    value: 'Back',
  },
  {
    key: 'Troubleshooting (interest)',
    value: 'Dépannage (interest)',
  },
  {
    key: 'Teamwork (skill)',
    value: 'Travail en équipe (skill)',
  },
  {
    key: 'theirProfile',
    value: 'their profile',
  },
  {
    key: 'weeklySchedule',
    value: 'Please let us know your work schedule.',
  },
  {
    key: 'employmentRecord-weekly-schedule-label',
    value: 'Horaire hebdomadaire',
  },
  {
    key: 'myactivity-removeApplied',
    value: 'removed their application for',
  },
  {
    key: 'myactivity-archive',
    value: 'archived their successful application for',
  },
];
