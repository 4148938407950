import { Typography } from '@mui/material';
import Icon from '../../asssets/icons';
import Card from '../UI/Card/CardComponent';
import { WidgetContainer, WidgetText } from '../UI/StyledComponents/DashboardStyledComponents';
import useDetectResize from '../../hooks/useDetectResize';

interface TopWidgetProps {
  title: string;
  count: number;
  IconImg: string;
  isMobile?: boolean;
  subTitle?: string;
}

const TopWidgetComponent = (props: TopWidgetProps) => {
  const { isTablet } = useDetectResize();
  const { title, count, IconImg, isMobile, subTitle } = props;

  return (
    <>
      {isMobile ? (
        <WidgetContainer>
          <div className="top-widget-metric">
            <WidgetText variant={isTablet ? 'h3' : 'h2'} fontWeight="400" lineHeight={1} noWrap>
              {title}
            </WidgetText>
            <WidgetText variant="h6" lineHeight={1.5} noWrap>
              {subTitle}
            </WidgetText>
            <WidgetText
              variant="h1"
              fontWeight="600"
              lineHeight={isTablet ? 1.8 : 0.8}
              sx={{
                marginTop: '5px',
                fontSize: isTablet ? '38px !important' : '32px !important',
              }}
            >
              {count}
            </WidgetText>
          </div>
        </WidgetContainer>
      ) : (
        <Card className="dash-top-level-cards inline">
          <div className="top-widget-metric">
            <Typography color={'#ffffff'} variant="h2">
              {title}
            </Typography>
            <Typography color={'#ffffff'} fontSize={'30px!important'} variant="h1">
              {count}{' '}
            </Typography>
          </div>
          <div className="top-widget-icon">
            <img src={IconImg} alt="X" />
          </div>
        </Card>
      )}
    </>
  );
};

export default TopWidgetComponent;
