import React, { ReactNode, useEffect, useState } from 'react';
//import logo from './logo.svg'; //set logo
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from './components/typescript/hooks';
import {
  fetchProviders,
  getMyProviderDetails,
  getProviderStatus,
  setProviderDetails,
} from './store/providerSlice';
import { getEmployerStatus, fetchEmployerMetrics, setAllEmployers } from './store/employerSlice';
import { useSelector } from 'react-redux';
import { fetchJobs, getJobStatus, setJobList } from './store/jobSlice';
import {
  fetchIndividuals,
  getIndividualStatus,
  setIndividualB2CList,
} from './store/individualSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  fetchNotifications,
  notificationListenerConnected,
  startConnecting,
} from './store/notificationSlice';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { getAccessToken, setCredentials, tokenGranted } from './store/authSlice';
import HeaderComponent from './components/HeaderComponent';
import NavigationPlaceholderComponent from './components/NavigationComponent/NavigationPlaceholderComponent';
import { getTagStatus, thunkFetchTags } from './store/keywordSlice';
import useInactiveTimer from './hooks/useInactiveTimer';
import InactiveComponent from './components/UI/Popup/InactiveComponent';
import { Employer, Job, Provider } from './components/typescript/types';
import useDetectResize from './hooks/useDetectResize';
import {
  AppContainer,
  MainDisplayModal,
} from './components/UI/StyledComponents/GlobalStyledComponents';
import MobileNavListComponent from './components/NavigationComponent/MobileNavListComponent';
import MobileDashboard from './components/DashboardComponents/MobileTablet/MobileDashboard';
import { MobilePageTitleComponent } from './components/PageTitleComponent';
import { MobileNavBackDrop } from './components/UI/StyledComponents/NavigationStyledComponents';
import Modal from './components/UI/Popup/BackdropComponent';
import ActivityFeedComponent from './pages/ActivityFeedComponent';
import { sec } from './store/services';

const DashboardComponent = withAuthenticationRequired(
  React.lazy(() => import('./pages/DashboardComponent'))
);
const EmployersComponent = withAuthenticationRequired(
  React.lazy(() => import('./pages/EmployersComponent'))
);
const JobsComponent = withAuthenticationRequired(React.lazy(() => import('./pages/JobsComponent')));
const IndividualsComponent = withAuthenticationRequired(
  React.lazy(() => import('./pages/IndividualsComponent'))
);
const InvitesComponent = withAuthenticationRequired(
  React.lazy(() => import('./pages/InvitesComponent'))
);
const ProfileComponent = withAuthenticationRequired(
  React.lazy(() => import('./pages/ProfileComponent'))
);
const LandingPageComponent = React.lazy(() => import('./pages/LandingPageComponent'));
const NavigationComponent = withAuthenticationRequired(
  React.lazy(() => import('./components/NavigationComponent/NavigationComponent'))
);
const MobileTabletNavigationComponent = withAuthenticationRequired(
  React.lazy(() => import('./components/NavigationComponent/MobileNavigationComponent'))
);
const ReportingComponent = React.lazy(() => import('./pages/ReportingComponent'));

//check for service
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js').then(function () {
    console.log('service worker registered!');
  });
}

function App() {
  const dispatch = useAppDispatch();

  //to keep other organization's private, you should only fetch specific data of a provider outside of your organization.
  const employerStatus = useSelector(getEmployerStatus);
  const jobStatus = useSelector(getJobStatus);
  const providerStatus = useSelector(getProviderStatus);
  const individualStatus = useSelector(getIndividualStatus);
  const webSocketConnected = useSelector(notificationListenerConnected);
  const keywordStatus = useSelector(getTagStatus);
  const token = useSelector(getAccessToken);
  const tokenActive = useSelector(tokenGranted);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const { windowDimensions, isMobile, isTablet, isLaptop, isDesktop } = useDetectResize();
  const navigate = useNavigate();
  const location = useLocation();
  const myProviderDetails = useSelector(getMyProviderDetails);
  const [greeting, setGreeting] = useState<string | ReactNode>('Employer');
  const [loadOverlay, setLoadOverlay] = useState<boolean>(false);
  const { userInactive, setUserInactive } = useInactiveTimer(15, myProviderDetails);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isLandingPage, setIsLandingPage] = useState<boolean>(location.pathname === '/');

  sec.setAccessTokenSilently(getAccessTokenSilently);

  //Grab all data upon login.
  useEffect(() => {
    //console.info('APP TSX', user)
    if ((token === undefined || token === null) && user) {
      getAccessTokenSilently().then((accessToken: string) => {
        dispatch(setCredentials({ user: user, token: accessToken }));
      });
    }

    //user is logged in
    if (!isLoading && user && tokenActive) {
      // wait for user object, then pass org_id & email to fetchProviders.
      if (location.pathname === '/') navigate('/dashboard');

      if (keywordStatus === 'idle') {
        dispatch(thunkFetchTags({ token: token! })).then(() => {
          //should decouple this to its own conditional.
          console.log('Retrieved Tags');
        });
      }

      if (providerStatus === 'idle') {
        dispatch(fetchProviders({ orgId: user.org_id!, email: user.email!, token: token! }))
          .then(unwrapResult)
          .then(
            (payload: { data: { prov: Provider[]; emp: Employer[]; job: Job[]; b2c: any[] } }) => {
              let myProviderDetails = payload.data.prov.find(
                (provider: any) => provider.email === user.email!
              );
              window.dataLayer!.push({
                event: 'login',
                userId:
                  myProviderDetails!.id.slice(0, 8) + '000' + myProviderDetails!.id.slice(-5, -1),
              });

              dispatch(setProviderDetails(myProviderDetails));
              dispatch(fetchIndividuals({ orgId: user.org_id, token: token! }));
              dispatch(setAllEmployers(payload.data.emp));
              dispatch(setJobList(payload.data.job));
              dispatch(setIndividualB2CList(payload.data.b2c));
            }
          )
          .catch((e) => {
            console.log(e);
          });
      }

      if (employerStatus === 'idle' && providerStatus === 'finished-loading') {
        console.log('retrieve metrics!');
        dispatch(fetchEmployerMetrics({ orgId: user.org_id, token: token! }))
          .then(unwrapResult)
          .then((employers) => {
            console.info('Retrieved Employers JSON', employers.data);
          });
      }

      if (jobStatus === 'idle' && providerStatus === 'finished-loading') {
        //null orgId, later possibly have settings to grab based off organization/globally.
        dispatch(fetchJobs({ orgId: myProviderDetails!.orgId, token: token! }));
      }

      //console.info('MyProvider: ', myProviderDetails);
      if (!webSocketConnected && myProviderDetails) {
        dispatch(startConnecting());
        dispatch(fetchNotifications({ providerId: myProviderDetails.id, token: token! }));
      }
    }
  }, [
    employerStatus,
    dispatch,
    jobStatus,
    individualStatus,
    providerStatus,
    webSocketConnected,
    isLoading,
    user,
    getAccessTokenSilently,
    token,
    tokenActive,
    navigate,
  ]);

  useEffect(() => {
    windowDimensions.w < 1160 ? setIsCollapsed(true) : setIsCollapsed(false);
  }, [windowDimensions.w]);

  useEffect(() => {
    let dynamicGreeting =
      location.pathname.startsWith('/employers') && location.pathname.endsWith('edit')
        ? 'Edit Employer'
        : location.pathname === '/employers/create'
          ? 'Add Employer'
          : location.pathname === '/employers'
            ? 'Employers'
            : '';

    setGreeting(dynamicGreeting);

    setIsLandingPage((currState) => (currState = location.pathname === '/'));
  }, [location]);

  useEffect(() => {
    if (isLoading && location.pathname === '/dashboard') {
      setLoadOverlay(true);
      let loadScreen = setTimeout(() => {
        setLoadOverlay(false);
      }, 2500);

      return () => {
        clearTimeout(loadScreen);
      };
    }
  }, [loadOverlay, isLoading]);

  return (
    <AppContainer display={isMobile || isTablet ? 'block' : 'inline-flex'}>
      {loadOverlay && isLoading && <NavigationPlaceholderComponent />}

      {isAuthenticated && (
        <React.Suspense fallback={<NavigationPlaceholderComponent />}>
          {isDesktop || isLaptop ? (
            <NavigationComponent isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          ) : (
            <>
              <MobileTabletNavigationComponent
                isNavActive={!isCollapsed}
                displayNav={setIsCollapsed}
              />

              <MobilePageTitleComponent isCollapsed={isCollapsed} />
            </>
          )}
        </React.Suspense>
      )}
      <MainDisplayModal
        className={isAuthenticated ? 'main-display-modal card' : 'main-display-modal landing-page'}
        height={
          isLandingPage
            ? windowDimensions.h / 0.8
            : isMobile || isTablet
              ? windowDimensions.h
              : 'inherit'
        }
        backgroundColor={
          !isCollapsed && (isMobile || isTablet) ? '#E4F2F6' : isLandingPage ? '#E4F2F6' : 'white'
        }
        overflow={isCollapsed && (isMobile || isTablet) ? 'scroll' : 'hidden'}
        isMobile={isMobile}
        isTablet={isTablet}
      >
        {isAuthenticated && (
          <div
            className="inline-btwn full-width"
            style={{ display: isMobile || isTablet ? 'none' : 'inline-flex' }}
          >
            {(isDesktop || isLaptop) && <HeaderComponent children={greeting} />}
            {userInactive && (
              <InactiveComponent
                onAcknowledged={() => {
                  setUserInactive((currState) => (currState = false));
                }}
              />
            )}
          </div>
        )}

        <Routes>
          <Route
            path="employers/*"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                <EmployersComponent isNavCollapsed={isCollapsed} />
              </React.Suspense>
            }
          />
          <Route
            path="/jobs/*"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                <JobsComponent isNavCollapsed={isCollapsed} />
              </React.Suspense>
            }
          />
          <Route
            path="/individuals/*"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                <IndividualsComponent isNavCollapsed={isCollapsed} />
              </React.Suspense>
            }
          />
          <Route
            path="/invite"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                <InvitesComponent />
              </React.Suspense>
            }
          />
          <Route
            path="/insights"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                <ReportingComponent />
              </React.Suspense>
            }
          />
          <Route
            path="/activity"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                <ActivityFeedComponent />
              </React.Suspense>
            }
          />
          <Route
            path="/activity/:leadProviderOrgId/:individualId"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                <ActivityFeedComponent />
              </React.Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                <ProfileComponent />
              </React.Suspense>
            }
          />
          <Route
            path="/dashboard"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                {isDesktop || isLaptop ? (
                  <DashboardComponent />
                ) : (
                  <MobileDashboard navigationOpen={!isCollapsed} />
                )}
              </React.Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                {location.pathname !== '/dashboard' && <LandingPageComponent />}
              </React.Suspense>
            }
          />
          <Route
            path="/"
            element={
              <React.Suspense fallback={<NavigationPlaceholderComponent />}>
                {location.pathname !== '/dashboard' && <LandingPageComponent />}
              </React.Suspense>
            }
          />
        </Routes>
        {!isCollapsed && (isMobile || isTablet) && (
          <Modal onHide={() => [setIsCollapsed(true)]} hideBackdrop={true}>
            <MobileNavBackDrop>
              <MobileNavListComponent hideMenu={() => setIsCollapsed(true)} />
            </MobileNavBackDrop>
          </Modal>
        )}
      </MainDisplayModal>
    </AppContainer>
  );
}

export default App;
