import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '.';
import { Organization, Provider } from '../components/typescript/types';

export interface ProviderSlice {
  allProviders: Provider[];
  allOrganizations: Organization[];
  myProvider: Provider;
  status: string;
  error: string | null;
}

export const fetchProviders = createAsyncThunk(
  'providers/fetchProviders',
  async (params: { orgId: string; email: string; token: string }) => {
    return axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/providers/${params.orgId}`, {
        headers: {
          Authorization: `Bearer ${params.token!}`,
        },
        params: {
          email: params.email,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const postProviderInvite = createAsyncThunk(
  'providers/postInvite',
  async (params: { data: any; token: string }) => {
    return axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/providers/invite`, params.data, {
        headers: {
          Authorization: `Bearer ${params.token!}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

export const updateProviderInfo = createAsyncThunk(
  'providers/postUpdatedInfo',
  async (params: { data: any; token: string }) => {
    return axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/v1/providers/update`, params.data, {
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.message;
      });
  }
);

const initialState: ProviderSlice = {
  allProviders: [],
  allOrganizations: [],
  myProvider: null as unknown as Provider,
  status: 'idle',
  error: null,
};

const providerSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    setProviderDetails: (state, action) => {
      state.myProvider = { ...action.payload };
      //  console.info('MOCK USER(Provider) details:', state.myProvider);
      state.status = 'finished-loading';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProviders.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log(action.payload);
        const loadedProviders = action.payload.data.prov;
        const organizationsList = action.payload.data.org;

        // console.info('Providers loaded: ', loadedProviders);
        // console.info('Orgs loaded: ', organizationsList);

        state.allProviders = loadedProviders;
        state.allOrganizations = organizationsList;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message! || 'error provider slice fetch';
      })
      .addCase(postProviderInvite.fulfilled, (state, action) => {
        console.info('Invite: ', action.payload);
        //Should addd a invite-status or check to show all users invited into the system.
        //  return action.payload;
      })
      .addCase(updateProviderInfo.fulfilled, (state, action) => {
        console.info('update info', action.payload);
        state.myProvider = action.payload.provider[0];
      });
  },
});

export const { setProviderDetails } = providerSlice.actions;
export const getMyProviderDetails = (state: RootState) => state.providers.myProvider;
export const getProvidersInOrganization = (state: RootState) => state.providers.allProviders;
export const getProviderById = (state: RootState, providerId?: string) =>
  state.providers.allProviders.find((provider) => provider.id === providerId);
export const getAuthorizedProviders = (state: RootState) =>
  state.providers.allProviders.filter((provider) => provider.userType === 'PROVIDER');

export const getProviderStatus = (state: RootState) => state.providers.status;
export const getProviderError = (state: RootState) => state.providers.error;
export const getOrgDetails = (state: RootState, id: string) =>
  state.providers.allOrganizations.find((org) => org.orgId === id);

export default providerSlice.reducer;
