import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getEmployers, getEmployerStatus, getIEMData } from '../../store/employerSlice';
import { getAllJobs, getIJMData, getJobStatus } from '../../store/jobSlice';
import CompanyDetailsComponent from '../CompanyDetailsComponent';
import { Employer, EmployerMetrics, Individual, Job, JobMetrics } from '../typescript/types';
import RecommendationFilterOptions from './RecommendationFilterOptions';
import PuffLoader from '../UI/Animation/PuffLoader';
import { calculateAvailableHours, getFullName } from '../../helpers/helperFunctions';
import { getAllIndividuals, getIndividualStatus } from '../../store/individualSlice';
import EmploymentSupportLeadDetails from './EmploymentSupportLeadDetails';
import { getProvidersInOrganization } from '../../store/providerSlice';
import RecommendationDetails from './RecommendationOverviewComponent';
import RecommendationShowTagMatches from './RecommendationShowTagMatches';
import { goBackReccStyles, recommenderToggleStyling } from '../../helpers/styling';
import { Button, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

import dayjs from 'dayjs';
import useRedirectedState from '../../hooks/useRedirectedState';
import useTranslate from '../../hooks/useTranslate';
import { AnimationOverlay, AnimationWrapper } from '../UI/StyledComponents/GlobalStyledComponents';
import useDetectResize from '../../hooks/useDetectResize';

const ListOfRecommendationsComponent = React.lazy(() => import('./ListOfRecommendationsComponent'));

export interface ContactInfo {
  img: string;
  name: string;
  email: string;
  phone: string;
  photoUrl: string;
  orgId?: string;
}
const RecommendationsComponent = () => {
  const { searchState, userType } = useParams();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const t = useTranslate();

  const { redirectedState } = useRedirectedState(pathname.split('/')[3]);

  const [searchBy, setSearchBy] = useState<string>(searchState!);
  const [itemsToDisplay, setItemsToDisplay] = useState<Employer[] | Job[] | Individual[]>([]);
  const allJobs = useSelector(getAllJobs);
  const allEmployers = useSelector(getEmployers);
  const allIndividuals = useSelector(getAllIndividuals);
  const allProviders = useSelector(getProvidersInOrganization);
  const IJM = useSelector(getIJMData);
  const IEM = useSelector(getIEMData);

  const [displayAnimation, setDisplayAnimation] = useState<boolean>(true);
  const isIndividual = userType?.startsWith('job') || userType?.startsWith('employer'); //Looking to match individuals to Jobs or Employers.

  const [baseObject, setBaseObject] = useState<any>(
    isIndividual
      ? (state as unknown as Individual) || redirectedState
      : userType === 'individualsToEmployer'
      ? (state as unknown as Employer)
      : (state as unknown as Job) || redirectedState
  );
  const [selectedItem, setSelectedItem] = useState<Job | Employer | Individual | null>(null);
  const [leadProvider, setLeadProvider] = useState<ContactInfo | null>(null);
  const [detailsDistance, setDetailsDistance] = useState<number>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [listCompiled, setListCompiled] = useState<boolean>(false);
  const [baseCoordinates, setBaseCoordinates] = useState<{ long: number; lat: number }>({
    long: 0,
    lat: 0,
  });
  const currentLocation = useLocation();
  const [reload, setReloaded] = useState<boolean>(false);
  const [updateTrigger, setUpdateTrigger] = useState<boolean>(false);
  const { windowDimensions } = useDetectResize();

  console.info('BaseObject | userType | searchState:', baseObject, userType, searchState);
  // console.info('BaseObject long long: ', baseObject.longitude + ' lat: ' + baseObject.latitude);
  // console.info('lead provider: ', leadProvider);

  useEffect(() => {
    console.log('Render #1');
    getListItems();
    setBaseCoordinates({ long: baseObject?.longitude, lat: baseObject?.latitude });
    // setDisplayAnimation(false);
  }, [baseObject]);

  useEffect(() => {
    console.log('Render #2');
    if (isIndividual) {
      console.log(pathname.split('/')[3]);
      const base = allIndividuals.find((individual) => pathname.split('/')[3] === individual.id);
      setBaseObject((currState: any) => {
        return (currState = base);
      });
    } else {
      if (userType === 'individualsToEmployer') {
        const base = allEmployers.find((employer) => state.id === employer.id);
        setBaseObject((currState: any) => {
          return (currState = base);
        });
      } else {
        const base = allJobs.find((job) => pathname.split('/')[3] === job.id);
        console.log(base);
        setBaseObject((currState: any) => {
          return (currState = base);
        });
      }
    }
    setUpdateTrigger((currState) => {
      return !currState;
    }); //used to update metrics real time given a change is made and processed by middleware.
    getListItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IJM, IEM, allIndividuals, userType, allEmployers, allJobs, redirectedState]);

  useEffect(() => {
    console.log('Render #3');
    if (reload) {
      setItemsToDisplay([]);
      getListItems();
      setReloaded(false);
      setBaseCoordinates({ long: baseObject?.longitude, lat: baseObject?.latitude });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, itemsToDisplay]);

  useEffect(() => {
    getListItems();
    setDisplayAnimation(true);
    setIsVisible(false);
    let timer = setTimeout(() => {
      setDisplayAnimation(false);
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy]);

  function getListItems() {
    let filteredResults: any;
    if (userType === 'employer') {
      if (searchBy === 'location') {
        filteredResults = getByLocation(allEmployers);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
      if (searchBy === 'cumulative') {
        filteredResults = getByCumulative(allEmployers);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
      if (searchBy === 'aligned') {
        filteredResults = getByAligned(allEmployers);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
    }

    if (userType?.startsWith('job')) {
      if (searchBy === 'location') {
        filteredResults = getByLocation(allJobs);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
      if (searchBy === 'cumulative') {
        filteredResults = getByCumulative(allJobs);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
      if (searchBy === 'aligned') {
        filteredResults = getByAligned(allJobs);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
    }

    if (userType === 'individualsToEmployer' || userType?.startsWith('individualsToJob')) {
      if (searchBy === 'location') {
        filteredResults = getByLocation(allIndividuals);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
      if (searchBy === 'cumulative') {
        filteredResults = getByCumulative(allIndividuals);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
      if (searchBy === 'aligned') {
        filteredResults = getByAligned(allIndividuals);
        setItemsToDisplay((objectState) => {
          return (objectState = [...filteredResults]);
        });
      }
    }
  }

  //returns your final sorted list with metrics data appended to it.
  function getByLocation(allEmployersJobsOrIndividuals: Employer[] | Job[] | Individual[]) {
    let filteredMetrics = getFilteredMetrics(allEmployersJobsOrIndividuals[0]!);
    let sortedMetrics = byLocation(allEmployersJobsOrIndividuals[0], filteredMetrics);

    let sortedResults = !isIndividual
      ? createListItemsOfMultipleIndividuals(sortedMetrics as any)
      : allEmployersJobsOrIndividuals[0]?.hasOwnProperty('isJobActive')
      ? createMultipleJobListItems(sortedMetrics as JobMetrics[])
      : createMultipleEmployerListItems(sortedMetrics as EmployerMetrics[]);

    return sortedResults;
  }

  //returns your final sorted list with metrics data appended to it.
  function getByCumulative(allEmployersJobsOrIndividuals: Employer[] | Job[] | Individual[]) {
    let filteredMetrics = getFilteredMetrics(allEmployersJobsOrIndividuals[0]!);

    let sortedMetrics = byTotal(allEmployersJobsOrIndividuals[0], filteredMetrics);

    let sortedResults = !isIndividual
      ? createListItemsOfMultipleIndividuals(sortedMetrics as any)
      : allEmployersJobsOrIndividuals[0]?.hasOwnProperty('isJobActive')
      ? createMultipleJobListItems(sortedMetrics as JobMetrics[])
      : createMultipleEmployerListItems(sortedMetrics as EmployerMetrics[]);

    return sortedResults;
  }

  //returns your final sorted list with metrics data appended to it.
  function getByAligned(allEmployersJobsOrIndividuals: Employer[] | Job[] | Individual[]) {
    let filteredMetrics = getFilteredMetrics(allEmployersJobsOrIndividuals[0]!);

    let sortedMetrics = byScore(allEmployersJobsOrIndividuals[0], filteredMetrics);

    let sortedResults = !isIndividual
      ? createListItemsOfMultipleIndividuals(sortedMetrics as any)
      : allEmployersJobsOrIndividuals[0]?.hasOwnProperty('isJobActive')
      ? createMultipleJobListItems(sortedMetrics as JobMetrics[])
      : createMultipleEmployerListItems(sortedMetrics as EmployerMetrics[]);

    return sortedResults;
  }

  const handleFilterClick = (queryType: string) => {
    setSearchBy((state: string) => {
      return (state = queryType);
    });
  };

  const handleItemChange = (itemId: string) => {
    let itemObject: Individual[] | Employer[] | Job[];

    if (userType?.startsWith('job')) {
      itemObject = allJobs.filter((jobs) => jobs?.id === itemId)!;
      setSelectedItem((currState) => {
        return (currState = itemObject[0] as Job);
      });
      let provider = getContactInfo(itemObject[0]);
      setLeadProvider((currState) => {
        return (currState = provider);
      });
    } else if (userType === 'employer') {
      itemObject = allEmployers.slice().filter((employer) => employer?.id === itemId)!;
      setSelectedItem((currState) => {
        return (currState = itemObject[0] as Employer);
      });
      let provider = getContactInfo(itemObject[0]);
      setLeadProvider((currState) => {
        return (currState = provider);
      });
    } else if (userType?.startsWith('individualsToJob')) {
      itemObject = allIndividuals.slice().filter((individual) => individual?.id === itemId)!;
      setSelectedItem((currState) => {
        return (currState = itemObject[0] as Individual);
      });
      let provider = getContactInfo(itemObject[0]);
      setLeadProvider((currState) => {
        return (currState = provider);
      });
    } else if (userType === 'individualsToEmployer') {
      itemObject = allIndividuals.slice().filter((individual) => individual?.id === itemId)!;
      setSelectedItem((currState) => {
        return (currState = itemObject[0] as Individual);
      });
      let provider = getContactInfo(itemObject[0]);
      setLeadProvider((currState) => {
        return (currState = provider);
      });
    } else {
      console.warn('Error: could not find the correct userType');
    }
  };

  const toggleRecommender = () => {
    let formattedUrl = currentLocation.pathname.split('/');
    let appendRoute = userType === 'employer' ? 'job' : 'employer';
    formattedUrl.pop();
    formattedUrl.push(appendRoute);
    const finalUrl = formattedUrl.join('/');
    setReloaded((currState) => (currState = true));
    navigate(`${finalUrl}`, { state: baseObject });
  };

  const handleSelectedDistance = (distance: number) => {
    setDetailsDistance((currState) => (currState = distance));
  };

  const getContactInfo = (itemObject: Individual | Employer | Job): ContactInfo => {
    let obj: ContactInfo = { img: '', name: '', email: '', phone: '', photoUrl: '', orgId: '' };
    if (userType?.startsWith('job')) {
      let job = itemObject as Job;
      obj.img = job.providerId;
      obj.name = job.providerName;
      obj.email = job.providerEmail;
      obj.phone = job.providerPhone;
      obj.orgId = job.providerOrgId;
      return obj;
    } else if (userType === 'employer') {
      let employer = itemObject as Employer;
      let provider = allProviders.find((provider) => provider?.id === employer.leadProviderId)!;
      obj.img = 'defaultProviderLogo.png';
      obj.name = getFullName(provider);
      obj.email = provider.email;
      obj.phone = provider.phone;
      return obj;
    } else {
      let individual = itemObject as Individual;
      let provider = allProviders.find((provider) => provider?.id === individual.leadProviderId)!;
      obj.img = 'defaultProviderLogo.png';
      obj.name = getFullName(provider);
      obj.email = provider.email;
      obj.phone = provider.phone;
      obj.photoUrl = provider.photoUrl;
      return obj;
    }
  };

  function getFilteredMetrics(mainObject: Employer | Job | Individual) {
    if (isIndividual) {
      return mainObject?.hasOwnProperty('isJobActive')
        ? IJM?.filter((metric) => metric.ijmIndividual === (baseObject as Individual)?.id)
        : IEM?.filter((metric) => metric.iemIndividual === (baseObject as Individual)?.id);
    } else {
      if (userType?.startsWith('individualsToJob')) {
        return IJM.filter((metric) => metric.ijmJob === (baseObject as Job)?.id);
      } else {
        return IEM.filter((metric) => metric.iemEmployer === (baseObject as Employer)?.id);
      }
    }
  }

  function byScore(
    mainObject: Employer | Job | Individual,
    filteredMetrics: JobMetrics[] | EmployerMetrics[]
  ) {
    if (isIndividual) {
      return mainObject?.hasOwnProperty('isJobActive')
        ? (filteredMetrics as JobMetrics[]).slice().sort((a, b) => b.ijmScore - a.ijmScore)
        : (filteredMetrics as EmployerMetrics[]).slice().sort((a, b) => b.iemScore! - a.iemScore!);
    } else {
      if (userType?.startsWith('individualsToJob')) {
        return (filteredMetrics as JobMetrics[]).slice().sort((a, b) => b.ijmScore - a.ijmScore);
      } else {
        return (filteredMetrics as EmployerMetrics[])
          .slice()
          .sort((a, b) => b.iemScore! - a.iemScore!);
      }
    }
  }
  function byTotal(
    mainObject: Employer | Job | Individual,
    filteredMetrics: JobMetrics[] | EmployerMetrics[]
  ) {
    if (isIndividual) {
      return mainObject?.hasOwnProperty('isJobActive')
        ? (filteredMetrics as JobMetrics[]).slice().sort((a, b) => b.ijmTotal - a.ijmTotal)
        : (filteredMetrics as EmployerMetrics[]).slice().sort((a, b) => b.iemTotal! - a.iemTotal!);
    } else {
      if (userType?.startsWith('individualsToJob')) {
        return (filteredMetrics as JobMetrics[]).slice().sort((a, b) => b.ijmTotal - a.ijmTotal);
      } else {
        return (filteredMetrics as EmployerMetrics[])
          .slice()
          .sort((a, b) => b.iemTotal! - a.iemTotal!);
      }
    }
  }

  function byLocation(
    mainObject: Employer | Job | Individual,
    filteredMetrics: JobMetrics[] | EmployerMetrics[]
  ) {
    if (isIndividual) {
      return mainObject?.hasOwnProperty('isJobActive')
        ? (filteredMetrics as JobMetrics[]).slice().sort((a, b) => +a.ijmDistance - +b.ijmDistance)
        : (filteredMetrics as EmployerMetrics[])
            .slice()
            .sort((a, b) => +a.iemDistance! - +b.iemDistance!);
    } else {
      if (userType?.startsWith('individualsToJob')) {
        return (filteredMetrics as JobMetrics[])
          .slice()
          .sort((a, b) => +a.ijmDistance - +b.ijmDistance);
      } else {
        return (filteredMetrics as EmployerMetrics[])
          .slice()
          .sort((a, b) => +a.iemDistance! - +b.iemDistance!);
      }
    }
  }
  //used when user wants recommendations of an indiv
  function createMultipleJobListItems(sortedMetrics: JobMetrics[]) {
    return sortedMetrics.map((metric: JobMetrics) => {
      if (userType?.startsWith('job')) {
        return {
          ...allJobs.find(
            (job) => job?.id === metric?.ijmJob && metric?.ijmIndividual === baseObject?.id
          ),
          ...metric,
        };
      } else {
        //userType === individualsToJob baseObject is a Job
        return {
          ...allIndividuals.find((individual) => individual?.id === metric?.ijmIndividual),
          ...metric,
        };
      }
    });
  }

  function createMultipleEmployerListItems(sortedMetrics: EmployerMetrics[]) {
    return sortedMetrics.map((metric: EmployerMetrics) => {
      return {
        ...allEmployers.find((employer) => employer?.id === metric?.iemEmployer),
        ...metric,
      };
    });
  }

  function createListItemsOfMultipleIndividuals(sortedMetrics: EmployerMetrics[] | JobMetrics[]) {
    if (userType?.startsWith('individualsToJob')) {
      return (sortedMetrics as JobMetrics[]).map((metric: JobMetrics) => {
        return {
          ...allIndividuals.find((individual) => individual?.id === metric?.ijmIndividual!),
          ...metric,
        };
      });
    } else {
      return (sortedMetrics as EmployerMetrics[]).map((metric: EmployerMetrics) => {
        return {
          ...allIndividuals.find((individual) => individual?.id === metric?.iemIndividual!),
          ...metric,
        };
      });
    }
  }

  function getTitle(selectedItem: Job | Employer | Individual | null): string {
    let obj;
    if (userType?.startsWith('job')) {
      obj = selectedItem as Job;
      return obj?.title;
    } else if (userType === 'employer') {
      obj = selectedItem as Employer;
      return obj?.orgName;
    } else {
      obj = selectedItem as Individual;
      return getFullName(obj);
    }
  }

  function buildNestedTags(item: Individual) {
    let nestedTags: { interests: number[]; skills: number[]; restrictions: number[] } = {
      interests: item?.interests,
      skills: item?.skills,
      restrictions: item?.restrictions,
    };
    return nestedTags || null;
  }

  const navigateBackHandler = (objType: string) => {
    // if use redirects from emails, need to pass state, otherwise pathname history is maintained.
    console.log(objType);
    console.info(baseObject);
    if (objType !== 'individualsToEmployer' && !isIndividual) {
      navigate(
        `/jobs/${encodeURIComponent(baseObject.employerOrganizationName)}/${baseObject.id}`,
        { state: baseObject }
      );
    } else if ((objType.startsWith('job') || objType.startsWith('employer')) && isIndividual) {
      navigate(`/individuals/${baseObject.leadProviderOrgId}/${baseObject.id}`, {
        state: baseObject,
      });
    } else {
      navigate(-1);
    }
  };

  console.warn(baseObject);

  return (
    <div className="recommendation-component">
      <section className="employer-details-nav">
        <CompanyDetailsComponent
          avatarStr={
            isIndividual
              ? getFullName(baseObject! as Individual)
              : userType === 'individualsToEmployer'
              ? (baseObject as Employer)?.orgName
              : (baseObject as Job)?.employerOrganizationName
          }
          indvTitle={isIndividual ? getFullName(baseObject! as Individual) : undefined}
          jobTitle={
            userType?.startsWith('individualsToJob') ? (baseObject as Job)?.title : undefined
          }
          jobType={
            userType?.startsWith('individualsToJob') ? (baseObject as Job)?.jobType : undefined
          }
          employerTitle={
            userType === 'individualsToEmployer'
              ? (baseObject as Employer)?.orgName
              : userType?.startsWith('individualsToJob')
              ? (baseObject as Job)?.employerOrganizationName
              : undefined
          }
          img={
            isIndividual
              ? (baseObject as Individual)?.profileImg
              : userType === 'individualsToEmployer'
              ? (baseObject as Employer)?.imgLogo
              : (baseObject as Job)?.employerLogo
          }
          isOpenToWork={
            isIndividual
              ? (baseObject as Individual)?.status
              : userType?.startsWith('individualsToJob')
              ? (baseObject as Job)?.closeDate === null
                ? true
                : dayjs((baseObject as Job)?.closeDate) > dayjs()
              : (baseObject as Employer)?.status
          }
          hrsTop={isIndividual ? (baseObject as Individual)?.filledHours : 0}
          hrsBottom={isIndividual ? (baseObject as Individual)?.availableHours : 0}
        />

        <div className="inline employer-nav">
          <div
            className="go-back inline"
            onClick={() => {
              navigateBackHandler(userType!);
            }}
          >
            <Button variant="text" startIcon={<ArrowBackIos />} sx={goBackReccStyles}>
              {isIndividual
                ? 'Back to Individual Details'
                : userType === 'individualsToEmployer'
                ? 'Back to Employer Details'
                : 'Back to Job Details'}
            </Button>
          </div>
          {isIndividual && (
            <Button variant="contained" onClick={toggleRecommender} sx={recommenderToggleStyling}>
              {userType === 'employer' ? t('viewJobMatches') : t('viewEmployerMatches')}
            </Button>
          )}
        </div>
      </section>

      <section className="recomm-content card">
        <div className="recomm-column1">
          <Typography variant="h4">
            {!isIndividual
              ? t('individual').toUpperCase()
              : userType === 'employer'
              ? t('employer').toUpperCase()
              : t('job').toUpperCase()}{' '}
            {t('matches').toUpperCase()}
          </Typography>
          <RecommendationFilterOptions
            userType={userType!.split(' ')[0]}
            searchState={searchBy!}
            setQueryType={handleFilterClick}
          />

          {displayAnimation && listCompiled ? (
            <AnimationOverlay>
              <AnimationWrapper height={windowDimensions.h}>
                <PuffLoader color={'#0379A0'} size={240} />
              </AnimationWrapper>
            </AnimationOverlay>
          ) : (
            <ListOfRecommendationsComponent
              userType={userType!.split(' ')[0]}
              items={itemsToDisplay}
              searchBy={searchBy}
              showDetails={handleItemChange}
              setDistance={handleSelectedDistance}
              isVisibleOnClick={() => {
                setIsVisible(true);
              }}
              primaryCoordinates={baseCoordinates}
              updateDetected={updateTrigger}
              isLoading={displayAnimation}
              setListCompiled={setListCompiled}
            />
          )}
        </div>
        <div className="recomm-column2 scrollable-hidden">
          <RecommendationShowTagMatches
            title={getTitle(selectedItem)}
            nestedTags={buildNestedTags(
              isIndividual ? (baseObject as Individual)! : (selectedItem as Individual)
            )}
            distance={detailsDistance!}
            tags={
              isIndividual
                ? (selectedItem as Job | Employer)?.tags!
                : (baseObject as Job | Employer)?.tags!
            }
            availability={
              isIndividual
                ? calculateAvailableHours(baseObject as Individual)
                : calculateAvailableHours(selectedItem as Individual)
            }
            isVisible={isVisible}
            restrictionsText={
              isIndividual
                ? (baseObject as Individual)?.restrictionsText!
                : (selectedItem as Individual)?.restrictionsText!
            }
            updateDetected={updateTrigger}
          />
          <RecommendationDetails
            selectedItem={selectedItem!}
            userType={userType!.split(' ')[0]}
            isVisible={isVisible}
          />
          <EmploymentSupportLeadDetails
            provider={leadProvider}
            isVisible={isVisible}
            photo={leadProvider?.photoUrl}
            orgId={leadProvider?.orgId}
          />
        </div>
      </section>
    </div>
  );
};

export default RecommendationsComponent;
