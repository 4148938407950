import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { AsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { capitalize } from '../../helpers/helperFunctions';
import useTranslate from '../../hooks/useTranslate';
import { getAccessToken } from '../../store/authSlice';
import { getMyProviderDetails } from '../../store/providerSlice';
import { useAppDispatch } from '../typescript/hooks';
import SanitizeHTML from './SanitizeHTML';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface DeletionProps {
  className?: string;
  icon: string;
  closeModal: () => void;
  confirmDeletion: (state: boolean) => void;
  recordId: string;
  asyncThunkFunc: AsyncThunk<any, any, any>;
  messageWarning: string;
  subject: string;
}

const ConfirmDeletionComponent = (props: DeletionProps) => {
  const {
    className,
    icon,
    closeModal,
    confirmDeletion,
    recordId,
    asyncThunkFunc,
    messageWarning,
    subject,
  } = props;
  const dispatch = useAppDispatch();
  const myProvider = useSelector(getMyProviderDetails);
  const accessToken = useSelector(getAccessToken);
  const t = useTranslate();
  const navigate = useNavigate();

  const handleDeletetion = () => {
    dispatch(
      asyncThunkFunc({
        deletePayload: { lookupId: recordId!, creator: myProvider.id! },
        token: accessToken!,
      })
    )
      .then(unwrapResult)
      .then((res: any) => {
        console.log({ res });
        if (res.status === 'success') {
          navigate('/' + props.subject + 's');
          confirmDeletion(true);
        } else {
          //error occurred, please try again later.
        }
      });
  };

  return (
    <Card className={className}>
      <div
        className="delete-record-popup"
        style={{
          maxWidth: '700px',
          textAlign: 'center',
        }}
      >
        <div>
          <img src={icon} alt="icon-delete" />
          <Typography variant="h1">
            {capitalize(t('deletePermanently')) + ' ' + subject}?
          </Typography>
          <br />
          <SanitizeHTML htmlString={messageWarning} />
          <br />
          <div className="inline-around full-width" style={{ columnGap: '2em' }}>
            <Button variant="outlined" sx={{ fontFamily: 'LexendDeca' }} onClick={handleDeletetion}>
              {t('deleteThisMsg') + ' ' + subject.toLowerCase()}
            </Button>

            <Button variant="contained" sx={{ fontFamily: 'LexendDeca' }} onClick={closeModal}>
              {t('doNotDeleteThisMsg')}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ConfirmDeletionComponent;
