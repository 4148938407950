import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MatchMeter from '../SharedComponents/MatchMeter';
import { Job, AppliedJob } from '../typescript/types';
import {
  ActivityJobCardParent,
  BackdropOverlay,
  InlineContainer,
  WidgetCardImage,
} from '../UI/StyledComponents/GlobalStyledComponents';
import { FormTextInput } from '../UI/StyledComponents/FormsStyledComponents';
import { APPLICATION_STATUS } from '../../helpers/constants';
import SelectAnOptionPopupComponent from '../UI/Popup/SelectAnOptionPopupComponent';
import Modal from '../UI/Popup/BackdropComponent';
import { useAppDispatch, useAppSelector } from '../typescript/hooks';
import { getAccessToken } from '../../store/authSlice';
import { updateAppliedJobStatus } from '../../store/activitySlice';
import { getMyProviderDetails } from '../../store/providerSlice';
import { getIndustryBanner, getIndustryImage } from '../../helpers/helperFunctions';
import PuffLoader from '../UI/Animation/PuffLoader';
import useTranslate from '../../hooks/useTranslate';
import { removeAppliedJob } from '../../store/individualSlice';
import RemoveOfferedModal from '../UI/Popup/RemoveOfferedModal';

const ActivityJobCard = (props: {
  job: Job;
  type: string;
  appliedJob?: AppliedJob;
  likedJobs?: { id: string; ts: string };
  indId: string;
  archiveJob?: () => void;
}) => {
  const { job, type, appliedJob, indId, archiveJob } = props;

  const t = useTranslate();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [newApplicationStatus, setNewApplicationStatus] = useState<APPLICATION_STATUS | null>(
    type === 'applied' ? appliedJob?.status! : null
  );
  const [showActivityLogModal, setShowActivityLogModal] = useState<boolean>(false);
  const token = useAppSelector(getAccessToken);
  const myDetails = useAppSelector(getMyProviderDetails);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [showArchiveButton, setShowArchiveButton] = useState<boolean>(false);

  const handleSubmission = () => {
    setShowActivityLogModal(false);
    dispatch(
      updateAppliedJobStatus({
        indId: indId,
        newStatus: newApplicationStatus!,
        jobId: appliedJob?.id!,
        orgId: myDetails?.orgId!,
        provId: myDetails?.id!,
        token: token!,
      })
    );
  };

  const handleCancelUpdateState = () => {
    setShowActivityLogModal(false);
    setNewApplicationStatus(appliedJob?.status!);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => { 
    if (e.target.value === 'Offer of Employment') {
      setShowActivityLogModal(true);
    } else {
      dispatch(
        updateAppliedJobStatus({
          indId: indId,
          newStatus: e.target.value,
          jobId: appliedJob?.id!,
          orgId: myDetails?.orgId!,
          provId: myDetails?.id!,
          token: token!,
        })
      );
    }
    setNewApplicationStatus(e.target.value as APPLICATION_STATUS);
  };

  const handleRemoveOffered = () => {
    archiveJob!();
    setShowArchiveModal(false);
  };

  const ApplicationStatusPicker = () => {
    return (
      <FormTextInput
        label={'Status'}
        fullWidth
        select
        value={newApplicationStatus}
        required={false}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={handleChange}
        sx={{
          width: '50%',
          minWidth: '200px',
          maxWidth: '225px',
        }}
      >
        <MenuItem value={APPLICATION_STATUS.OFFER}>{APPLICATION_STATUS.OFFER}</MenuItem>
        <MenuItem value={APPLICATION_STATUS.PROCESSING}>{APPLICATION_STATUS.PROCESSING}</MenuItem>
        <MenuItem value={APPLICATION_STATUS.INTERVIEWING}>
          {APPLICATION_STATUS.INTERVIEWING}
        </MenuItem>
        <MenuItem value={APPLICATION_STATUS.NOPE}>{APPLICATION_STATUS.NOPE}</MenuItem>
      </FormTextInput>
    );
  };

  useEffect(() => {
    setShowArchiveButton(newApplicationStatus === APPLICATION_STATUS.OFFER);
  }, [newApplicationStatus]);

  return (
    <ActivityJobCardParent className="activity-job-card-wrpr">
      <Box
        onClick={() => {
          navigate(`/jobs/${encodeURIComponent(job?.employerOrganizationName!)}/${job?.id!}`, {
            state: job!,
          });
        }}
        className="job-card-main-content"
        width={1}
      >
        <React.Suspense fallback={<PuffLoader />}>
          <WidgetCardImage src={getIndustryBanner(job!)} loading="lazy" />
        </React.Suspense>
        <InlineContainer
          sx={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <Box className="activity-card-info" padding="5px 10px" alignSelf={'center'} width="80%">
            <Typography fontWeight={600}>{job.title}</Typography>
            <Typography variant="body2" fontWeight={600} marginBottom={'7.5px'}>
              {job.employerOrganizationName}
            </Typography>
            <Typography fontWeight={600} textTransform={'uppercase'}>
              {t('db-jobInterestHours')}{' '}
              <span style={{ color: '#4f5f71', fontWeight: 'normal' }}>{job.weeklyHrs}</span>
            </Typography>
          </Box>
          <MatchMeter job={job} indId={indId} />
        </InlineContainer>
      </Box>
      <InlineContainer>
        {type === 'applied' && (
          <Box padding="10px 0" width={1}>
            <ApplicationStatusPicker />
          </Box>
        )}
        {showArchiveButton && (
          <Tooltip
            title="Archiving this job is necessary to ensure the individual you support can apply for more jobs. A record of this successful offer can be referenced in the activity log."
            arrow
          >
            <Button
              onClick={() => {
                setShowArchiveModal(true);
              }}
            >
              Archive
            </Button>
          </Tooltip>
        )}
      </InlineContainer>
      {showActivityLogModal && (
        <Modal
          className="status-message-modal"
          onHide={() => {
            handleCancelUpdateState();
          }}
        >
          <BackdropOverlay
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SelectAnOptionPopupComponent
              title={t('popupTitle-areYouSure')}
              jsx={
                <Typography sx={{ marginTop: '20px' }}>
                  {t('changeStatusMessage-1')} <b>{t('changeStatusMessage-2')}</b>{' '}
                  {t('changeStatusMessage-3')} <b>{t('changeStatusMessage-4')}</b>{' '}
                  {t('changeStatusMessage-5')}'{' '}
                </Typography>
              }
              confirmAction={handleSubmission}
              goBack={() => {
                handleCancelUpdateState();
              }}
              buttonYes={t('panelForms-continue')}
              buttonNo={t('button-back')}
            />
          </BackdropOverlay>
        </Modal>
      )}
      {showArchiveModal && (
        <Modal
          className="status-message-modal"
          onHide={() => {
            setShowArchiveModal(false);
          }}
        >
          <BackdropOverlay
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <SelectAnOptionPopupComponent
              title={t('popupTitle-areYouSure')}
              jsx={
                <Typography sx={{ marginTop: '20px' }}>
                  Archiving this job is necessary to ensure the individual you support can apply for
                  more jobs. A record of this successful offer can be referenced in the activity
                  log.
                </Typography>
              }
              confirmAction={handleRemoveOffered}
              goBack={() => {
                setShowArchiveModal(false);
              }}
              buttonYes={t('yes')}
              buttonNo={t('no')}
            />
          </BackdropOverlay>
        </Modal>
      )}
    </ActivityJobCardParent>
  );
};

export default ActivityJobCard;
