import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Job } from '../../typescript/types';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import useTranslate from '../../../hooks/useTranslate';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { detailedAccordionStyling, marginBottomAccordion } from '../../../helpers/styling';
import {
  MobileContainerBody,
  MobileContainerHeader,
  SectionHeaderWrapper,
} from '../../UI/StyledComponents/GlobalStyledComponents';
import RecommendationsWidgetComponent from '../../DashboardComponents/RecommendationsWidgetComponent';
import { getMyProviderDetails, getProviderById } from '../../../store/providerSlice';
import { selectJobById } from '../../../store/jobSlice';
import ViewComponentSharedButtons from '../../EmployerComponents/SharedWidgets/ViewComponentSharedButtons';
import { ViewAccordion } from '../../EmployerComponents/MobileTablet/ReusableAccordion';
import {
  OverviewWidget,
  RecordDescriptionWidget,
} from '../../EmployerComponents/SharedWidgets/OverviewWidget';
import JobAttachments from '../JobAttachments';
import HowToApply from '../JobHowToApply';
import useDetectResize from '../../../hooks/useDetectResize';

const MobileJobViewComponent = (props: { isNavCollapsed: boolean }) => {
  const { isNavCollapsed } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  const t = useTranslate();

  const refJob = state as Job;
  const job = useSelector((rState: RootState) => selectJobById(rState, refJob!.id));
  const leadProvider = useSelector((state: RootState) => getProviderById(state, job?.providerId));
  const myProviderDetails = useSelector(getMyProviderDetails);
  const { isMobile } = useDetectResize();
  const accordionSummaryContentSx = {
    '& > div > div.MuiAccordionSummary-content': {
      margin: !isMobile ? '20px 0 !important' : '20px 0 !important',
    },
  };
  const topAccordionStyles = [detailedAccordionStyling, accordionSummaryContentSx];
  const [expanded, setExpanded] = useState<string>('');

  return (
    <>
      <ViewComponentSharedButtons goBackUrl="/jobs" refObj={job!} leadProvider={leadProvider!} />
      <MobileContainerBody>
        <ViewAccordion
          title={'Overview'}
          sx={topAccordionStyles}
          ac_id="panel1"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <hr style={{ margin: '20px -15px 20px', border: '1.5px solid #d9e1e7' }} />
          <Typography variant="h3">JOB OVERVIEW</Typography>
          {job && <OverviewWidget overViewOf="job" overViewObj={job as Job} />}
        </ViewAccordion>

        <ViewAccordion
          title={t('howToApply')}
          sx={topAccordionStyles}
          ac_id="panel2"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <hr style={{ margin: '20px -15px 20px', border: '1.5px solid #d9e1e7' }} />
          <HowToApply job={job} showTitle={false} />
        </ViewAccordion>

        <ViewAccordion
          title={t('attachmentsTitle')}
          sx={topAccordionStyles}
          ac_id="panel3"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <hr style={{ margin: '20px -15px 20px', border: '1.5px solid #d9e1e7' }} />
          <JobAttachments job={job} />
        </ViewAccordion>
      </MobileContainerBody>

      <RecommendationsWidgetComponent
        userState={job as Job}
        userType={'individualsToJob'}
        isIndividual={false}
      />
    </>
  );
};

export default MobileJobViewComponent;

export const JobViewHeader = (props: {
  isRecommendations?: boolean;
  setBaseObject?: React.Dispatch<any>;
}) => {
  const { state, pathname } = useLocation();
  const t = useTranslate();
  const job = useSelector((state: RootState) => selectJobById(state, pathname.split('/')[3]));
  const { isRecommendations } = props;
  const { isMobile } = useDetectResize();

  useEffect(() => {
    // console.warn('Ind Object')
    // console.log(job);
    if (props.setBaseObject) {
      props.setBaseObject(job);
    }
  }, [job]);

  return (
    <SectionHeaderWrapper
      sx={{
        textAlign: `${isRecommendations ? 'right' : 'center'}`,
        display: `${isRecommendations ? 'inline-grid' : 'block'}`,
        justifyItems: `${isRecommendations ? 'flex-end' : 'unset'}`,
        paddingLeft: `${isRecommendations ? '20px' : '0px'}`,
      }}
    >
      <Typography className="comp-detail-mn-title" variant="h1" fontWeight="900" sx={{ width: 1 }}>
        {job?.title!}
      </Typography>
      <Typography
        className="comp-detail-mn-title"
        justifyContent={isMobile ? 'flex-start' : 'center'}
        color="text.secondary"
        variant="h3"
        sx={{ width: 1 }}
      >
        {job?.employerOrganizationName!}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: `${isRecommendations ? 'flex-end' : 'center'}`,
          marginTop: '5px',
          '& > span': {
            marginRight: isRecommendations ? '0px !impotant' : '1em',
          },
        }}
      >
        <span className={`status-badge ${job?.isJobActive! ? 'status-open' : 'status-closed'}`}>
          {job?.isJobActive ? t('open') : t('closed')}
        </span>
      </Box>
    </SectionHeaderWrapper>
  );
};
