import { useCallback, useEffect, useRef, useState } from 'react';

interface MediaObj {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isDesktop: boolean;
  isMobileOrTablet: boolean;
}

//upper limit (BP) for each device. based off Bootstrap breakpoints
const mobileBP = 768; //sm
const tabletBP = 1025; //md
const laptopBP = 1200; //lg
const desktopBP = 1400; //xlg

// changed the defaultValues object to getDeviceType an object-returning function
const getDeviceType = (width: number): MediaObj => ({
  isMobile: width < mobileBP,
  isTablet: width >= mobileBP && width <= tabletBP,
  isLaptop: width > tabletBP && width <= laptopBP,
  isDesktop: width > laptopBP,
  isMobileOrTablet: width <= tabletBP,
});

const useDetectResize = () => {
  const [mediaType, setMediaType] = useState<MediaObj>(getDeviceType(window.innerWidth));
  const [windowDimensions, setWindowDimensions] = useState({
    w: window.innerWidth,
    h: window.innerHeight,
  });

  // this is to ensure timeout ID persists across renders
  const resizeTimeoutRef = useRef<number>();

  // defining handleWindowResize here so useEffect can access it as a dependency
  const handleWindowResize = useCallback(() => {
    clearTimeout(resizeTimeoutRef.current);

    // debounce setWindowDimensions & setMediaType function every 100 ms
    resizeTimeoutRef.current = window.setTimeout(() => {
      // store width/height and mediaState
      setWindowDimensions({ w: window.innerWidth, h: window.innerHeight });
      setMediaType(getDeviceType(window.innerWidth));
    }, 100);
  }, []);

  useEffect(() => {
    // window.resize event listener will call handleWindowResize() whenever screen width is adjusted.
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      clearTimeout(resizeTimeoutRef.current);
    };
  }, [handleWindowResize]);

  return {
    ...mediaType,
    windowDimensions,
  };
};

export default useDetectResize;
