import {
  TextField,
  FormHelperText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useState, useRef } from 'react';
import { useAppDispatch } from '../typescript/hooks';
import { thunkSubmitKeywordRequest } from '../../store/keywordSlice';
import { getMyProviderDetails } from '../../store/providerSlice';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../store/authSlice';
import useTranslate from '../../hooks/useTranslate';
import { FormTextInput } from '../UI/StyledComponents/FormsStyledComponents';
import useDetectResize from '../../hooks/useDetectResize';

const SubmitKeywordSuggestions = (props: {
  labelNoun: string;
  displayModal: (bool: boolean) => void;
}) => {
  const [data, setData] = useState<string>('');
  const [expanded, setExpanded] = useState<string | false>(false);
  const { labelNoun, displayModal } = props;
  const dispatch = useAppDispatch();
  const accessToken = useSelector(getAccessToken);
  const myProviderDetails = useSelector(getMyProviderDetails);
  const ref = useRef<HTMLInputElement>();
  const { isMobile, isTablet } = useDetectResize();
  const t = useTranslate();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    console.log(labelNoun);
  };

  const onSubmit = () => {
    dispatch(
      thunkSubmitKeywordRequest({
        token: accessToken!,
        keywordObject: { data: data, provId: myProviderDetails.id },
      })
    );
    ref.current!.value = ''; //reset input
    setExpanded(false); //close accordion
    displayModal(true); //show popup ack submission
    setData((currState) => (currState = '')); //reset state data to disable button
  };

  // TODO: update text relative to job/employer/individual

  return (
    <Accordion
      expanded={expanded === 'panel'}
      onChange={handleChange('panel')}
      sx={{
        boxShadow: 'none',
        marginBottom: '0px!important'
      }}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          width: 'fit-content',
          marginLeft: 'auto',
          '& .MuiAccordionSummary-content': {
            justifyContent: 'flex-end',
          },
        }}
      >
        <Typography
          sx={{ color: 'var(--primary-color)', textDecoration: 'underline', fontFamily: 'Nunito' }}
        >
          {t('submitPromptI')} {labelNoun} {t('submitPromptII')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            width: '100%',
            display: 'inline-grid',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <div>
            <FormTextInput
              inputRef={ref}
              label={`Request additional ${labelNoun} here.`}
              placeholder={`Request additional ${labelNoun} here.`}
              variant="outlined"
              defaultValue={ref.current?.value}
              sx={{ width: isMobile || isTablet ? '75vw' : '50vw', margin: '0 10px' }}
              onChange={(e) => {
                setData(e.target.value);
              }}
            />
            <FormHelperText sx={{ marginLeft: '15px' }}>{t('submitKeywordMsg')}</FormHelperText>
          </div>
          <Button
            variant="contained"
            onClick={onSubmit}
            sx={{
              width: 'fit-content',
              marginLeft: 'auto',
              background: 'var(--primary-color)',
              '&:hover': {
                background: '#2325a5',
              },
            }}
            disabled={data.length === 0}
          >
            {t('submitRequest')}
          </Button>
        </div>
        <hr
          style={{
            height: '1px',
            backgroundColor: '#ccc',
            border: 'none',
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default SubmitKeywordSuggestions;
