import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import useTranslate from '../../../hooks/useTranslate';
import { Individual } from '../../typescript/types';
import { getProviderById } from '../../../store/providerSlice';
import { RootState } from '../../../store';
import { selectIndividualById } from '../../../store/individualSlice';
import { detailedAccordionStyling } from '../../../helpers/styling';
import ViewComponentSharedButtons from '../../EmployerComponents/SharedWidgets/ViewComponentSharedButtons';
import {
  IndToggleContainer,
  InlineContainer,
  JobEmpToggleButton,
  MobileContainerBody,
  SectionHeaderWrapper,
} from '../../UI/StyledComponents/GlobalStyledComponents';
import { ViewAccordion } from '../../EmployerComponents/MobileTablet/ReusableAccordion';
import { recommendBtnStyling } from '../IndividualComponent';
import { OverviewWidget } from '../../EmployerComponents/SharedWidgets/OverviewWidget';
import RecommendationsWidgetComponent from '../../DashboardComponents/RecommendationsWidgetComponent';
import IndividualSkillsInterestsRestrictions from '../IndividualSkillsInterestsRestrictions';
import IndividualAttachments from '../IndividualAttachments';
import IndividualNotes from '../IndividualNotes';
import MobileEmploymentHistoryCards from './MobileEmploymentHistoryCards';
import useDetectResize from '../../../hooks/useDetectResize';
import { HrsBadge, IndStatusBadge } from '../../SharedComponents/SharedBadges';
import useProfileCompletion from '../../../hooks/useProfileCompletion';
import { ProfileProgressBar } from '../../ActivityFeedComponents/ActivityCard';
import { IndividualHeaderProgressBar } from '../../UI/StyledComponents/ViewStyledComponents';
import EngagementLinks from '../EngagementLinks';

const MobileIndividualViewComponent = () => {
  const { pathname } = useLocation();
  const t = useTranslate();

  const individual = useSelector((rState: RootState) =>
    selectIndividualById(rState, pathname.split('/')[3])
  );
  const leadProvider = useSelector((state: RootState) =>
    getProviderById(state, individual?.leadProviderId)
  );
  const { isMobile } = useDetectResize();
  const accordionSummaryContentSx = {
    '& > div > div.MuiAccordionSummary-content': {
      margin: !isMobile ? '20px 0 !important' : '20px 0 !important',
    },
  };
  const topAccordionStyles = [detailedAccordionStyling, accordionSummaryContentSx];
  const [isJobRecommendations, setIsJobRecommendations] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string>('');

  return (
    <>
      <ViewComponentSharedButtons
        goBackUrl="/individuals"
        refObj={individual!}
        leadProvider={leadProvider!}
      />
      <MobileContainerBody>
        <ViewAccordion
          title={'SKILLS, INTERESTS & RESTRICTIONS'}
          sx={topAccordionStyles}
          ac_id="panel2"
          key="panel2"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <hr style={{ margin: '20px -15px 20px', border: '1.5px solid #d9e1e7' }} />
          <IndividualSkillsInterestsRestrictions individual={individual!} />
          <EngagementLinks
            individualId={individual?.id!}
            leadProviderOrgId={leadProvider?.orgId!}
          />
        </ViewAccordion>

        <ViewAccordion
          title={'Overview'}
          sx={topAccordionStyles}
          ac_id="panel1"
          key="panel1"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <hr style={{ margin: '20px -15px 20px', border: '1.5px solid #d9e1e7' }} />
          <Typography variant="h3">INDIVIDUAL OVERVIEW</Typography>
          {individual && (
            <OverviewWidget overViewOf="individual" overViewObj={individual as Individual} />
          )}
        </ViewAccordion>

        <ViewAccordion
          title="Employment Schedule & History"
          sx={topAccordionStyles}
          ac_id="panel3"
          key="panel3"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <hr style={{ margin: '20px -15px 20px', border: '1.5px solid #d9e1e7' }} />
          <MobileEmploymentHistoryCards
            record={individual?.employmentRecord!}
            hours={individual?.filledHours!}
          />
        </ViewAccordion>

        <ViewAccordion
          title={t('notesComments')}
          sx={topAccordionStyles}
          ac_id="panel4"
          key="panel4"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <hr style={{ margin: '20px -15px 20px', border: '1.5px solid #d9e1e7' }} />
          <IndividualNotes individual={individual} />
        </ViewAccordion>

        <ViewAccordion
          title={t('attachmentsTitle')}
          sx={topAccordionStyles}
          ac_id="panel5"
          key="panel5"
          expanded={expanded}
          setExpanded={setExpanded}
        >
          <hr style={{ margin: '20px -15px 20px', border: '1.5px solid #d9e1e7' }} />
          <IndividualAttachments individual={individual} />
        </ViewAccordion>
      </MobileContainerBody>
      <Box className="full-width inline-c">
        <IndToggleContainer
          className="toggle-container-ind"
          sx={{
            width: '90%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <JobEmpToggleButton
            variant={isJobRecommendations ? 'contained' : 'text'}
            size="small"
            sx={recommendBtnStyling}
            onClick={() => {
              setIsJobRecommendations(true);
            }}
          >
            {t('jobNav').toUpperCase()}
          </JobEmpToggleButton>

          <JobEmpToggleButton
            variant={!isJobRecommendations ? 'contained' : 'text'}
            size="small"
            sx={recommendBtnStyling}
            onClick={() => {
              setIsJobRecommendations(false);
            }}
          >
            {t('employerNav')}
          </JobEmpToggleButton>
        </IndToggleContainer>
      </Box>

      <RecommendationsWidgetComponent
        userState={individual as Individual}
        userType={isJobRecommendations ? 'job' : 'employer'}
        isJob={isJobRecommendations}
        isIndividual={true}
      />
    </>
  );
};

export default MobileIndividualViewComponent;

export const IndividualViewHeader = (props: {
  isRecommendations?: boolean;
  setBaseObject?: React.Dispatch<any>;
}) => {
  const { pathname } = useLocation();
  const individual = useSelector((state: RootState) =>
    selectIndividualById(state, pathname.split('/')[3])
  );
  const { isMobile, isTablet } = useDetectResize();
  const score = useProfileCompletion(individual!);

  useEffect(() => {
    if (props.setBaseObject) {
      props.setBaseObject(individual);
    }
  }, [individual]);

  return (
    <SectionHeaderWrapper
      sx={{
        textAlign: `${props.isRecommendations ? 'right' : 'center'}`,
        display: `${props.isRecommendations ? 'inline-grid' : 'block'}`,
        justifyItems: `${props.isRecommendations ? 'flex-end' : 'unset'}`,
        justifyContent: `${
          props.isRecommendations && (isMobile || isTablet) ? 'flex-end' : 'center'
        }`,
        paddingLeft: `${props.isRecommendations ? '20px' : '0px'}`,
        alignContent: 'center',
        marginBottom: `${isMobile ? '10px' : '15px'}`,
        marginLeft: `${isTablet ? (props.isRecommendations ? '-85px' : 'none') : 'none'}`,
      }}
    >
      <Typography
        className="comp-detail-mn-title"
        variant="h1"
        sx={{
          width: 1,
          justifyContent: props.isRecommendations ? 'flex-end !important' : 'center',
        }}
      >
        {individual?.firstName! + ' ' + individual?.lastName!}
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: props.isRecommendations && (isMobile || isTablet) ? 'flex-end' : 'center',
          marginTop: '5px',
          paddingTop: `${props.isRecommendations ? '0px' : '5px'}`,
        }}
      >
        <InlineContainer
          width={isMobile ? '135px !important' : '150px !important'}
          justifyContent={'space-between'}
        >
          <HrsBadge
            numerator={individual?.filledHours!}
            denominator={individual?.availableHours!}
          />
          <IndStatusBadge isOpen={individual?.status!} />
        </InlineContainer>
      </Box>
      <IndividualHeaderProgressBar
        className="header-progress-bar"
        padding="10px 0px 0px 10px !important"
      >
        <ProfileProgressBar score={score} background={'transparent'} />
      </IndividualHeaderProgressBar>
    </SectionHeaderWrapper>
  );
};
