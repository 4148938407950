import Card from '../UI/Card/CardComponent';
import { getDeltaTime } from '../../helpers/getDeltaTime';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { NOTIFICATION } from '../../helpers/constants';
import { Employer, Individual, Job, Notification } from '../typescript/types';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import store from '../../store/index';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  thunkMarkNotificationRead,
  singleNotificationHidden,
  singleNotificationRead,
  thunkMarkNotificationHide,
} from '../../store/notificationSlice';
import { getMyProviderDetails } from '../../store/providerSlice';
import { useAppDispatch } from '../typescript/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import { numberOfDays, numberOfDaysOpenForWork } from '../../helpers/helperFunctions';
import { getAllIndividuals } from '../../store/individualSlice';
import useTranslate from '../../hooks/useTranslate';
import {
  NotificationCardContainer,
  NotificationCardStyling,
  NotificationContainer,
  NotificationTypography,
} from '../UI/StyledComponents/NotificationModalStyledComponents';
import { Box } from '@mui/system';
import useDetectResize from '../../hooks/useDetectResize';
import { Typography } from '@mui/material';

interface PropNotification {
  notification: Notification;
  closeModal: any;
}

const NotificationCard = (props: PropNotification) => {
  const { notification, closeModal } = props;
  const [notificationState, setNotificationState] = useState<Job | Employer | Individual | null>(
    null
  );
  const myProvider = useSelector(getMyProviderDetails);
  const organizationIndividuals = useSelector(getAllIndividuals);
  const [navPath, setNavPath] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const t = useTranslate();
  const isEnglishLocale = (localStorage.getItem('language') || 'en') === 'en' ? true : false;
  const { windowDimensions } = useDetectResize();

  useEffect(() => {
    if (notification.notificationType.startsWith('JOB')) {
      setNavPath((currState) => (currState = `/jobs/${notification.objectPath}`));
      let id = notification.objectPath.split('/')[1];
      let obj = store.getState().jobs.allJobs.find((job) => job.id === id);
      setNotificationState((currState) => (currState = obj as Job));
    } else if (notification.notificationType.startsWith('INDIVIDUAL')) {
      setNavPath((currState) => (currState = `/individuals/${notification.objectPath}`));
      let id = notification.objectPath.split('/')[1];
      let obj = store.getState().individuals.allIndividuals.find((individ) => individ.id === id);
      setNotificationState((currState) => (currState = obj as Individual));
    } else if (notification.notificationType.startsWith('EMPLOYER')) {
      let [nav1, nav2, id] = notification.objectPath.split('/');
      //This may break, need to test and probably encodeURIComponent();
      setNavPath((currState) => (currState = `/employers/${nav1}/${nav2}`));
      let obj = store.getState().employers.employers.find((emp) => emp.id === id);
      setNotificationState((currState) => (currState = obj as Employer));
    }
  }, [notification]);

  const getIndividualName = (id: string) => {
    let result = organizationIndividuals.find((individual: Individual) => individual.id === id);
    let individual =
      result === undefined ? `could not find ${id}` : `${result.firstName} ${result.lastName}`;
    return individual;
  };

  const notificationMessage = (notification: Notification) => {
    let endOfSentence = <></>;

    switch (notification.notificationType) {
      case NOTIFICATION.JOB_NEW:
        endOfSentence = <> {t('jobNewNotif')} 👀</>;
        break;
      // case NOTIFICATION.JOB_UPDTD:
      //   endOfSentence = <> {t('jobUpdatedNotif')} </>;
      //   break;
      case NOTIFICATION.JOB_CLOSE:
        let days = numberOfDays(notification.alertUser!);
        endOfSentence = (
          <>
            &nbsp; {t('jobClosingNotif1')} <i>{notification.notificationNoun2}</i>{' '}
            <b>
              {days > 0 && days <= 10
                ? ` ${t('jobClosingNotif2')} ${t('jobClosingNotif3')} ${days} ${t(
                    'jobClosingNotif4'
                  )} 🕓`
                : days >= 10
                  ? ` has extended the closing date to ${days} days from now`
                  : ` ${days === 1 ? t('jobClosingNotif5') : ' is now closed'}  🕓`}
            </b>
          </>
        );
        break;
      case NOTIFICATION.JOB_OPEN:
        endOfSentence = (
          <>
            {' '}
            position by <i>{notification.notificationNoun2}</i> has reopened! 📢
          </>
        );
        break;
      case NOTIFICATION.EMPLOYER_NEW:
        endOfSentence = <> {t('newProvNotif')}</>;
        break;
      // case NOTIFICATION.INDIV_NEW:
      //   endOfSentence = (
      //     <>
      //       &nbsp;{t('individualAddedNotif')},{' '}
      //       <span className="notif-noun-link" onClick={navigateHandler}>
      //         {notification.notificationNoun2 || t('aNewPerson')}
      //       </span>
      //     </>
      //   );
      //   break;
      case NOTIFICATION.INDIV_B2C_UPDATE:
        endOfSentence = (
          <>
            &nbsp;{t('updated')}{' '}
            <span className="notif-noun-link" onClick={navigateHandler}>
              {t('theirProfile')}
            </span>
          </>
        );
        break;
      case NOTIFICATION.INDIV_EJI:
        endOfSentence = (
          <>&nbsp;has started an application for the {notification.notificationNoun2} role.</>
        );
        break;
      case NOTIFICATION.INDIV_RETRACT:
        endOfSentence = (
          <>&nbsp;has removed an application for the {notification.notificationNoun2} role.</>
        );
        break;
      // case NOTIFICATION.INDIV_OPEN:
      //   let iDays = numberOfDaysOpenForWork(notification.alertUser!);

      //   endOfSentence = (
      //     <>
      //       ,{' '}
      //       <span className="notif-noun-link" onClick={navigateHandler}>
      //         {notification.notificationNoun2! || t('aNewPerson')}
      //       </span>{' '}
      //       {t('individualMilkExpirationNotif1')} {iDays} {t('individualMilkExpirationNotif2')} 📢
      //     </>
      //   );
      //   break;
      case NOTIFICATION.INDIV_MATCH:
        endOfSentence = (
          <>
            &nbsp;
            <span className="notif-noun-link" onClick={navigateHandler}>
              {notification.notificationNoun2!.startsWith('ind-')
                ? getIndividualName(notification.notificationNoun2!)
                : notification.notificationNoun2!}
            </span>
            &nbsp;{t('hasNewJob')}.
          </>
        );
        break;
      case NOTIFICATION.INDIV_B2C_NEW:
        endOfSentence = <>&nbsp;has connected to your organization! 🥳</>;
        break;
      case NOTIFICATION.INDIV_ASSIGN:
        endOfSentence = <>&nbsp;has been assigned to you for employment support.</>;
        break;
      default:
        endOfSentence = (
          <> unaccounted condition: notificationType = {notification.notificationType}</>
        );
    }

    return (
      <>
        {notification.notificationType === 'INDIVIDUAL_ASSIGNED' ? (
          <>
            <span className="notif-noun-link" onClick={navigateHandler}>
              {notification.notificationNoun2}
            </span>
            {endOfSentence}
          </>
        ) : notification.notificationType === 'INDIVIDUAL_EXPRESS' ? (
          <>
            <span className="notif-noun-link" onClick={navigateHandler}>
              {notification.notificationNoun}
            </span>
            {endOfSentence}
          </>
        ) : notification.notificationType === 'INDIVIDUAL_RETRACT' ? (
          <>
            <span className="notif-noun-link" onClick={navigateHandler}>
              {notification.notificationNoun}
            </span>
            {endOfSentence}
          </>
        ) : !notification.notificationType.startsWith('INDIVIDUAL') ? (
          <>
            <span className="notif-noun-link" onClick={navigateHandler}>
              {notification.notificationNoun}
            </span>
            {endOfSentence}
          </>
        ) : notification.notificationType.endsWith('B2C_NEW') ? (
          <>
            <span className="notif-noun-link" onClick={navigateHandler}>
              {notification.notificationNoun2}
            </span>
            {endOfSentence}
          </>
        ) : notification.notificationType.startsWith('INDIVIDUAL') &&
          !notification.notificationType.endsWith('MATCH') ? (
          <>
            <span className="notif-noun">{notification.notificationNoun}</span>
            {endOfSentence}
          </>
        ) : (
          <>
            <span className="notif-noun">💥</span>
            {endOfSentence}
          </>
        )}
      </>
    );
  };

  const navigateHandler = () => {
    navigate(navPath, { state: notificationState });
  };

  const markRead = () => {
    dispatch(
      thunkMarkNotificationRead({
        myProviderId: myProvider.id,
        notification: notification,
      })
    )
      .then(unwrapResult)
      .then((status) => {
        status === 200
          ? dispatch(
              singleNotificationRead({
                ts: notification.createdAt,
                path: notification.objectPath,
                type: notification.notificationType,
              })
            )
          : alert('Sorry, something went wrong, please try again');
      });
  };

  const markHidden = () => {
    dispatch(
      thunkMarkNotificationHide({
        myProviderId: myProvider.id,
        notification: notification,
      })
    )
      .then(unwrapResult)
      .then((status) => {
        status === 200
          ? dispatch(
              singleNotificationHidden({
                ts: notification.createdAt,
                path: notification.objectPath,
                type: notification.notificationType,
              })
            )
          : alert('Sorry, something went wrong in markHiddenClick');
      });
  };

  return (
    <>
      {notification.isVisible && (
        <NotificationCardContainer
          className={!notification.isRead ? 'notif-highlight' : 'notif-unread'}
          onClick={() => {
            markRead();
            closeModal();
          }}
        >
          <NotificationContainer>
            <NotificationCardStyling width={windowDimensions.w}>
              <NotificationTypography className="notification-message" variant="body1">
                {notificationMessage(notification)}

                <br />

                <Typography variant="caption" className="notification-time">
                  {getDeltaTime(notification.createdAt, t, isEnglishLocale)}
                </Typography>
              </NotificationTypography>
            </NotificationCardStyling>

            <Box className="meat-balls">
              <IconButton
                color="inherit"
                onClick={(e) => {
                  markHidden();
                  e.stopPropagation();
                }}
              >
                {<DeleteOutlineOutlinedIcon />}
              </IconButton>
            </Box>
          </NotificationContainer>
        </NotificationCardContainer>
      )}
    </>
  );
};

export default NotificationCard;
