import React, { useEffect } from 'react';
import TablePagination from '@mui/material/TablePagination';

interface SliceRange {
  start: number;
  end: number;
}

interface PaginationProps {
  totalItems: number;
  setBoundaries: React.Dispatch<React.SetStateAction<SliceRange>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const PaginationComponent = (props: PaginationProps) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { totalItems, setBoundaries, page, setPage } = props;

  useEffect(() => {
    console.warn('PaginationComponent::total items:', totalItems);
    const { start, end } = getIndexBoundary(page, rowsPerPage, totalItems);
    setBoundaries((currState: SliceRange) => (currState = { start: start, end: end }));
  }, [page, rowsPerPage, totalItems]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    console.warn('new page triggered');
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //write a function that returns the start and end index of items you need to slice from list in parent component
  function getIndexBoundary(page: number, rowsPerPage: number, totalItems: number) {
    const expectedEnd = (page + 1) * rowsPerPage; // using slice so will need to use end + 1 on slice method to get correct range.
    const end = expectedEnd > totalItems ? totalItems : expectedEnd;
    const start = page === 0 ? 0 : page * rowsPerPage; // using slice so start of slice is on the index.

    return { start, end };
  }

  return (
    <TablePagination
      component="div"
      count={totalItems}
      page={page!}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage=""
      sx={{
        '& .MuiSelect-select': {
          display: 'none !important',
        },
        '& > div.MuiToolbar-root > div.MuiInputBase-root > svg': {
          display: 'none !important',
        },
      }}
    />
  );
};

export default PaginationComponent;
