import {
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarQuickFilter,
  MuiEvent,
} from '@mui/x-data-grid';
import React, { useState } from 'react';
import ReusableTableComponent from '../../ReportingComponents/Reports/ReportsTable';
import useTranslate from '../../../hooks/useTranslate';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useGridLocale from '../../../hooks/useGridLocale';
import useDetectResize from '../../../hooks/useDetectResize';
import { Individual } from '../../typescript/types';
import CustomMobileSearchBarComponent from '../../SharedComponents/CustomMobileSearchBarComponent';
import { getAllIndividuals } from '../../../store/individualSlice';
import { ViewTableContainer } from '../../UI/StyledComponents/GlobalStyledComponents';
import IndTabletCard from './IndTabletCard';
import MobileTableCard from '../../JobComponents/MobileTablet/MobileTableCard';
import useMobileTableWidths from '../../../hooks/useMobileTableWidths';

const IndividualMobileTable = () => {
  const indList = useSelector(getAllIndividuals);
  const [tableData, setTableData] = useState<Individual[]>(indList);
  const [pageSize, setPageSize] = React.useState(10);
  const t = useTranslate();
  let navigate = useNavigate();
  const gridLocaleSettings = useGridLocale();
  const { windowDimensions, isTablet, isMobile } = useDetectResize();
  const { tableWidth } = useMobileTableWidths({
    mobilePercent: 0.8,
    tabletPercent: 0.75,
    mediaWidth: windowDimensions.w,
    isMobile: isMobile,
  });

  const mobileCardHeight = {
    '.MuiDataGrid-row': {
      minHeight: '215px !important',
      cursor: 'pointer',
    },
    '.MuiDataGrid-cell': {
      minHeight: '215px !important',
      padding: '0px !important',
    },
    '.MuiDataGrid-virtualScrollerContent': {
      paddingTop: '10px !important',
    },
  };
  const tabletCardHeight = {
    '.MuiDataGrid-row': {
      minHeight: '245px !important', //100px for no skills/interests
      cursor: 'pointer',
    },
    '.MuiDataGrid-cell': {
      minHeight: '245px !important',
    },
    '.MuiDataGrid-virtualScrollerContent': {
      paddingTop: '10px !important',
    },
  };

  //For Table view
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('fullNameIndividual'),
      type: 'string',
      width: tableWidth,

      renderCell: (params: GridRenderCellParams) =>
        isMobile ? (
          <MobileTableCard obj={params.row as Individual} isJob={false} />
        ) : (
          <IndTabletCard ind={params.row as Individual} />
        ),
    },
  ];

  function displayData(
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) {
    //console.log(params.row); console.log(event); console.log(details);
    navigate(`${params.row.leadProviderOrgId}/${params.row.id}`, { state: params.row });
  }

  return (
    <ViewTableContainer height={windowDimensions.h}>
      {ReusableTableComponent(
        tableData,
        columns,
        pageSize,
        setPageSize,
        displayData,
        gridLocaleSettings,
        t,
        isMobile ? mobileCardHeight : tabletCardHeight,
        true,
        CustomMobileSearchBarComponent,
        true
      )}
    </ViewTableContainer>
  );
};

export default IndividualMobileTable;
