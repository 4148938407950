import { useNavigate, useParams } from 'react-router-dom';
import EmploymentSupportLeadCardComponent from '../EmployerComponents/EmploymentSupportLeadCardComponent';
import RecommendationsWidgetComponent from '../DashboardComponents/RecommendationsWidgetComponent';
import CompanyDetailsComponent from '../CompanyDetailsComponent';
import JobDescription from './JobDescription';
import Card from '../UI/Card/CardComponent';
import { Job } from '../typescript/types';
import { ArrowBackIos, EditOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getMyProviderDetails, getProviderById } from '../../store/providerSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getTags } from '../../store/keywordSlice';
import JobAttachments from './JobAttachments';
import { selectJobById } from '../../store/jobSlice';
import useTranslate from '../../hooks/useTranslate';
import {
  NavButtonWrapper,
  ViewColumn1,
  ViewColumn2,
  ViewComponentHeader,
} from '../UI/StyledComponents/ViewStyledComponents';
import HowToApply from './JobHowToApply';
import { OverviewWidget } from '../EmployerComponents/SharedWidgets/OverviewWidget';
import { ViewContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import { useGetJobByIdQuery } from '../../store/services/jobApi';

export default function JobComponent() {
  const { employerOrganizationName: orgId, id: jobId } = useParams();
  const { isLoading } = useGetJobByIdQuery({
    orgId: orgId!,
    jobId: jobId!,
  });
  const job = useSelector((rState: RootState) => selectJobById(rState, jobId!));
  const navigate = useNavigate();
  const leadProvider = useSelector((state: RootState) => getProviderById(state, job?.providerId));
  const t = useTranslate();
  const TAGS = useSelector((state: RootState) => getTags(state, t));
  const myProviderDetails = useSelector(getMyProviderDetails);

  if (isLoading) {
    return <div />;
  }

  return (
    <ViewContainer className="view-container-job">
      {/* {alertUser && <Modal onHide={()=>{setAlertUser(false)}} ><PopupMessageComponent message={employerNameMistmatch} closeModal={()=>{setAlertUser(false)}}/></Modal>} */}
      <ViewComponentHeader>
        <CompanyDetailsComponent
          avatarStr={job?.employerOrganizationName!}
          jobTitle={job?.title!}
          jobType={job?.jobType!}
          employerTitle={job?.employerOrganizationName!}
          img={job?.employerLogo}
          isOpenToWork={
            job?.closeDate === null && job?.isJobActive
              ? true
              : job?.closeDate !== null && !job?.isJobActive
                ? false
                : dayjs(job?.closeDate) > dayjs()
                  ? true
                  : false
          }
        />

        <NavButtonWrapper>
          <div className="go-back inline-btwn" onClick={() => navigate('/jobs')}>
            <Button
              variant="text"
              startIcon={<ArrowBackIos />}
              sx={{
                textTransform: 'none',
                color: '#050505',
                fontSize: '0.85em',
                fontWeight: '600',
                fontFamily: 'Nunito',
                lineHeight: '1.85',
                textAlign: 'left',
                padding: '0.5em 1em',
                '.MuiSvgIcon-root': {
                  color: '#4f5f71',
                },
                '@media (max-resolution: 1dppx) and (min-height: 1030px)': {
                  fontSize: '1.00em',
                },
              }}
            >
              {t('backBtnJobView')}
            </Button>
          </div>

          {myProviderDetails?.orgId === job?.providerOrgId && (
            <Button
              startIcon={<EditOutlined />}
              sx={{
                marginRight: '8px',
                textTransform: 'none',
                borderRadius: '16px',
                fontSize: '0.85em',
                fontWeight: '600',
                backgroundColor: '#0F1177',
                fontFamily: 'Nunito',
                padding: '0.5em 1em',
                '.MuiSvgIcon-root': {
                  fontSize: '2em',
                },
                '@media (max-resolution: 1dppx) and (min-height: 1030px)': {
                  fontSize: '1.00em',
                },
              }}
              variant="contained"
              onClick={() => navigate('edit', { state: job! })}
            >
              {t('jobViewEditBtn')}
            </Button>
          )}
        </NavButtonWrapper>
      </ViewComponentHeader>
      <Card className="employer-content">
        <ViewColumn1>
          <EmploymentSupportLeadCardComponent
            name={job?.providerName!}
            email={job?.providerEmail}
            phone={job?.providerPhone}
            photo={leadProvider?.photoUrl}
            orgId={job?.providerOrgId}
          />
          <Typography variant="h3">JOB OVERVIEW</Typography>
          {job && <OverviewWidget overViewOf="job" overViewObj={job as Job} />}
        </ViewColumn1>

        <ViewColumn2>
          <JobDescription job={job} />
          <HowToApply job={job} showTitle={true} />
          <JobAttachments job={job} showTitle={true} />
        </ViewColumn2>
      </Card>
      <div className="recommendations-section inline-btwn full-width">
        <RecommendationsWidgetComponent
          userState={job as Job}
          userType={'individualsToJob'}
          isIndividual={false}
        />
      </div>
    </ViewContainer>
  );
}
