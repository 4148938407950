import { Box, createTheme, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OverviewRowItem = styled(Box)(({ theme, alignItems }) => ({
  minHeight: '55px',
  width: '100%',
  display: 'inline-flex',
  alignItems: `${alignItems ? alignItems : 'center'}`,
  // get img attribute which is child of OverviewRowItem
  [theme.breakpoints.up('md')]: {
    margin: '10px 0',
  },
}));

export const OverviewTitle = styled(Typography)(({ theme }) => ({
  lineHeight: '1.7',
  fontWeight: '600',
}));

export const OverviewContainer = styled(Box)(({ theme }) => ({
  marginTop: '1em',
  display: 'table !important',
  padding: '0.2em 0.2em 0.7em 0.7em',

  [theme.breakpoints.up('md')]: {
    display: 'inline-flex',
  },

  [theme.breakpoints.down('sm')]: {
    padding: 'unset',
  },
}));

export const OverviewBadges = styled('span')(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: `${theme.palette.primary.light}`,
  padding: '0.2em 0.5em',
  fontWeight: 600,
  lineHeight: 1.33,
  color: `${theme.palette.text.primary} !important`,
  textAlign: 'center',
  display: 'inline-grid !important',
  textTransform: 'lowercase',
  margin: '1px 2px',
}));

export const ViewComponentHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: 'inherit',
  alignItems: 'flex-end',
  height: 'fit-content',
  minHeight: '110px',
  maxHeight: '140px',
  marginTop: '0.7em',

  ['@media(min-width: 1200px)']: {},
  ['@media(min-width: 1400px)']: {
    width: '98% !important',
  },
}));

export const NavButtonWrapper = styled(Box)(({ theme }) => ({
  height: 'fit-content',
  width: 'fit-content',
  display: 'flex',
  justifyContent: 'flex-end',
  [`& > div`]: {
    marginRight: '5px',
  },
}));

export const ViewColumn1 = styled(Box)(({ theme }) => ({
  width: '30%',
  minWidth: '300px',
  height: '100%',
  border: '2px solid var( --background)',
  borderStyle: 'none solid none none',
  overflow: 'scroll',
  scrollBehavior: 'smooth',
  padding: '15px',
  [`&::-webkit-scrollbar`]: {
    width: '4px',
    height: '0',
  },

  [`&::-webkit-scrollbar-track`]: {
    backgroundColor: '#d3d9de',
  },

  [`&::-webkit-scrollbar-thumb `]: {
    backgroundColor: 'var(--secondary-color)',
    borderRadius: '2.5px',
  },

  [`& > section`]: {
    marginBottom: '3em',
    marginTop: '1em',
    [`& > p`]: {
      fontFamily: 'Nunito',
      fontWeight: '500',
      lineHeight: '1.71',
      color: '#050505',
      textAlign: 'left',
    },
  },
}));

export const ViewColumn2 = styled(Box)(({ theme }) => ({
  width: '70%',
  overflow: 'auto',
  scrollBehavior: 'smooth',
  padding: '1.5em',

  [`&::-webkit-scrollbar`]: {
    width: ' 4px',
    height: '0',
  },

  [`&::-webkit-scrollbar-track`]: {
    backgroundColor: '#d3d9de',
  },

  [`&::-webkit-scrollbar-thumb `]: {
    backgroundColor: 'var(--secondary-color)',
    borderRadius: '2.5px',
  },

  [`& > section`]: {
    marginBottom: '3em',
    marginTop: '1em',
    [`& > p`]: {
      fontFamily: 'Nunito',
      fontWeight: '500',
      lineHeight: '1.71',
      color: '#050505',
      textAlign: 'left',
    },
  },
}));

export const RecommendationsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1740px',
  maxHeight: '685px',
  display: 'inline-grid',
  padding: '18px 10px 12px',
  gap: '10px',

  [theme.breakpoints.up('md')]: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    '& > div': {
      width: '32%',
    },
    padding: '0px',
  },
}));

export const RecommendationsCardInfo = styled(Box)(({ theme }) => ({
  minHeight: '90px',
  maxHeight: '150px',
  width: 'inherit',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '20px',

  '& h4': {
    width: '70%',
    fontFamily: 'LexendDeca',
    fontWeight: '600',
    textAlign: 'left',
    color: `${theme.palette.primary.main}`,
    lineHeight: '1.5',
  },

  '& img': {
    backgroundColor: '#0379A0',
    padding: '1em',
    width: '60px',
    height: '60px',
    maxWidth: '60px',
    maxHeight: '60px',
    borderRadius: '16px',
    marginLeft: '25px',
  },

  '@media (min-height: 768px) and (max-height: 1029px)': {
    minHeight: '90px',
    width: '26vw',

    '& h4': {
      fontSize: '0.8em',
    },
  },

  '@media(min-height: 901px)': {
    minHeight: '90px',
    width: '26vw',
  },

  '@media (min-height: 938px)': {
    '& h4': {
      fontSize: '1.00em',
    },
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '& h4': {
      color: '#050505',
    },
    padding: '15px',
  },
}));

export const RecommendationDetailsContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  minHeight: '300px',
  overflow: 'scroll',
  padding: '10px',
  scrollBehavior: 'smooth',
  paddingBottom: '100px',
  [theme.breakpoints.down('md')]: {
    margin: '10px 0',
  },
  [theme.breakpoints.up('md')]: {
    '@media(min-height: 759px)': {
      height: '100%',
    },
    '@media(min-height: 938px)': {
      height: '100%',
    },
    '& > h5': {
      marginTop: '1em',
      marginBottom: '0.75em',
    },
    '@media (min-resolution: 1.49dppx)': {
      marginTop: '10px',
      minHeight: '250px',
      height: '97%',
    },
    '@media(max-height: 700px)': {
      paddingTop: '20px',
      paddingBottom: '0px',
      minHeight: '0px',
    },
    '@media(max-height: 650px)': {
      paddingTop: '30px',
      paddingBottom: '0px',
      minHeight: '0px',
    },
    '@media(max-height: 600px)': {
      paddingTop: '40px',
      paddingBottom: '0px',
      minHeight: '0px',
    },
    '@media(max-height: 550px)': {
      paddingTop: '40px',
      paddingBottom: '0px',
      minHeight: '0px',
    },
    '@media(max-height: 500px)': {
      paddingTop: '40px',
      paddingBottom: '0px',
      minHeight: '0px',
    },
  },
}));

export const ActivityDetailsContainer = styled(RecommendationDetailsContainer)(({ theme }) => ({
  height: '97%',
}));

export const IndividualHeaderProgressBar = styled(Box)(({ theme }) => ({
  padding: '20px 10px 0px 10px',
  width: '100%',
  ['& > div.progress-text-box > span']: {
    color: 'black',
    fontSize: '16px',
  },
  ['& > div.recomm-metric-cont']: {
    justifyContent: 'flex-start',
    width: '100%',
    padding: '8px',

    ['& > div.metric-bar-outer']: {
      width: '100%',
      maxWidth: 'none',
      margin: '0px !important',

      ['& > div']: {
        background: 'linear-gradient(0deg, #6a6cbd 9%, rgb(41 41 111) 50%, #1313b8 81%);',
      },
    },
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '300px',

    margin: '0 auto',
    padding: '10px 10px 0px 10px',
  },
}));

export const DesktopActivityWrapper = styled(Box)(({ theme }) => ({
  maxHeight: '250px',
  minHeight: '200px',
  height: 'fit-content',
  border: '1px solid #E4F2F6',
  borderRadius: '15px',
  overflow: 'hidden',
  padding: '1em',
  margin: '1.5em 0 3em 0',
}));
export const ActivityLogDesktopContainer = styled(Box)(({ theme }) => ({}));

export const ActivityLogCardBox = styled(Box)(({ theme }) => ({
  padding: '0.5em 0em',
}));
