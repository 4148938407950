import React, { useEffect } from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Job } from '../typescript/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getCumulativeScore } from '../../store/jobSlice';

const MatchMeter = (props: { job: Job; indId: string }) => {
  const { job, indId } = props;

  const cumulativeScore = useSelector((state: RootState) =>
    getCumulativeScore(state, job.id, indId)
  );

  useEffect(() => {
    console.warn('job: ', job);
    console.warn('cumulative', cumulativeScore);
  }, [cumulativeScore]);

  return (
    <CircularProgressbar
      value={+cumulativeScore! > 100 ? 100 : Math.round(+cumulativeScore!)}
      text={+cumulativeScore! > 100 ? '100%' : `${Math.round(+cumulativeScore!)}%`}
      styles={{
        root: {
          width: 'auto',
          height: '80px',
          marginLeft: 'auto',
        },
        text: {
          fill: '#050505',
          fontWeight: 'bold',
        },
        path: {
          stroke:
            Math.round(+cumulativeScore!) > 50 && Math.round(+cumulativeScore!) < 70
              ? '#996600'
              : Math.round(+cumulativeScore!) > 69
              ? '#0f7553'
              : '#cc3300',
        },
      }}
    />
  );
};

export default MatchMeter;
