import { Box, Typography } from '@mui/material';
import { capitalize } from '../../../helpers/helperFunctions';
import { LargePuffLoader } from '../Animation/PuffLoader';

const HideFormOverlayComponent = (props: { noun: string }) => {
  const { noun } = props;
  return (
    <Box className="waiting-overlay">
      <Box
        className="waiting-text"
        sx={{
          display: 'inline-grid',
          justifyItems: 'center',
        }}
      >
        <LargePuffLoader />
        <br />
        <Typography variant="h1">{capitalize(noun)} </Typography>
      </Box>
    </Box>
  );
};

export default HideFormOverlayComponent;
