import { Alert } from "@mui/material";
import { CharCountBox } from "../UI/StyledComponents/NotificationModalStyledComponents";
import { useEffect } from "react";

const CharCountAlert = (props: { value: string | undefined }) => {
    const { value } = props;

    useEffect(() => {
        console.log("Value changed: ", value);
    }, [value]);

    // value includes the <p> tags that surround the text so this discounts the 7 extra characters that come with it.
    const adjustedLength = value ? value.length - 7 : 0;

    return (
        <CharCountBox>
        {value && adjustedLength >= 3000 && (
          <Alert
            severity="warning"
            sx={{ marginTop: '5px', marginBottom: '5px', minWidth: '210px' }}
          >
            Character count: {adjustedLength}/3000
          </Alert>
        )}
      </CharCountBox>
    );
  };
  
  export default CharCountAlert;