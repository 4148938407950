import { Avatar, Box, Typography } from '@mui/material';
import { InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import { Individual } from '../typescript/types';
import { getFullName, stringAvatar } from '../../helpers/helperFunctions';
import { DisplayBarUI } from '../RecommendationComponents/RecommendationsCard';
import useProfileCompletion from '../../hooks/useProfileCompletion';
import useTranslate from '../../hooks/useTranslate';

interface ActivityProps {
  details: Individual;
}

const ActivityCard = (props: ActivityProps) => {
  const { details } = props;
  const score = useProfileCompletion(details);

  return (
    <Box
      className="parent-activity-card"
      key={details.id}
      sx={{
        padding: '10px',
        cursor: 'pointer',
      }}
    >
      <InlineContainer justifyContent={'space-between'}>
        <InlineContainer justifyContent="flex-start">
          <Avatar {...stringAvatar(getFullName(details!))} />
          <Typography fontWeight={600}>{getFullName(details)}</Typography>
        </InlineContainer>
        <Box width="40%">
          <ProfileProgressBar background={'#313131'} score={score} />
        </Box>
      </InlineContainer>
    </Box>
  );
};

export default ActivityCard;

export const ProfileProgressBar = (props: { background: string; score: number }) => {
  const t = useTranslate();

  return (
    <>
      <Box
        className="progress-text-box"
        sx={{
          background: props.background,
          width: 'fit-content',
          padding: '4px 8px',
          borderRadius: '8px',
          lineHeight: 1,
        }}
      >
        <Typography variant="caption" color="white">
          {t('db-profileCompletionTitle')}: {props.score.toFixed(0)}%
        </Typography>
      </Box>
      <DisplayBarUI score={props.score} />
    </>
  );
};
