import * as React from 'react';
import { useSelector } from 'react-redux';
import { AsyncThunk } from '@reduxjs/toolkit';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useTranslate from '../../../hooks/useTranslate';
import {
  FormStepperContainer,
  FormSubmitContainer,
  PanelDescriptor,
  SaveButtonContained,
  ButtonOutlined,
} from '../../UI/StyledComponents/FormsStyledComponents';
import { getMyProviderDetails } from '../../../store/providerSlice';
import useDetectResize from '../../../hooks/useDetectResize';
import { useRef } from 'react';
import useFocusTrap from '../../../hooks/useFocusTrap';
import { Stack } from '@mui/system';
import { RootState } from '../../../store';
import { selectJobById } from '../../../store/jobSlice';
import DeleteResourceComponent from '../../SharedComponents/DeleteResourceComponent';
import { Employer, Individual, Job } from '../../typescript/types';
import { Tooltip } from '@mui/material';

interface StepperProps {
  steps: string[];
  children: any;
  disableFormSubmitOrg?: any;
  goToPanel: (step: number) => any;
  isEdit: boolean;
  getValues: any;
  deleteThisRecordId: string;
  hasDeleteAccess?: boolean;
  deleteThisRecordMsg?: string;
  deleteThisRecordFunc?: AsyncThunk<any, any, any>;
  tags: number[];
  isMobileTabletWidth: boolean;
  stackStepsTitle: boolean;
  isFormType: 'employer' | 'job' | 'individual';
  record?: Employer | Job | Individual | null;
  setDisplayCompletedMsg?: React.Dispatch<React.SetStateAction<boolean>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleSubmit: any;
}

const StepperWidget = (props: StepperProps) => {
  const {
    steps,
    children,
    disableFormSubmitOrg,
    goToPanel,
    isEdit,
    getValues,
    deleteThisRecordId,
    deleteThisRecordMsg,
    deleteThisRecordFunc,
    hasDeleteAccess,
    tags,
    isMobileTabletWidth,
    stackStepsTitle,
    isFormType,
    setDisplayCompletedMsg,
    activeStep,
    setActiveStep,
    handleSubmit,
    record,
  } = props;

  const t = useTranslate();
  const warnUserMsg = t('deleteAllJobWarningMsg');
  const myDetails = useSelector(getMyProviderDetails);

  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const { isLaptop, isDesktop, isTablet } = useDetectResize();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    //watch fields in rhf to determine if completed.
    // return boolean.
    return Object.keys(completed).length;
  };

  const notifyUserRequiredFieldsCompleted = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    //handleComplete();
    const newActiveStep = +activeStep === steps.length - 1 ? activeStep : +activeStep + 1;
    console.log(`Go FORWARD: Current Panel: ${activeStep}. Next Panel: ${newActiveStep}`);
    goToPanel(newActiveStep);
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    let step = +activeStep === 0 ? 0 : +activeStep - 1;
    console.log(`Go BACK: Current Panel: ${+activeStep}. Reverse to Panel: ${+step}`);
    goToPanel(step);
    setActiveStep((prevActiveStep: number) => (prevActiveStep = step));
  };

  const handleStep = (step: number) => () => {
    goToPanel(step);
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const form_steps = useRef<null | HTMLDivElement>(null);

  useFocusTrap(form_steps);

  const canDelete =
    (isFormType === 'job' || isFormType === 'individual') &&
    deleteThisRecordFunc &&
    hasDeleteAccess;

  const CustomTooltip = ({ children }: { children: React.ReactNode }) => {
    return (
      <Tooltip
        title={
          <Typography variant="h2" style={{ color: 'white' }}>
            {t('closeJobTooltip')}
          </Typography>
        }
        followCursor
        placement="top"
      >
        <span>{children}</span>
      </Tooltip>
    );
  };

  const DeleteButton = ({ disabled }: { disabled: boolean }) => {
    if (!disabled) {
      return (
        <DeleteResourceComponent
          disabled={false}
          recordId={deleteThisRecordId!}
          warnUserMsg={deleteThisRecordMsg!}
          deleteAsyncThunkFunc={deleteThisRecordFunc!}
          subject={isFormType}
          customStyle={{ backgroundColor: '#d32f2f !important', color: 'white' }}
        />
      );
    }
    return (
      <CustomTooltip>
        <DeleteResourceComponent
          disabled={true}
          recordId={deleteThisRecordId!}
          warnUserMsg={deleteThisRecordMsg!}
          deleteAsyncThunkFunc={deleteThisRecordFunc!}
          subject={isFormType}
        />
      </CustomTooltip>
    );
  };

  return (
    <Box sx={{ width: 1 }}>
      <FormStepperContainer
        border={isMobileTabletWidth ? 'none' : '2px solid #D9E1E7'}
        gridTemplateColumns="100%"
        className="step-contain"
        ref={form_steps}
      >
        <PanelDescriptor textAlign={'center'} width="100%">
          <Typography variant="h3" width="100%" marginBottom="5px">
            {steps[activeStep]}
          </Typography>
        </PanelDescriptor>

        <Stepper
          sx={{
            borderRadius: '10px',
            padding: isMobileTabletWidth ? '0' : '5px !important',
            width: '100%',
            maxWidth: '800px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          nonLinear
          alternativeLabel={stackStepsTitle}
          activeStep={+activeStep}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {isTablet || isLaptop || isDesktop ? label : null}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </FormStepperContainer>
      <React.Fragment>
        <>{children}</>
        <FormSubmitContainer>
          <Box>
            {activeStep !== 0 ? (
              <ButtonOutlined onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </ButtonOutlined>
            ) : (
              <Box sx={{ width: '4rem' }} />
            )}
          </Box>
          <div>
            <Stack spacing={2} direction={isMobileTabletWidth ? 'column-reverse' : 'row'}>
              {canDelete && <DeleteButton disabled={(record as Job)?.isJobActive} />}
              <SaveButtonContained onClick={handleSubmit}>
                {(isEdit
                  ? t('submitSettingsBtn').toUpperCase() + ' '
                  : t('submitSaveBtn').toUpperCase() + ' ') +
                  (isFormType === 'employer'
                    ? t('employerBtnText').toUpperCase()
                    : isFormType === 'job'
                      ? t('jobBtnText').toUpperCase()
                      : t('individualBtnText').toUpperCase())}
              </SaveButtonContained>
            </Stack>
          </div>
          <Box>
            {activeStep !== steps.length - 1 ? (
              <ButtonOutlined
                onClick={() => {
                  handleNext();
                }}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                Next
              </ButtonOutlined>
            ) : (
              <Box sx={{ width: '4rem' }} />
            )}
          </Box>
        </FormSubmitContainer>
      </React.Fragment>
    </Box>
  );
};

export default StepperWidget;
