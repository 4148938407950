import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import type { RootState } from './index';

interface AuthSlice {
  user: any;
  token: string | null;
  active: boolean;
}

export interface AWSBucketProps {
  orgId: string;
  fileName: string;
  contentType: string;
  subFolder: string;
  fileType: string;
  token: string;
  creator?: string;
}

interface AWSDeleteObjectProps {
  key: string;
  token: string;
}

const initialState: AuthSlice = {
  user: null,
  token: null,
  active: false,
};

export const getSignedUrl = createAsyncThunk(
  '/auth/getSignedUrl',
  async (params: AWSBucketProps) => {
    try {
      return axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/file-upload`, {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          params: {
            orgId: params.orgId,
            fileName: params.fileName,
            contentType: params.contentType,
            subFolder: params.subFolder,
            fileType: params.fileType,
            creator: params.creator,
          },
        })
        .then((response) => {
          console.log(response);
          return response.data;
        })
        .catch((err) => {
          return err.message;
        });
    } catch (e) {
      console.info('Auth AWS error: ', e);
    }
  }
);

export const deleteSignedUrl = createAsyncThunk(
  '/auth/deleteBuckObject',
  async (params: AWSDeleteObjectProps) => {
    try {
      return axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/v1/single-delete`, {
          headers: {
            Authorization: `Bearer ${params.token}`,
          },
          params: {
            key: params.key,
          },
        })
        .then((response) => {
          console.log(response);
          return response.data;
        })
        .catch((err) => {
          return err.message;
        });
    } catch (e) {
      console.info('AWS delete error: ', e);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state: AuthSlice, action: PayloadAction<{ user: any; token: string }>) => {
      // console.info('AuthSlice: ', action.payload);
      return {
        user: action.payload.user,
        token: action.payload.token,
        active: true,
      };
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSignedUrl.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { setCredentials, logOut } = authSlice.actions;
export const authActions = authSlice.actions;
export const selectUser = (state: RootState) => state.auth.user;
export const getAccessToken = (state: RootState) => state.auth.token;
export const tokenGranted = (state: RootState) => state.auth.active;

export default authSlice.reducer;
