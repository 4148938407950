import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import {
  StickyRecoFilterContainer,
  ToggleFilterButton,
} from '../UI/StyledComponents/RecommendationStyledComponent';
import { RecommendationDetailsContainer } from '../UI/StyledComponents/ViewStyledComponents';
import EmploymentSupportLeadDetails from '../RecommendationComponents/EmploymentSupportLeadDetails';
import { ContactInfo } from '../RecommendationComponents/RecommendationsComponent';
import { useAppDispatch, useAppSelector } from '../typescript/hooks';
import { B2CInfo, getB2CActivity, removeAppliedJob } from '../../store/individualSlice';
import { RootState } from '../../store';
import { getFullName } from '../../helpers/helperFunctions';
import ActivityFeedCard from './ActivityFeedCard';
import { Individual, Job } from '../typescript/types';
import { getAllJobs } from '../../store/jobSlice';
import {
  ActivityCardHolder,
  ActivityInfoContainer,
  InlineContainer,
} from '../UI/StyledComponents/GlobalStyledComponents';
import useDetectResize from '../../hooks/useDetectResize';
import PuffLoader from '../UI/Animation/PuffLoader';
import useTranslate from '../../hooks/useTranslate';
import { getMyProviderDetails } from '../../store/providerSlice';
import { getAccessToken } from '../../store/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';

interface ActivityInfo {
  isVisible: boolean;
  setIsVisible: (value: React.SetStateAction<boolean>) => void;
  info: Individual | null;
  leadProvider: ContactInfo;
  activity: Activity;
}

enum Activity {
  Overview,
  Liked,
  Applied,
}

const RecommendationsOverviewComponent = React.lazy(
  () => import('../RecommendationComponents/RecommendationOverviewComponent')
);

const ActivityInfoComponent = (props: ActivityInfo) => {
  const { isVisible, info, leadProvider, setIsVisible } = props;
  const [toggle, setToggle] = useState<Activity>(props.activity);
  const b2cArr: B2CInfo[] = useAppSelector((state: RootState) => getB2CActivity(state));
  const [b2cJobs, setB2CJobs] = useState<any>(null);
  const { isMobile, isTablet } = useDetectResize();

  useEffect(() => {
    if (info) {
      const specificB2CObject = b2cArr?.find((b2c: B2CInfo) => b2c.indId === info.id);
      setB2CJobs(
        (currState: any) =>
          (currState = {
            liked: specificB2CObject?.likedJobs!,
          })
      );
    }
  }, [info]);

  const ActivityInfoSelections = () => {
    switch (toggle) {
      case Activity.Overview:
        return <ActivityOverview ind={props.info!} />;
      case Activity.Liked:
        return <JobsLiked likedJobs={b2cJobs?.liked!} indId={props.info?.id!} />; // get array of liked jobs from b2c_user
      case Activity.Applied:
        return <ApplicationStarted indId={props.info?.id!} />; //get array of applied jobs form b2c_user
    }
  };

  const ClosePopupContainer = () => {
    return (
      <Box
        onClick={() => {
          setIsVisible((currState) => (currState = false));
        }}
        sx={{
          display: 'flex',
          border: `2px solid black`,
          borderRadius: '50%',
          color: 'black',
          fontWeight: 600,
          marginBottom: 'auto',
        }}
      >
        <CloseIcon />
      </Box>
    );
  };

  return (
    <ActivityInfoContainer className="activity-info-cont recomm-column2 scrollable-hidden">
      {isVisible ? (
        <>
          <Box>
            <InlineContainer
              className="af-1"
              justifyContent="space-between"
              alignItems="flex-end"
              padding="10px 15px"
              position="relative"
            >
              <div>
                <Typography variant="h2" padding="10px">
                  {getFullName(props.info!)}
                </Typography>
              </div>
              <div>
                <Button
                  component={RouterLink}
                  to={`/individuals/${props.info?.leadProviderOrgId}/${props.info?.id}`}
                  sx={{
                    textTransform: 'none',
                    color: '#050505',
                    fontSize: '0.85em',
                    fontWeight: '600',
                    fontFamily: 'Nunito',
                    lineHeight: '1.85',
                    textAlign: 'left',
                    padding: '0.5em 1em',
                    '.MuiSvgIcon-root': {
                      color: '#4f5f71',
                    },
                    '@media (max-resolution: 1dppx) and (min-height: 1030px)': {
                      fontSize: '1.00em',
                    },
                  }}
                >
                  Go to Profile
                </Button>
              </div>

              {(isMobile || isTablet) && <ClosePopupContainer />}
            </InlineContainer>

            <ToggleActivityInfo setToggle={setToggle} selection={toggle} />
          </Box>

          <ActivityInfoSelections />

          <EmploymentSupportLeadDetails
            provider={leadProvider!}
            isVisible={isVisible}
            photo={leadProvider?.photoUrl!}
            orgId={leadProvider?.orgId!}
          />
        </>
      ) : (
        <RecommendationDetailsContainer
          height="inherit !important"
          className="details-cover hide-scroll"
        />
      )}
    </ActivityInfoContainer>
  );
};

export default ActivityInfoComponent;

const ToggleActivityInfo = (props: {
  setToggle: (type: Activity) => void;
  selection: Activity;
}) => {
  const { setToggle, selection } = props;
  const { isMobile, isTablet } = useDetectResize();
  const t = useTranslate();

  return (
    <StickyRecoFilterContainer className="sticky-recom-toggle-activity">
      <Box
        className="recomm-toggle-container inline-btwn"
        width={isMobile ? '95% !important' : isTablet ? '75% !important' : '90% !important'}
      >
        <ToggleFilterButton
          id="location-filter-tag"
          variant={selection === Activity.Overview ? 'contained' : 'text'}
          size="small"
          onClick={() => {
            setToggle(Activity.Overview);
          }}
        >
          <Typography
            variant="body1"
            id="location-filter-tag"
            className="text-overflow reco-filter-text"
          >
            Overview
          </Typography>
        </ToggleFilterButton>

        <ToggleFilterButton
          variant={selection === Activity.Liked ? 'contained' : 'text'}
          size="small"
          onClick={() => {
            setToggle(Activity.Liked);
          }}
        >
          <Typography
            variant="body1"
            id="aligned-filter-tag"
            className="text-overflow reco-filter-text"
          >
            {t('jobs-liked')}
          </Typography>
        </ToggleFilterButton>

        <ToggleFilterButton
          variant={selection === Activity.Applied ? 'contained' : 'text'}
          size="small"
          onClick={() => {
            setToggle(Activity.Applied);
          }}
        >
          <Typography
            variant="body1"
            id="cumulative-filter-tag"
            className="text-overflow reco-filter-text"
          >
            {t('jobs-applications')}
          </Typography>
        </ToggleFilterButton>
      </Box>
    </StickyRecoFilterContainer>
  );
};

const ActivityOverview = (props: { ind: Individual }) => {
  return (
    <React.Suspense fallback={<PuffLoader />}>
      <RecommendationsOverviewComponent
        selectedItem={props.ind! as Individual}
        userType="individualsToJob"
        isVisible={true}
        isActivityFeedView={true}
      />
    </React.Suspense>
  );
};

const JobsLiked = (props: { likedJobs: { id: string; ts: string }[] | null; indId: string }) => {
  const { likedJobs, indId } = props;
  const allJobs = useSelector((state: RootState) => getAllJobs(state));
  const t = useTranslate();

  return (
    <ActivityCardHolder className="ac-liked-jobs hide-scroll">
      {likedJobs?.length === 0 || likedJobs === null || likedJobs === undefined ? (
        <InlineContainer>
          <Typography variant="subtitle2">{t('no-like')}</Typography>
        </InlineContainer>
      ) : (
        likedJobs?.map((likedJob: { id: string; ts: string }) => {
          console.warn('Search for: ', likedJob.id);
          return (
            <ActivityFeedCard
              key={likedJob.id!}
              job={allJobs.find((job: Job) => job.id === likedJob.id)!}
              type={'interest'}
              likedJobs={likedJob}
              indId={indId!}
            />
          );
        })
      )}
    </ActivityCardHolder>
  );
};

const ApplicationStarted = (props: { indId: string }) => {
  const { indId } = props;
  const allJobs = useSelector((state: RootState) => getAllJobs(state));
  const b2cArr: B2CInfo[] = useAppSelector((state: RootState) => getB2CActivity(state));
  const [appliedJobs, setAppliedJobs] = useState<any>(
    b2cArr?.find((b2c: B2CInfo) => b2c.indId === indId)!.appliedJobs
  );
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const myDetails = useAppSelector(getMyProviderDetails);
  const token = useAppSelector(getAccessToken);

  const archiveJob = (appliedJob: any) => {
    dispatch(
      removeAppliedJob({
        myId: indId,
        jobId: appliedJob!.id!,
        orgId: myDetails?.orgId!,
        provId: myDetails?.id!,
        token: token!,
      })
    )
      .then(unwrapResult)
      .then((res: { data: { appliedJobsArr: any; status: string } }) => {
        console.log(appliedJobs);
        console.log(res.data.appliedJobsArr);
        setAppliedJobs(res.data.appliedJobsArr);
      });
  };

  return (
    <>
      <ActivityCardHolder className="ac-applied-jobs hide-scroll">
        {appliedJobs === null || appliedJobs === undefined || appliedJobs.length === 0 ? (
          <InlineContainer>
            <Typography variant="subtitle2">{t('no-apply')}</Typography>
          </InlineContainer>
        ) : (
          appliedJobs?.map((appliedJob: any) => {
            return (
              <ActivityFeedCard
                key={appliedJob.id}
                job={allJobs.find((job: Job) => job.id === appliedJob.id)!}
                type={'applied'}
                appliedJob={appliedJob}
                indId={indId}
                archiveJob={() => archiveJob(appliedJob)}
              />
            );
          })
        )}
      </ActivityCardHolder>
    </>
  );
};

ActivityInfoComponent.defaultProps = {
  activity: 0,
};
