import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { nanoid, unwrapResult } from '@reduxjs/toolkit';

import {
  allNotifications,
  markAllNotificationsHidden,
  markAllNotificationsRead,
  thunkMarkNotificationsAllRead,
  thunkMarkNotificationsHideAll,
} from '../../store/notificationSlice';
import NotificationCard from './NotificationCard';
import { Notification } from '../typescript/types';
import { useAppDispatch } from '../typescript/hooks';
import { getMyProviderDetails } from '../../store/providerSlice';
import useTranslate from '../../hooks/useTranslate';
import {
  ModalNotificationDropDown,
  NotificationCardContainer,
  NotificationContainer,
  NotificationGrid,
} from '../UI/StyledComponents/NotificationModalStyledComponents';
import useDetectResize from '../../hooks/useDetectResize';

const filterNotifications = (
  notifications: Notification[],
  providerID: string,
  providerFirstName: string,
  providerLastName: string,
  providerOrgID: string
) => {
  return notifications.filter((notification) => {
    return !(
      (notification.notificationType === 'INDIVIDUAL_ASSIGNED' &&
        notification.notificationNoun !== providerID) ||
      (notification.notificationType === 'INDIVIDUAL_MATCH' &&
        notification.notificationNoun !== providerID) ||
      (notification.notificationType === 'INDIVIDUAL_B2C_NEW' &&
        notification.notificationNoun !== `${providerFirstName} ${providerLastName}`) ||
      (notification.notificationType === 'INDIVIDUAL_B2C_UPDATE' &&
        notification.notificationNoun2 !== providerOrgID)
    );
  });
};

const NotificationComponent = (props: {
  closeModal: (value: React.SetStateAction<boolean>) => void;
}) => {
  const myProvider = useSelector(getMyProviderDetails);
  const notifications = filterNotifications(
    useSelector(allNotifications!),
    myProvider.id!,
    myProvider.firstName,
    myProvider.lastName,
    myProvider.orgId
  );
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [visibleCount, setVisibleCount] = useState<number>(0);
  const { isMobile, isTablet } = useDetectResize();
  const dispatch = useAppDispatch();
  const t = useTranslate();

  useEffect(() => {
    let readCount = notifications.filter((notification) => !notification.isRead).length;
    let visibleCount = notifications.filter((notification) => notification.isVisible).length;
    setNotificationCount(readCount);
    setVisibleCount(visibleCount);
  }, [notifications]);

  const markAllRead = () => {
    if (notificationCount > 0) {
      dispatch(thunkMarkNotificationsAllRead(myProvider.id))
        .then(unwrapResult)
        .then((status) => {
          status === 200
            ? dispatch(markAllNotificationsRead(null))
            : alert(
                'Sorry, something went wrong, check your internet connection and please try again.'
              );
        });
    }
  };

  const hideAll = () => {
    if (visibleCount > 0) {
      dispatch(thunkMarkNotificationsHideAll(myProvider.id))
        .then(unwrapResult)
        .then((status) => {
          status === 200
            ? dispatch(markAllNotificationsHidden(null))
            : alert(
                'Sorry, something went wrong, check your internet connection and please try again.'
              );
        });
    }
  };

  return (
    <ModalNotificationDropDown>
      <NotificationGrid
        container
        sx={{
          width: isMobile || isTablet ? '96vw' : 'min-content',
          padding: '5px',
        }}
      >
        <Grid sx={{ width: '100%' }}>
          <Box className="notif-dropdown-header">
            <Typography
              className="notif-dropdown-title"
              variant="h2"
              textTransform={'uppercase'}
              color={'#050505'}
            >
              {t('notificationTitle')}
            </Typography>
            <Box className="notif-panel">
              <Typography variant="body2" className="notif-dropdown-unreadCount">
                {t('notificationCountTextI')}{' '}
                <span className="notif-count">{notificationCount}</span>{' '}
                {t('notificationCountTextII')}{' '}
              </Typography>
              <Button
                className="notif-dropdown-markAllAsRead"
                variant="text"
                onClick={(e) => {
                  hideAll();
                  e.stopPropagation();
                }}
              >
                {t('filterKeyEventsBtn')}
              </Button>
            </Box>
          </Box>
          <Box className="dropdown-line"></Box>
        </Grid>

        <Grid sx={{ width: '100%' }}>
          <Typography
            variant="h5"
            textTransform={'uppercase'}
            color={'#050505'}
            className="notif-dropdown-earlier"
          >
            {t('notificationText')}
          </Typography>
          <Box className="notif-dropdown-notifs hide-scroll">
            {visibleCount > 0 ? (
              notifications.map((data: Notification) => {
                return (
                  data.alertUser !== -1 && (
                    <NotificationCard
                      key={nanoid()}
                      notification={data}
                      closeModal={props.closeModal}
                    />
                  )
                );
              })
            ) : (
              <NotificationCardContainer>
                <NotificationContainer>
                  <Typography className="notification-empty-message notif-noun" variant="body2">
                    {t('noNotifications')}
                  </Typography>
                </NotificationContainer>
              </NotificationCardContainer>
            )}
          </Box>
          <Box className="notif-dropdown-seeAll">
            <Button
              className="notif-dropdown-markAllAsRead"
              variant="text"
              onClick={(e) => {
                markAllRead();
                e.stopPropagation();
              }}
            >
              {t('notificationMarkAllBtn')}
            </Button>
          </Box>
        </Grid>
      </NotificationGrid>
    </ModalNotificationDropDown>
  );
};

export default NotificationComponent;
