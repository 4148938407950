import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { BackdropOverlay, InlineContainer } from '../UI/StyledComponents/GlobalStyledComponents';
import { Button, Divider, Typography } from '@mui/material';
import Icon from '../../asssets/icons';
import {
  ActivityLogDesktopContainer,
  DesktopActivityWrapper,
} from '../UI/StyledComponents/ViewStyledComponents';
import { useSelector } from 'react-redux';
import { getAllActivity, getIndividualActivity, setActivityList } from '../../store/activitySlice';
import { getAccessToken } from '../../store/authSlice';
import { RootState } from '../../store';
import { useAppDispatch } from '../typescript/hooks';
import PuffLoader from '../UI/Animation/PuffLoader';
import { ActivityProp } from '../typescript/types';
import ActivityLogComponent, { ActivityLogCard } from './ActivityLogComponent';
import Modal from '../UI/Popup/BackdropComponent';
import dayjs from 'dayjs';

const ActivityLogDesktopComponent = (props: { indId: string }) => {
  const { indId } = props;
  const token = useSelector(getAccessToken);
  const dispatch = useAppDispatch();
  const mostRecentActivity = useSelector(getAllActivity);
  const [fecthActivityData, setFetchActivityData] = useState<boolean>(false);
  const [showActivityLogModal, setShowActivityLogModal] = useState<boolean>(false);

  useEffect(() => {
    console.warn(mostRecentActivity);
  }, [mostRecentActivity]);

  const getRecentActivity = () => {
    dispatch(getIndividualActivity({ indId: indId, token: token! }))
      .unwrap()
      .then((res) => {
        dispatch(setActivityList(res));
        setFetchActivityData(true);
      });
  };

  return (
    <>
      <ActivityLogDesktopContainer className="hide-scroll">
        <InlineContainer alignItems={'center'}>
          <InlineContainer justifyContent="flex-start">
            <Box
              component="img"
              src={Icon.AttachmentIcon}
              alt="doc-icon"
              sx={{ marginRight: '1em' }}
            />
            <Typography variant="h3" color="black">
              {'Activity Log'.toUpperCase()}
            </Typography>
          </InlineContainer>
          <Box
            sx={{
              width: 'fit-content',
              ['& > p']: {
                textDecoration: 'underline',
              },
              ['&:hover']: {
                transform: 'scale(1.05)',
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              setShowActivityLogModal(true);
            }}
          >
            {fecthActivityData && (
              <Typography variant="body1" width="max-content" marginRight="1em">
                View full activity log
              </Typography>
            )}
          </Box>
        </InlineContainer>
        <DesktopActivityWrapper className="dsktp-activity-cont">
          {fecthActivityData ? (
            <React.Suspense fallback={<PuffLoader />}>
              <>
                {mostRecentActivity.length > 0 ? (
                  [...mostRecentActivity]
                    ?.sort(
                      (a: ActivityProp, b: ActivityProp) =>
                        dayjs(b.timestamp!).unix() - dayjs(a.timestamp!).unix()
                    )
                    .map((item: ActivityProp, index: number) => {
                      console.warn('activity', item);
                      if (index < 6) {
                        return (
                          <ActivityLogCard key={`activity-${item.activityId}`} activity={item!} />
                        );
                      }
                    })
                ) : (
                  <Typography>No recent activity found.</Typography>
                )}
              </>
            </React.Suspense>
          ) : (
            <InlineContainer sx={{ height: '200px' }}>
              <Button
                variant="contained"
                onClick={() => {
                  getRecentActivity();
                }}
              >
                Show Recent Activity
              </Button>
            </InlineContainer>
          )}
        </DesktopActivityWrapper>
      </ActivityLogDesktopContainer>
      {showActivityLogModal && (
        <Modal
          className="activity-modal"
          onHide={() => {
            setShowActivityLogModal(false);
          }}
        >
          <BackdropOverlay
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ActivityLogComponent
              indId={indId}
              hideModal={() => {
                setShowActivityLogModal(false);
              }}
            />
          </BackdropOverlay>
        </Modal>
      )}
    </>
  );
};

export default ActivityLogDesktopComponent;
