import { useEffect, useState } from 'react';
import { Individual, IndividualFormData } from '../components/typescript/types';

const useActivityTracker = (previousRecord: Individual, currentRecord: IndividualFormData) => {
  const [activityRecords, setActivityRecords] = useState<{ field: string; value: string }[]>([]);
  const deepCopy = JSON.parse(JSON.stringify(previousRecord || {}));
  const propertiesWeDontWantArray = [
    'referenceAttachments',
    'certificationAttachments',
    'fileAttachments',
    'restrictions',
    'setOpenTS',
    'profileImg',
    'longitude',
    'latitude',
  ]; // if you change this, don't forget to update both b2c

  useEffect(() => {
    //function buildActivityRecord():updatedRecord;
    if (currentRecord !== null) {
      buildActivityRecord(currentRecord);
    }
  }, [currentRecord]);

  function buildActivityRecord(current: IndividualFormData) {
    let results: any[] = [];

    const isArrayEqual = (x: any, y: any) => {
      return x.trim() === y.trim();
    };

    //iterate over object and return all 'non-matching' properties as the results. Return activityRecord to then be dispatched to activity_report table.
    for (let field in deepCopy) {
      if (propertiesWeDontWantArray.includes(field) === false) {
        //TODO, detect changes for these && field !== 'skills' && field !== 'interests'  && field !== 'daysAvailable'
        if (field === 'employmentRecord') {
          if (
            !isArrayEqual(
              current[field as keyof IndividualFormData],
              JSON.stringify(deepCopy[field])
            )
          ) {
            results.push({ field: field, value: current[field] });
          }
        } else if (field === 'skills' || field === 'interests' || field === 'daysAvailable') {
          const changeDetected =
            JSON.stringify(current[field as keyof IndividualFormData]) !==
            JSON.stringify(deepCopy[field]);
          console.warn(`Comparing arrays for ${field}`);
          console.info(
            JSON.stringify(deepCopy[field]),
            JSON.stringify(current[field as keyof IndividualFormData])
          );

          if (changeDetected && field !== 'daysAvailable') {
            getAddedTags(
              current[field as keyof IndividualFormData] as number[],
              deepCopy[field],
              field
            );
            getRemovedTags(
              current[field as keyof IndividualFormData] as number[],
              deepCopy[field],
              field
            );
          } else if (changeDetected && field === 'daysAvailable') {
            results.push({ field: field, value: current[field] });
          }
        } else if (current[field as keyof IndividualFormData] !== deepCopy[field]) {
          console.warn(`Found a change for: ${field}`);
          results.push({ field: field, value: current[field as keyof IndividualFormData] });
        }
      }
    }

    setActivityRecords((currState: { field: string; value: string }[]) => (currState = results));
    // console.log(results);

    function getAddedTags(newTagList: number[], oldTagList: number[], fieldName: string) {
      let addedTags: number[] = [];

      newTagList.forEach((currentTag: number) => {
        !oldTagList.includes(currentTag) && addedTags.push(currentTag);
      });

      //differentiate between singular/plural noun as it will be used to build activity reporting text. prepend added/removed as on-the-fly tags.
      if (addedTags.length > 0)
        results.push({
          field: `${addedTags.length > 1 ? fieldName : fieldName.slice(0, -1)}-added`,
          value: addedTags,
        });
    }

    function getRemovedTags(newTagList: number[], oldTagList: number[], fieldName: string) {
      let removedTags: number[] = [];

      oldTagList.forEach((previousTag: number) => {
        !newTagList.includes(previousTag) && removedTags.push(previousTag);
      });

      if (removedTags.length > 0)
        results.push({
          field: `${removedTags.length > 1 ? fieldName : fieldName.slice(0, -1)}-removed`,
          value: removedTags,
        });
    }
  }

  return {
    activityRecords,
  };
};

export default useActivityTracker;
