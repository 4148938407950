import { Box, Paper, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Icon from '../../asssets/icons';
import { convertTagsToString } from '../../helpers/displayOptions';
import {
  baseTagStyling,
  interestStyling,
  restrictionStyling,
  skillStyling,
} from '../../helpers/styling';
import { getTags } from '../../store/keywordSlice';
import { Individual } from '../typescript/types';
import useTranslate from '../../hooks/useTranslate';
import { RootState } from '../../store';
import useDetectResize from '../../hooks/useDetectResize';
import { OverviewTitle } from '../UI/StyledComponents/ViewStyledComponents';

const IndividualSkillsInterestsRestrictions = (props: { individual?: Individual }) => {
  const { individual } = props;
  const { isMobile, isTablet } = useDetectResize();
  const t = useTranslate();
  const TAGS = useSelector((state: RootState) => getTags(state, t));

  return (
    <section>
      <Box
        className="iet-header inline full-width"
        sx={{ margin: isMobile || isTablet ? '0.75rem 0rem' : null }}
      >
        <img src={Icon.DetailedTags} alt="icon" />
        <OverviewTitle variant="h3" color={'black'}>
          {t('skills').toUpperCase()}
        </OverviewTitle>
      </Box>
      <div className="keywords-array inline-btwn">
        {individual &&
          (convertTagsToString(individual!.skills, true, TAGS?.all) as string[]).map(
            (tag: string, index: number) => {
              return (
                <Paper sx={[baseTagStyling, skillStyling]} key={index + '-skillsTag'}>
                  {tag}
                </Paper>
              );
            }
          )}
      </div>
      <Box
        className="iet-header inline full-width"
        sx={{ margin: isMobile || isTablet ? '0.75rem 0rem' : null }}
      >
        <img src={Icon.DetailedTags} alt="icon" />
        <OverviewTitle variant="h3" color={'black'}>
          {t('interests').toUpperCase()}
        </OverviewTitle>
      </Box>
      <div className="keywords-array inline-btwn">
        {individual &&
          (convertTagsToString(individual!.interests, true, TAGS?.all) as string[]).map(
            (tag: string, index: number) => {
              return (
                <Box sx={[baseTagStyling, interestStyling]} key={index + '-interestsTag'}>
                  {tag}
                </Box>
              );
            }
          )}
      </div>
      <Box
        className="iet-header inline full-width"
        sx={{ margin: isMobile || isTablet ? '0.75rem 0rem' : null }}
      >
        <img src={Icon.DetailedTags} alt="icon" />
        <OverviewTitle variant="h3" color={'black'}>
          {t('restrictions').toUpperCase()}
        </OverviewTitle>
      </Box>
      <div className="keywords-array inline-btwn">
        {/* {(convertTagsToString(individual!.restrictions, true, TAGS?.all)  as string[]).map((tag:string, index:number) => {
          return <Box  sx={restrictionStyling} key={index+'-restrictionsTag'}>{tag}</Box>
        })} */}
        {!!individual?.restrictionsText.length ? (
          <Paper sx={[baseTagStyling, restrictionStyling]}>{individual?.restrictionsText}</Paper>
        ) : (
          <Paper sx={[baseTagStyling]}>No Restrictions</Paper>
        )}
      </div>
    </section>
  );
};

export default IndividualSkillsInterestsRestrictions;
