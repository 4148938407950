export const DAY_OPTIONS = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
];

export const INDUSTRY = [
  'ADMINISTRATION, BUSINESS AND MANAGEMENT',
  'ANIMALS, LAND AND ENVIRONMENT',
  'AUTOMOTIVE AND GARAGE SERVICES',
  'COMPUTING AND ICT',
  'CONSTRUCTION AND BUILDING',
  'CUSTOMER CARE AND SUPPORT',
  'SOCIAL WORK AND CARING SERVICES',
  'COURIER AND DELIVERY SERVICES',
  'DESIGN, ARTS AND CRAFTS',
  'EDUCATION AND TRAINING',
  'ENGINEERING',
  'FOOD SERVICES',
  'HAIRDRESSING AND BEAUTY',
  'HEALTHCARE',
  'HERITAGE, CULTURE AND LIBRARIES',
  'HOSPITALITY, CATERING AND TOURISM',
  'FACILITIES, CLEANING AND PROPERTY SERVICES',
  'FINANCIAL SERVICES',
  'LEGAL SERVICES',
  'MANUFACTURING AND PRODUCTION',
  'RECYCLING',
  'RETAIL',
  'SECURITY, UNIFORMED AND PROTECTIVE SERVICES',
  'SPORTS',
];

export const INDUSTRY_FR = [
  'ADMINISTRATION, BUSINESS AND MANAGEMENT',
  'ANIMALS, LAND AND ENVIRONMENT',
  'AUTOMOTIVE AND GARAGE SERVICES',
  'COMPUTING AND ICT',
  'CONSTRUCTION AND BUILDING',
  'CUSTOMER CARE AND SUPPORT',
  'SOCIAL WORK AND CARING SERVICES',
  'SERVICES DE MESSAGERIE AND LIVRAISON',
  'DESIGN, ARTS AND CRAFTS',
  'ÉDUCATION',
  'ENGINEERING',
  'SERVICES ALIMENTAIRES',
  'HAIRDRESSING AND BEAUTY',
  'SOINS DE SANTÉ',
  'HERITAGE, CULTURE AND LIBRARIES',
  'HOSPITALITY, CATERING AND TOURISM',
  'FACILITIES, CLEANING AND PROPERTY SERVICES',
  'FINANCIAL SERVICES',
  'SERVICES JURIDIQUES',
  'FABRICATION',
  'RECYCLING',
  'RETAIL',
  'SECURITY, UNIFORMED AND PROTECTIVE SERVICES',
  'SPORTS',
];

export const SCHEDULE_OPTIONS = [
  { value: 0, text: 'Monday', abbr: 'Mon' },
  { value: 1, text: 'Tuesday', abbr: 'Tue' },
  { value: 2, text: 'Wednesday', abbr: 'Wed' },
  { value: 3, text: 'Thursday', abbr: 'Thu' },
  { value: 4, text: 'Friday', abbr: 'Fri' },
  { value: 5, text: 'Saturday', abbr: 'Sat' },
  { value: 6, text: 'Sunday', abbr: 'Sun' },
  { value: 7, text: 'Rotating', abbr: 'Rotation' },
];

export const JOBSCHEDULE_OPTIONS = [
  { value: 0, text: 'Monday', abbr: 'Mon' },
  { value: 1, text: 'Tuesday', abbr: 'Tue' },
  { value: 2, text: 'Wednesday', abbr: 'Wed' },
  { value: 3, text: 'Thursday', abbr: 'Thu' },
  { value: 4, text: 'Friday', abbr: 'Fri' },
  { value: 5, text: 'Saturday', abbr: 'Sat' },
  { value: 6, text: 'Sunday', abbr: 'Sun' },
];

export enum UserType { //TODO swap out userType strings to enum for better readability
  individualsToEmployer,
  job,
  employer,
  individualsToJob,
}

export enum ActivityType {
  initialContract = 'initialContract',
  followUpContract = 'initialContract',
  presentation = 'initialContract',
  jobPostingNew = 'initialContract',
  jobPostingRenew = 'initialContract',
}
export const DAYS_LIST = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

export const NOTIFICATION = {
  JOB_NEW: 'JOB_NEW',
  // JOB_UPDTD: 'JOB_UPDATE', [Removed pending new feature]
  JOB_CLOSE: 'JOB_CLOSE',
  JOB_OPEN: 'JOB_REOPENED',
  //INDIV_NEW: 'INDIVIDUAL_NEW',
  INDIV_UPDTD: 'INDIVIDUAL_UPDATE',
  //INDIV_OPEN: 'INDIVIDUAL_OPEN',
  INDIV_B2C_UPDATE: 'INDIVIDUAL_B2C_UPDATE',
  INDIV_MATCH: 'INDIVIDUAL_MATCH',
  INDIV_ASSIGN: 'INDIVIDUAL_ASSIGNED',
  EMPLOYER_NEW: 'EMPLOYER_NEW',
  INDIV_B2C_NEW: 'INDIVIDUAL_B2C_NEW',
  INDIV_EJI: 'INDIVIDUAL_EXPRESS',
  INDIV_RETRACT: 'INDIVIDUAL_RETRACT',
};

export enum APPLICATION_STATUS {
  PROCESSING = 'In Progress',
  INTERVIEWING = 'Interview Scheduled',
  OFFER = 'Offer of Employment',
  NOPE = 'Not a Match',
}

export const is14YearsInMilliseconds = 14 * 365 * 24 * 60 * 60 * 1000;

export const regXPhoneNumber = /^(?:1-)?\d{3}(?:[-.]\d{3})?[-.]\d{4}(?: ext\. \d{1,5})?$/i;

export const regXEmail = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,10})+$/i;
