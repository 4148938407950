import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const InactiveComponent = (props: { onAcknowledged: () => void }) => {
  const { onAcknowledged } = props;
  const [countdown, setCountdown] = useState<number>(30);
  const { logout } = useAuth0();

  useEffect(() => {
    let newCount = setTimeout(() => {
      setCountdown((currState: number) => (currState -= 1));
    }, 1000);

    if (countdown === 0) {
      logout({
        async openUrl(url) {
          window.location.replace(url);
        },
      });
    }

    return () => {
      clearTimeout(newCount);
    };
  }, [countdown]);

  return (
    <div className="inactive-overlay">
      <div className="inactive-wrpr">
        <div className="inactive-text">
          <Typography variant="body1">You will automatically be logged out in:</Typography>
          <br />
          <Typography variant="h1">{countdown} &nbsp; seconds</Typography>
        </div>
        {/* <Button variant="contained" onClick={onAcknowledged}>Stay Logged In</Button> */}
      </div>
    </div>
  );
};

export default InactiveComponent;
